<template>
<div class="hnw-home">
	
	<div id="home-banner">
		
		<div class="bannder">
			<el-carousel height="450px" arrow="never" >
				<el-carousel-item v-for="(item,index) in banner" :key="index" >
					<img :src="item.img" alt="" @click="bannerJump(item)">
				</el-carousel-item>
			</el-carousel>
		</div>
		<div class="banner-content hnw-area-center">

			<div class="goods-classify">
				<el-collapse v-model="activeClassifys" accordion>
					<el-collapse-item title="一致性 Consistency" name="1">
						<template slot="title">
							<div class="classifys-head">
								<img src="../../assets/newHome/分类.png" alt="" srcset="">
								产品分类
							</div>
						</template>
						<div class="classifys-list" name="1">
							<div 
								class="classifys-item"  
								v-for="(item,index) in classifys" 
								:key="index"
								@mouseover="item.showNames=true"
								@mouseleave="item.showNames=false"
							>
								<div class="classifys-title">
									<div class="title-left">
										<img v-show="item.showNames" :src="require(`../../assets/newHome/classifys/${item.img}.png`)"  alt="" srcset="">
										<img  v-show="!item.showNames" :src="require(`../../assets/newHome/classifys/${item.img}1.png`)"  alt="" srcset="">
										<!-- <img v-show="item.showNames" :src="require(`../../assets/newHome/oertActive${index}.png`)"  alt="" srcset="">
										<img  v-show="!item.showNames" :src="require(`../../assets/newHome/oert${index}.png`)"  alt="" srcset=""> -->
										{{ item.oert }}
									</div>
									<div class="title-r">
										<img class="more" v-show="item.showNames" src="../../assets/newHome/更多1.png" alt="" srcset="">
										<img  v-show="!item.showNames" src="../../assets/newHome/更多.png" alt="" srcset="">

									</div>
								</div>
								<div class="goods-name" @click="findGoods('searchText',item.oert)">
									<span class="name" v-for="(name,index1) in item.names" :key="index1">
										{{ name }}
									</span>
								</div>

								<div class="more-names" v-show="item.showNames">
									<span class="name" v-for="(name,index1) in item.names" :key="index1" @click="findGoods('searchText',name)">
										{{ name }}
									</span>
								</div>
							</div>
						</div>
					</el-collapse-item>
				</el-collapse>
				
				
			</div>
			<div class="user-info" :class="{'hide-user-info':!activeMyInfo}">
				<div class="user-head">
					<img  :src="$store.state.member_avatar||require('../../assets/index/home/face.svg')" alt="" class="logo-face">
					<div class="huanying" @click="activeMyInfo=!activeMyInfo">
						欢迎来到华诺威!
						<i v-show="!activeMyInfo" class="el-icon-arrow-right"></i>
						<i v-show="activeMyInfo"  class="el-icon-arrow-down"></i>
					</div>
					<div class="store-name" v-if="$store.state.member_id">
						{{ $store.state.member_name }}
					</div>
					<div class="user-btn" v-else>
						<el-button @click="toPath('/Register')">注册</el-button>
						<el-button class="lo-gin" @click="toPath('/login')" >登录</el-button>
					</div>
				</div>
				<div class="menu-box" v-show="activeMyInfo"> 
					<div class="menu-list">
						<div 
							class="menu-item"
							v-for="(menu, index) in homeMenuList"
							:key="index"
							@click="toPath(menu.path)"
						>
						  <img
							style="width: 16px; height: 16px; padding-right: 5px"
							:src="require(`../../assets/newHome/${menu.title}.png`)"
							alt=""
						  />
						  <div class="menu-title">
							  {{ menu.title }}
						  </div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	 <div class="home-main  hnw-area-center">
		
		<div class="nav-list"   :class="{'fiexd':scrollDistance>650}" :style="navObj">
			
			<div v-for="(brand,index) in homeBrandList" @click="toView(brand.title)" :key="index" class="nav-item" >  {{ brand.title }}</div>
			
		</div>

		<div class="home-content" id="home-content">
			
			<div class="content-module hnw-hov"  v-for="(item,index) in homeImgList" :key="index" @click="bannerJump(item)">
				<img :src="item.adv_pic" alt="" srcset="">
			</div>
			
			<!-- <div class="content-module classify-img-modu">
				<div class="modu-list">
					<div class="modu-item  hnw-hov" @click="navRouter(item)"  v-for="(item,index) in homeClassifyImgList" :key="index">
						<img  :src="require(`../../assets/newHome/${item}.png`)"  alt="" srcset="">
					</div>
				</div>
			</div> -->
			
			<div v-if="$store.state.is_int_show==0&&$store.getters.notLimitBrands" class="content-module hnw-hov"  @click="toPath('/integral')">
				<img src="../../assets/newHome/积分抽奖.png" alt="" srcset="">
			</div>
			<div class="content-module brand-module">
				<div class="brand-content" v-for="(brand,index) in homeBrandList" :key="index" :id="brand.title">
					<div class="brand-left hnw-hov" @click="comSearchBrand(brand.title)">
						<img  :src="brand.image"  alt="" srcset="">
					</div>
					<div class="brand-right">
						<div class="goods-list">
							<el-row>
								<el-col  v-for="(item,index) in homeBrandGoodsList[brand.title]" :key="index" :span="8">
									<div  class="goods-card hnw-hov"   @click="toGoodsInfo(item)">
										<div class="img-box" >
											<HnwOssImg  :src="item.homeImg" :width="170"></HnwOssImg>
										</div>
										
										<div class="card-fot">
											<div class="goods-name">{{item.goods_name}}</div>
											<!-- <div class="goods-msg">品牌 {{item.oenaturebrand}}</div> -->
											<div class="fot-bot-price" >
												<div class="showPrice blue" v-if="!$store.state.member_id" >
													会员可见
												</div>
												

												<div v-else class="showPrice">
													<div class="symbol">￥</div>
													<div class="goods-price">{{item.goods_price}}</div>
													
												</div>
											</div>
											
											
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="brand-classify hnw-hov" v-if="brand.s_image">
							
							
							<img v-if="brand.cx" :src="brand.cx" alt="" srcset="" @click="toPath(`/index/disgoods/2/${brand.id}/8`)">
							<img v-if="brand.jp" :src="brand.jp" alt="" srcset="" @click="toPath(`/index/disgoods/2/${brand.id}/9`)">
							<img v-if="brand.xp" :src="brand.xp" alt="" srcset="" @click="toPath(`/index/disgoods/2/${brand.id}/10`)">
							
						</div>
					</div>
					
				</div>
			</div>
			

			<div class="content-module recoment-modu">
				<div class="modu-head">
					<div class="head-left">
						猜您喜欢
					</div>
					<div class="head-right">
						<img src="../../assets/newHome/换一组.png" alt="" srcset="">
						换一组
					</div>
				</div>
				<el-row>
					<el-col  v-for="(item,index) in recommendGoodsList" :key="index" :span="4">
						<div  class="goods-card hnw-hov"   @click="toGoodsInfo(item)">
							<div class="img-box" >
								<HnwOssImg :src="item.homeImg" :width="180"></HnwOssImg>
							</div>
							
							<div class="card-fot">
								<div class="goods-name">{{item.goods_name}}</div>
								<div class="goods-msg">品牌: {{item.oenaturebrand}}</div>
								<div class="goods-msg">适配车型: {{item.vehicletype}}</div>
								<div class="goods-msg">OE号: {{item.oecode}}</div>
								<div v-if="!$store.getters.isVisitor" class="join-cart-box" @click.stop="joinCart(item)">
									<img src="../../assets/newHome/购物车.png" alt="" srcset="">
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<!-- <swiper class="swiper-list hnw-area-center" :options="swiperOption" ref="mySwiper">
				
				<swiper-slide v-for="(item,index) in banner" :key="index" >
					<img :src="item.img" alt="" >
				</swiper-slide>
				<div class="swiper-button swiper-button-prev" slot="button-prev"></div>
				<div class="swiper-button swiper-button-next" slot="button-next"></div>
				
			</swiper> -->
		</div>
	 </div>
</div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
export default {
	data(){
		return{
			
			swiperOption: {
				// 配置一次显示多个 slides
				slidesPerView: 3,
				// 可选：设置 slides 之间的间距
				spaceBetween: 30,
				// 如果需要分页器
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
				},
				// 如果需要导航按钮
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			},
			homeClassifyImgList:["品牌馆","变速箱系统","底盘系统","发动机系统"],
			
			homeMenuList:[
				{
					title:"我的订单",
					path:"/my/order"
				},
				{
					title:"账号信息",
					path:"/my/account"
				},
				{
					title:"企业信息",
					path:"/my/company"
				},
				{
					title:"我的地址",
					path:"/my/address"
				},
			],
			classifys:[
			 
				{
					oert:"车身系统",
					img:"cheshen",
					showNames:false,
					names:[]
				},
				{	
					oert:"发动机系统",
					img:"fadongji",
					showNames:false,
					names:[]
				},
				{
					oert:"底盘悬挂系统",
					img:"dipan",
					showNames:false,
					names:[]
				},
				{
					oert:"电子电器系统",
					img:"dianzidq",
					showNames:false,
					names:[]
				},
			],
			activeClassifys:"1",
			activeMyInfo:true,
			banner:[],
			homeBrandList:[],
			homeBrandGoodsList:[],
			recommendGoodsList:[],
			navLeft:0,
			navObj:{},
			scrollDistance: 0 ,
			homeImgList:[]
		}
	},
	methods:{
		toView(title){
			document.getElementById(title).scrollIntoView({ behavior: 'smooth', block: 'start' })
		},
		scrInclud(top){
			return this.scrollDistance>top-100&&this.scrollDistance<top+100
		},
		findGoods(key,text){
			let clearKey = ""
			if(key=="searchOesort"){
				
				clearKey = "searchText"
			}else{
				clearKey = "searchOesort"
			}
			
			this.$store.commit('UpDateState',{
				[clearKey]:"",
				[key]:text
			})
			console.log("clearKey",clearKey);
			
			this.toPath("/find/index")
		},
		toPath(path){
			this.$router.push(path)
		},
		comSearchBrand(brand){
			localStorage.setItem("epc-brand-text",brand)
			this.toPath("/brand")
			
		},
		navRouter(item){
			console.log(item);
			if(item=='品牌馆'){
				this.toPath('/brand')
			}else{
				
				this.findGoods('searchOesort',item)
			}
		},
		toGoodsInfo(acti){
			this.showDiaVis=false
			
			acti.preRou = "首页"
			if(acti.g_id){
				acti.goods_id = acti.g_id
			}
			let rou = '/index/goodsinfo'
			localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
			this.toPath(rou)	
		},
		async bannerJump(banner){ 
			
			if(banner.url){
				let acti = this.nowCollectList.find(col=>col.setlist_id==140)
				if(acti){
					this.toGoodsInfo(acti)
				}else{
					this.$warMsg("暂无信息")
				}
				// 如果图片能跳转商品
			}else if(banner.goods_id){

				try {
					let jumpMsg = JSON.parse(banner.goods_id)
					if(jumpMsg.type=='goods'){
						const res = await this.$api.getGoodsInfo({
							goods_id:jumpMsg.data
						}) 
						this.toGoodsInfo(res)
					}else if(jumpMsg.type=='brand'){

						this.$store.commit('UpDateState',{
							searchText:jumpMsg.data
						})

						this.toPath('/find/index')
					}else if(jumpMsg.type=='link'){
						this.toPath(jumpMsg.data)
					}else if(jumpMsg.type=='bodyImg'){ 
						localStorage.setItem("web-banner-body-img",jumpMsg.data)
						this.toPath("/bodyImg")
					}else if(jumpMsg.type=='adcerImg'){
						let tempArr =  jumpMsg.data.split("&") 
						if(tempArr[1]&&tempArr[1]!='undefined'){
							localStorage.setItem("web-banner-body-img",jumpMsg.data.split("&")[1])
							this.toPath("/bodyImg")
						}else if(tempArr[2]){
							this.$store.commit('UpDateState',{
								searchText:tempArr[2]
							}) 
							this.toPath('/find/index')
						}
					}
				} catch (error) {
					
				}
				
			}else if(banner.img.search('vin')!=-1){
				if(!this.token){
					this.$warMsg("未登录账号")
					return this.toPath("/login")
					
				}else if(this.$isCompany()){
					let link = location.href.split("#")[0]
			
					window.open(link + '#/epc/home/vin')
					
				}
			}
		},
		
		async getBanner(){
			// 轮播
			let bannerRes = await this.$api.getHomeBanner({
				source: 1
			})
			if(bannerRes){
				bannerRes.forEach(item=>{
					item.img=item.adv_pic
					// item.goods_id = '{"type":"brand","data":"火花塞"}'
				})
				this.banner = bannerRes.concat(this.banner)
			}
			// 列表
			bannerRes = await this.$api.getHomeBanner({
				source: 10
			})
			this.homeImgList = bannerRes
		},
		async getBrandList(){
			// 品牌列表
			
			let brandRes =  await this.$api.getDisList({
				type:2
			})
			// 
			let listimgs = []
			// item.id
			brandRes.forEach(async item=>{
				try {
					let simg = JSON.parse(item.s_image)
					
				
					item.cx = simg.cx
					item.jp = simg.jp
					item.xp = simg.xp
					
				} catch (error) {
					
				}
				
				let res = await this.$api.getDisList({
					type:2,
					id:item.id,
					page:1,
					goods_commend:"11",
					limit:6
				})
				if(res.db&&res.db.length>0){
					res.db.forEach(goods=>{
						goods.goods_price = this.$handlePrice(goods)
					})
					listimgs = await this.$api.getGoodsListHomeImg({
						goods_id:res.db.map(item=>item.goods_id).join(","),
						goods_type:"品牌首图",
						shop_id:1780,
						store_id:1780 
					}) 
					listimgs.forEach(item=>{
						res.db.forEach(goods=>{
							goods.goods_price = this.$handlePrice(goods)
							if(goods.goods_id==item.goods_id){
								this.$set(goods,"homeImg",item.url)
							}
						})
					})
					this.$set(this.homeBrandGoodsList,item.title,res.db)
					
				}else{
					this.homeBrandGoodsList[item.title] = []
				}
			})
			this.homeBrandList = brandRes
		},
		async getRecommendGoodsList(){
			// 推荐
			let list = []
			list = await this.$api.getlistRecom({
				goods_commend:1,
				store_id:1780,
			})
			
			if(list.length>0){
				let ids = list.map(item=>item.goods_id)
				let goods_id = ids.join(",") 
				// 获取图片
				// getlistRecomImg
				let listimgs = await this.$api.getGoodsListHomeImg({
					goods_id,
					goods_type: "WEB推荐",
					// shop_id:this.$store.state.store_id,
					shop_id:1780,
					store_id:1780			
				})
				
				listimgs.forEach(item=>{
					list.forEach(goods=>{
						if(goods.goods_id==item.goods_id){
							this.$set(goods,"homeImg",item.url)
							goods.goods_price = this.$handlePrice(goods)
						}
					})
				})
			} 
			this.recommendGoodsList = list
		},
		async joinCart(carItem){
			if(this.$isCompany()){
				if(carItem.goods_storage<1){
					return this.$store.commit('UpDateState',{invDia:true})
				}
				let res = ''
				
				let carMessage = {
					goods_id:carItem.goods_id,
					sccode:carItem.sccode,
					oecode:carItem.oecode,
					goods_name:carItem.goods_name,
					goods_price:carItem.goods_price,
					goods_storage:carItem.goods_storage,
					goods_num:1,
					store_id:carItem.store_id,
					store_name:carItem.store_name,
					cart_type:'1',
			
				} 
				res = await this.$api.joinCart([carMessage])
				// 跳转到加入成功页
				
			}
		},
		handleScroll() {
			// 获取当前的滚动距离
			this.scrollDistance =  document.documentElement.scrollTop;
			if(this.scrollDistance>650){

				this.navObj.left = this.navLeft + "px"
			}else{
				try {
					const fixedElement = document.getElementById('home-content');
					const rect = fixedElement.getBoundingClientRect();
					const leftDistanceToDocument = rect.left + window.pageXOffset;
					this.navLeft = leftDistanceToDocument - 140
					this.navObj = {}
				} catch (error) {
					
				}
			}
		},
		handleResize() {
			try {
				const fixedElement = document.getElementById('home-content');
				const rect = fixedElement.getBoundingClientRect();
				const leftDistanceToDocument = rect.left + window.pageXOffset;
				this.navLeft = leftDistanceToDocument - 140
			} catch (error) {
				
			}
		},
		
	},
	mounted() {
		// 添加滚动事件监听器
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleResize);
		// 如果你想要得到元素左边相对于整个文档（而不仅仅是当前视口）的距离，
		// 你需要加上当前的滚动偏移量
		
		
	},
	onBeforeUnmount(){
		window.removeEventListener('scroll', handleScroll);
		window.removeEventListener('resize', this.handleResize);
	},
	
	async created(){
		

		this.classifys.forEach(async item=>{
			let res = await this.$api.getGoosNamesByOesort(item.oert)
			item.names = res
		})
		this.getBanner()
		this.$nextTick(()=>{
			this.getBrandList()
			this.getRecommendGoodsList()
		})

	},
	components:{
		swiper,
		swiperSlide
	},
	props:{
		
	}
}
</script>

<style lang="scss">
.hnw-home{
	background: #F9F9F9;
	#home-banner{
		position: relative;
		height: 450px;
		.bannder{
			height: 450px;
			width: 100%;
			position: absolute;
			// display: flex;
			// justify-content: center;
			// .el-carousel{
			// 	width: 1920px;
			// 	height: 100%;
			// }
			img{
				height: 450px;
				width: 100%;
				max-width: 1920px;
				cursor: pointer;
				margin: 0 auto;
				object-fit: cover;
			}
			//  修改指示器样式
			
			.el-carousel__indicators{
				// background: rgba($color: #fff, $alpha: 0.4);
				padding: 0 2px;
				height: 18px;
				display: flex;
				align-items: center;
				border-radius: 9px;
				bottom: 20px;
				.el-carousel__indicator{
					padding: 0 6px;
					.el-carousel__button{
						width: 12px;
						height: 12px;
						border-radius: 50%;
					}
				}
				.is-active{
					.el-carousel__button{
						background: #fff;
						cursor: pointer;
						transform: scale(1.2);
					}
				}
			}
		}
		.banner-content{
			position: relative;
			height: 100%;
			display: flex;
			justify-content: space-between;
			.goods-classify{
				z-index: 99;
				width: 286px;
				// background: #343435;
				color: #fff;
				position: relative;
				.el-collapse{
					// background: #343435;
					border: none;
					.el-collapse-item{
						.el-collapse-item__header{
							border: none;
							height: auto;
							line-height: 1;
							background: #2260BB;
							color: #fff;
							.el-collapse-item__arrow {
								color: #fff;
							}
						}
						.el-collapse-item__wrap{
							border: none;
						}
					}
					.el-collapse-item__content{
						padding-bottom: 0;
						background: #343435;
					}
				}
				.classifys-head{
					display: flex;
					align-items: center;
					padding: 10px 20px;
					background: #2260BB;
					width: 100%;
					box-sizing: border-box;
					img{
						margin-right: 20px;
						width: 28px;
					}
				}
				.classifys-list{
					// padding-left: 20px;
					height: 400px;
					padding: 20px 0;
					display: flex;
					box-sizing: border-box;
					flex-direction: column;
					.classifys-item{
						// height: 25%;
						box-sizing: border-box;
						padding:0 20px;
						flex: 1;
						cursor: pointer;
						.classifys-title{
							display: flex;
							justify-content: space-between;
							align-items: center;
							padding: 10px 0;
							
							.title-left{
								display: flex;
								align-items: center;
								color: #fff;
								img{
									margin-right: 20px;
									width: 24px;
								}
							}
							.title-r{
								img{
									
									width: 14px;
								}
								.more{
									// width: 7px;
									
								}
							}
						}
						.goods-name{
							padding-left: 45px;
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: normal; /* Ensure normal white-space handling */
							.name{
								margin-right: 10px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								font-size: 14px;
								color: #999999;
								line-height: 24px;
							}
						}
						.more-names{
							width: 914px;
							height: 450px;
							overflow-y: auto;
							overflow-x: hidden;
							background: #fff;
							position: absolute;
							top: 0;
							right: -914px;
							padding: 35px 30px;
							box-sizing: border-box;
							border: 1px solid #F2F2F2;
							.name{
								margin-right: 10px;
								font-family: Source Han Sans CN;
								font-weight: 400;
								font-size: 14px;
								color: #999999;
								line-height: 24px;
							}
							.name:hover{
								color: #2260BB;
							}
						}
					}
					.classifys-item:hover{
						color: #333;
						background: #fff;
						border-right: 1px solid #F2F2F2;
						.title-left{
							color: #333;
						}
					}
				}
			}
			.user-info{

				z-index: 20;
				width: 268px;
				margin-top: 10px;
				// height: 430px;
				height: auto;
				// padding: 25px 10px;
				box-sizing: border-box;
				text-align: center;
				overflow: hidden;
				max-height: 430px;
				.user-head{
					padding: 0 10px;
					padding-top: 25px;
					background: #fff;
					.logo-face{
						width: 46px;
						cursor: pointer;
					}
					.huanying{
						font-family: Source Han Sans CN;
						font-weight: 400;
						font-size: 16px;
						color: #063376;
						line-height: 24px;
						cursor: pointer;
						i{
							margin-left: 5px;
						}
					}
					.store-name{
						font-family: Source Han Sans CN;
						font-weight: 400;
						font-size: 12px;
						color: #999999;
						line-height: 24px;
						padding: 5px 0;
					}
					.user-btn{
						padding: 20px 0;
						display: flex;
						justify-content:space-around;
						.el-button{
							width: 90px;
						}
						.lo-gin{
							background: #063376;
							border: 1px solid#063376;
							color: #fff;
						}
					}
				}
				.menu-box{
					height: 275px;
					padding: 0 10px;
					padding-bottom: 25px;
					transition: all 0.5s;
					// padding: 25px 10px;
					background: #fff;
					.menu-list{
						border-top: 1px solid #ddd;
						display: flex;
						flex-wrap: wrap;
						.menu-item{
							cursor: pointer;
							width: 33%;
							text-align: center;
							margin-top: 25px;
							.menu-title{
								font-family: Source Han Sans CN;
								font-weight: 400;
								font-size: 14px;
								color: #666666;
								line-height: 24px;
							}
						}
					}
				}
			}
			.hide-user-info{
				margin-top: 0px;
				.user-head{

					height: 48px;
					padding: 0 20px;
					display: flex;
					justify-content: center;
					align-items: center;
					img{
						margin-right: 5px;
					}
					.store-name{
						display: none;
					}
				}
			}
		}
	}
	.home-main{	
		position: relative;
		display: flex;
		justify-content: center;
		padding-top: 10px;
		.nav-list{
			width: 110px;
			align-self: flex-start;
			left: -140px;
			position: absolute;
			transition: top 0.3s ease;
			top: 20px;
			.nav-item{
				background-color: #fff;
				width: 110px;
				height: 48px;
				text-align: center;
				// line-height: 48px;
				border-bottom: 1px solid  #F2F2F2;
				cursor: pointer;
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
				i{
					margin-bottom: 2px;
				}
			}
			.active{
				background: #f00;
				color: #fff;
			}
			.nav-item:hover{
				background: #f00;
				color: #fff;

			}
		}
		.fiexd{
		
			position: fixed;
			top: 20px;
		}
		.home-content{
			.content-module{
				margin-top: 20px;
				background: #fff;
				max-width: 1200px;
				>img{
					width: 100%;
				}
				.modu-head{
					display: flex;
					justify-content: space-between;
					padding: 30px 30px 10px;
					box-sizing: border-box;
					.head-left{
						font-family: Source Han Sans CN;
						font-weight: 400;
						font-size: 32px;
						color: #333333;
						
					}
					.head-right{
						display: flex;
						align-items: center;
						cursor: pointer;
						img{
							margin-right: 8px;
						}
					}
				}
				.goods-card{
					background: #fff;
					position: relative;
					display: flex;
					margin-bottom: 10px;
					flex-direction: column;
					margin: 0 6px;
					padding-top: 10px;
					cursor: pointer;
					
					.img-box{
						// cursor: pointer;
						
						display: flex;
						justify-content: center;
						align-items: center;
						
					}
					
					.card-fot{
						
						padding:  10px 14px;
						box-sizing: border-box;
						>div{
							text-align: left;
						}
						.goods-name{
							font-family: Source Han Sans CN;
							font-weight: 400;
							font-size: 18px;
							color: #333333;
							line-height: 24px;
							
							width: 179px;
							
						}
						.goods-msg{
							font-family: Source Han Sans CN;
							font-weight: 400;
							font-size: 16px;
							color: #999999;
							line-height: 24px;
						}
						.fot-bot-price{
							
							.showPrice{
								display: flex;
								margin: 20px 0 10px 0;
								justify-content: start;
								align-items: center;
								margin-top: 5px !important;
								width: 100%;
								color: #F72723;
								.symbol{
									font-size: 16px;
								}
								.goods-price{
									font-size: 18px;
								}
								
							}
						}
							
						
					}
				}
			}
			.classify-img-modu{
				.modu-list{
					display: flex;
					justify-content: space-between;
					width: 100%;
					.modu-item{

						img{
							width: 288px;
						}
					}
				}
			}
			.brand-module{
				background: #f9f9f9;
				.brand-content{
					display: flex;
					margin-top: 20px;
					width: 100%;
					background: #fff;
					
					.brand-left{
						cursor: pointer;
						width: 244px;
						img{
							width: 244px;
						}
					}
					.brand-right{
						display: flex;
						flex: 1;
						background: #fff;
						.goods-list{
							
							flex: 1;
							background: #fff;
							display: flex;
							flex-wrap: wrap;
							
							.el-row{
								width: 100%;
								.el-col{
									overflow: hidden;
									height: 285px;
									.goods-card {
										
										overflow: hidden;
									}
								}
							}
						}
						.brand-classify{
							width: 250px;
							height: 550px;
							margin: 20px 0;
							border-left: 1px solid #F2F2F2;
							text-align: center;
							background: #fff;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: center;
							img{
								
								width: 216px;
							}
						}
					}
				}
			}
			.recoment-modu{
				.modu-head{
					.head-right{
						img{
							width: 22px;

						}
					}
				}
				.el-col{
					border-right: 1px solid #F2F2F2;
					overflow: hidden;
					box-sizing: border-box;
					height: 365px;
					border-bottom: 1px solid #f2f2f2;
					.goods-card{
						margin: 0;
						box-sizing: border-box;
						overflow: hidden;
						.card-fot{
							>div{
								white-space: nowrap;
								text-overflow: ellipsis;
								overflow: hidden;
								font-size: 14px;
							}
							.goods-name{
								font-size: 16px;
								color: #666;
							}
						}
						.join-cart-box{
							text-align: right;;
							padding: 10px 0;
							img{
								width: 24px;
							}
						}
					}
				}
			}
			.swiper-list.swiper-container{
				.swiper-wrapper{
					width: 288px;
					height: 204px;
					.swiper-slide{
						width: 288px !important;
						height: 204px;
						box-sizing: border-box;
						background-color: #fff;
						margin-right: 15px;
						box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
						border-radius:6px ;
						img{
							height: 204px;
							width: 288px;
						}
					}
					
				}
				.swiper-button{
					
				}
			}
			
		}
	}
}
</style>