<template>
<div class="inte-groups" :class="{'noBor':noBor}">
    <div class="group-list">
        <div class="group-item" v-for="option in groups" :key="option.value" @click="cutGroup(option)" :class="{'active':group_type==option.value}">
            <div>
                {{option.title}}
            </div>
        </div>
        
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            group_type:""
        }
    },
    methods:{
        cutGroup(option){
            this.$emit("modeChange",option.value)
            this.group_type = option.value
        },
    },
    created(){
        this.group_type = this.groups[0].value
    },
    props:{
        groups:{
            require:true,
            type:Array
        },
        noBor:{
            default:false
        }
    }
}
</script>

<style lang="scss">
.inte-groups{
    display: flex;
    justify-content: space-between;
    position: relative;
    width: 100%;
    background: #fff;
    margin-bottom: 5px;
    .group-list{
        display: flex;
        .group-item{
            cursor: pointer;
            line-height: 38px;
            height: 38px;
            padding: 0 20px;
            color: #666666;
            font-family: Source Han Sans CN;
            font-weight: 400;
            font-size: 14px;
            color: #666666;
        }
        .active{
            color: #042273;
            >div{
                border-bottom: 2px solid #042273;
            }
        }
    }
}
.noBor{
    border: none;
}
</style>