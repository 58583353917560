<template>
    <div class="find-list">
        <!-- <div style="width:100%;height:10px;background:#ccc"></div> -->
        <div class="w">
            
            <!-- 综合排序 -->
            <div class="sort">
                
                <div class="sort-left">
                    <!-- <span style="color:#50A3F8;">丨</span> -->
                    <div @click="num=1" :class="{'atv': num==1}">综合排序</div>
                    <div @click="num=2" :class="{'atv': num==2}" v-if="noInquireList.length>0">没有查到({{noInquireList.length}})</div>
                </div>
                <div class="short-pag" >
                    <el-checkbox class="filterZero"  v-model="showZero" @change="query(true)">过滤0库存</el-checkbox>
                    <div class="all">

                        {{total}}条
                    </div>
                    
                   
                </div>

                <div class="sort-right" @click="toggleScreen">
                    {{message}}
                    <i class="el-icon-arrow-up" v-if="showScreen"></i>
                    <i class="el-icon-arrow-down" v-else></i>
                </div>
            </div>

            <div class="screen-list" v-if="!showScreen&&pitchScreen.length>0" >
                <div class="screen-item" v-for="(scr,index) in pitchScreen" :key="index">{{scr}}</div>
                <i class="el-icon-close" @click="clearActiveFilter('pitchScreen')"></i>
            </div>

            <div class="condition-tabel" v-show="showCond" >
                <!-- 配件品牌 -->
                <div class="coudition-group">
                    <div class="label">配件品牌</div>
                    <div class="coudition" >
                        <div class="coudition-left pabo10" v-if="screenList && screenList.oenaturebrand" :style="{'height':couditionH}">
                            <template v-for="(item,index) of screenList.oenaturebrand" >
                                <div class="coudition-daxiao" :key='"oenaturebrand"+index' v-if="item.oenaturebrand">
                                    <span  class="item" :class="{'active':searchData.oenaturebrand.includes(item.oenaturebrand)}" @click="activeFilter('oenaturebrand',item.oenaturebrand)">
                                        {{item.oenaturebrand}}
                                    </span>
                                </div>
                            </template>
                            <div class="toggle">
                                <i v-if="couditionH == 'auto'" class="el-icon-arrow-up"  @click="toggleCH"></i>
                                <i v-else class="el-icon-arrow-down" @click="toggleCH"></i>
                            </div>
                        </div>
                        
                        <div class="coudition-right">
                            <span @click="clearActiveFilter('oenaturebrand')">
                                取消选中
                            </span>
                        </div>
                    </div>
                </div>
                
                <!-- 配件分类 -->
                <div class="coudition-group">
                    <div class="label">配件分类</div>
                    <div class="coudition">
                        
                        <div class="coudition-left" v-if="screenList && screenList.oesort">
                            <template v-for="(item,index) of screenList.oesort" >
                                <div  class="coudition-daxiao" :key='"oesort"+index' v-if="item.oesort">
                                    <span  class="item" :class="{'active':searchData.oesort.includes(item.oesort)}" @click="activeFilter('oesort',item.oesort)">
                                        {{item.oesort}}
                                    </span>
                                </div>
                            </template>
                        </div>
                        <div class="coudition-right">
                            <span @click="clearActiveFilter('oesort')">
                                取消选中
                            </span>
                        </div>
                    </div>
                </div>
               
            </div>
           
              <div class="product"  v-if="num==1">
                <ul>
                         <li v-for="(item,index) of listAttr" :key="index">  
                                {{item.label}}
                                <span v-if="item.label=='订货价'&&(shopId=='1871'||shopId=='1974')">(不含税)</span>
                                <div class="ico">
                                    
                                    <!-- <i class="el-icon-caret-top" @click="sortChange(item.key,1)" :class="{'active':sortActive==item.key+1}"></i> -->
                                    <!-- <i class="el-icon-caret-bottom" @click="sortChange(item.key,2)" :class="{'active':sortActive==item.key+2}"></i> -->
                                    <img src="../assets/commom/up.png" v-if="sortActive==item.key+1" alt="" @click="sortChange(item.key,2,index)" >

                                    <img src="../assets/commom/down.png" v-else alt="" @click="sortChange(item.key,1,index)" >
                                </div> 
                        
                    </li>  
                   

                    <li>
                        订货数量
                    </li>
                    <!-- <li>区域</li> -->
                    <li>
                        操作
                    </li>  
                </ul>
            </div>
            <div v-if="num==2" class="available">
                <div v-for="(item,index) in noInquireList" :key="index">
                    没查到 <span class="red">{{item}}</span> 相关配件，请试试 <span class="blue" @click="postDemand">发布需求</span> 。
                </div>
            </div>
          
            <!-- 商品详情 -->
            <div class="cargo" >
                    <ul>
                        <!-- :class="{'case-list':true,'isAll':item.isShowAll}"  -->
                        <li v-for="(goods,index) in goodsList" :key="index">
                            <div class="details">
                                <div class="isOe">
                                    <div class="isOe-top">
                                        <div class="name">{{goods.oenaturebrand}}</div>
                                        <div class="number"  @click.stop="showGoodsInfo(goods)">
                                            {{group_type?goods.store_name:goods.oecode}}
                                        </div>
                                            <!-- <div class="tag-icon" v-if="goods.exchange.length>0">
                                                互
                                            </div> -->
                                    </div>

                                    <div class="vehicleModel" @click.stop="showGoodsInfo(goods)" :title="'车系:'+goods.vehSeriesErp+'  车型:'+goods.vehicletype"><span class="series-span" v-if="goods.vehSeriesErp">车系:{{goods.vehSeriesErp}}</span> 车型:{{goods.vehicletype}}</div>
                                </div>

                                <div class="productName" :title="goods.goods_name">{{goods.goods_name}}</div>
                                
                                <div class="encoding" :title="goods.sccode">{{goods.sccode}}</div>
                                <div class="brand" :title="goods.oenaturebrand">{{goods.oenaturebrand}}</div>

                                <template v-if="shopId!=1974&&shopId!=1871">
                                    <div class=" inventory" v-if="$store.state.look_inv==1">
                                    {{goods.goods_storage}}
                                    <div class="red" v-if="goods.group_storage&&Number(goods.group_storage)>0">
                                        总仓发货，预计1~3天发货
                                    </div>
                                    </div>

                                    <div class="inventory" v-else>
                                        
                                        
                                        {{goods.goods_storage>0?"有货": "无货"}}
                                        
                                        <div class="red" v-if="goods.group_storage&&Number(goods.group_storage)>0">
                                            总仓发货，预计1~3天发货
                                        </div>
                                    </div>
                                </template>
                                
                                
                                <div class="TheOrderPrice"> ¥ {{goods.goods_price}}</div>
                                
                                <div class="numAd">
                                    <el-input-number   v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1"  size="mini"></el-input-number>
                                    <el-input-number   v-else  v-model="goods.goods_num"  @change="impueNumChange(goods)" :min="1"  size="mini"></el-input-number>
                                    <span class="goods-Fcl-tit red" v-if="goods.batch_pur_num_bey" >
                                        库存不足{{goods.batch_pur_num||0}}
                                    </span>
                                    <!-- -->
                                </div>
                                
                                <!-- <div class="area">{{goods.house||'总部仓'}}</div> -->
                                <div class="handle-box">
                                    
                                    <template v-if="$store.state.shop==1974||$store.state.shop==1871">
                                        <el-button class="add-btn" v-if="chain==3"  @click="joinCart(goods,index)" >加入购物车</el-button> 
                                        <el-button class="add-btn" v-else  @click="joinCart(goods,index)" >加入购物车</el-button> 
                                    </template>
                                    
                                    <template v-else>
                                        <el-button class="add-btn" v-if="chain==3" :class="{'ycg-btn':goods.goods_storage==0&&Number(goods.group_storage)==0}" @click="joinCart(goods,index)" >{{(goods.goods_storage>0||goods.group_storage>0)?'加入购物车':'加入预购单'}}</el-button> 
                                        <el-button class="add-btn" v-else :class="{'ycg-btn':goods.goods_storage==0}" @click="joinCart(goods,index)" >{{(goods.goods_storage>0)?'加入购物车':'加入预购单'}}</el-button> 
                                    </template>
                                    <div class="addition">
                                        <span v-if="goods.cartNum">
                                            已添加 {{goods.cartNum}} 件
                                        </span>
                                    </div>
                                </div>
                                    
                                <div class="stock" v-if="goods.self_storage">自有库存{{goods.self_storage || 0}}</div>
                                <!-- <div class="addition"  v-if="goods.cartNum">已添加 {{goods.cartNum}} 件</div> -->
                            </div>
                                <div class="buyHis" v-if="goods.orderInfo">
                                    <template  >
                                        您上次购买：
                                        <span>
                                            {{$hnwTools.formatDateTime(goods.orderInfo.add_time,'ymd')}}
                                        </span>
                                        <!-- 
                                        <span>
                                            {{goods.orderInfo.member_name}}
                                        </span> -->
                                        <span>
                                            {{goods.orderInfo.goods_num}} 件
                                        </span>
                                        <!-- <span>
                                            {{$hnwTools.getOrderStateText(goods.orderInfo.order_state)}}
                                        </span> -->
                                        <span class="readMore" @click="readHisList(goods.goods_id)">
                                            查看更多
                                        </span>
                                    </template>
                                </div>
                            
                        </li>
                            <!-- <div class="pagings" v-if="group_type==''">
                                <el-pagination
                                    background
                                    @size-change="handleStoreSizeChange(item)"
                                    @current-change="handleStoreCurrentChange(item)"
                                    :current-page.sync="item.curpage"
                                    :page-sizes="[20, 30,40]"
                                    :page-size.sync="item.page"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="Math.ceil(item.total)">
                                </el-pagination>
                            </div> -->
                    </ul>
                </div>
        <el-dialog title="历史购买记录" :visible.sync="hisVis" center  width="800px">
                    <div class="his-list">
                        <div class="list-head">
                            <div>购买时间</div>
                            <div>购买人</div>
                            <div class="col-100">价格</div>
                            <div class="col-100">购买个数</div>
                            <div class="col-100">订单状态</div>
                        </div>
                        <div class="list-body">
                            <div class="row" v-for="(his,index) in gooodsHisList" :key="index">
                                <div>{{$hnwTools.formatDateTime(his.add_time,'ymd')}}</div>
                                <div>{{his.member_name}}</div>
                                <div  class="col-100">{{his.goods_price}}</div>
                                <div  class="col-100">{{his.goods_num}}</div>
                                <div class="col-100">{{$hnwTools.getOrderStateText(his.order_state)}}</div>
                            </div>
                        </div>
                    </div>
                </el-dialog>
            <!-- 分页 -->
            <div class="paging" v-if="num==1 && $store.state.batchs.length==0">
                <el-pagination
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage"
                    :page-sizes="[15, 20, 30, 40]"
                    :page-size.sync="pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="Math.ceil(total)"
                >
                </el-pagination>
            </div>
            <div class="shoppingAll" v-else>
                <div @click="joinCartAll">
                        全部加入购物车
                </div>
            </div>

            <!-- 弹出框 -->
            <div class="hezi" >
                <el-dialog
                    :visible.sync="dialogVisible"
                    width="980px"
                    top="8vh"
                    >
                    
                    <DiaGoodsInfo :id="goods_id"></DiaGoodsInfo>
                </el-dialog>
            </div>
            <!-- 展开箭头 -->
            <!-- <div class="pull" @click="goPull">
                <i class="el-icon-arrow-down jiantou" v-if="!showAll"  ></i>
            <i class="el-icon-arrow-up  jiantou" v-if="showAll" ></i>
            </div> -->
        </div>
    </div>
</template>

<script>

export default {
    data() {
        return{
            gooodsHisList:[],
            hisVis:false,
            storeText:'',
            goodsList:[],            //搜索框数据
            // showPull:false,         //弹框-隐藏套内商品
            dialogVisible: false,   //显示隐藏弹出框
            message:"展开筛选",    
            showCond:false,         //筛选显示
            checked: false,         //多选框
            showSearch:false,       //数据展示
            // showAll:false,          //弹出框数据展示
            showbelow:false,        //筛选展示
            showScreen:false,
            num: 1,
            sort_type:'',
            sort_way:'',
            showList:[],
            start_list:[],
            sortActive:'',
            group_type:'',
            
            listAttr: [             
                { key:"oecode",label:"OE号",},
                { key:"goods_name",label:"商品名称",},
                { key:"sccode",label:"生产编码",},
                { key:"oenaturebrand",label:"配件品牌",},
                // { key:"oenatureplace",label:"产地",},
                // { key:"oenature",label:"配件性质",},
                { key:"goods_storage",label:"库存",},
                { key:"goods_price",label:"订货价",}
            ],
            attrList:[],
            goods_id:"",
            currentPage:1,
            pageSize:15,
            totalParts:0,     //搜索配件数
            total:0,             //搜索总条数
            screenList:{},
            searchData:{ //筛选框选中列表
                city:[],
                oecarbrand:[],
                oenaturebrand:[],
                oesort:[],
                store:[]
            },
            couditionH:"20px",
            goods:"",
            priceList:[],
            replaceList:[],
            vehList:[],
            num:1,
            noInquireList:[],
            showZero:false,
            shopId:""
      }
    },
    created(){
        this.shopId = this.$route.params.id
        // 如果是昕豫店铺就隐藏库存
        if(this.shopId==1974||this.shopId==1871){
            this.listAttr=this.listAttr.filter(item => {
                return item.label!='库存'
            })
        }
        // this.query(true)
    },
   
    methods: {
        
        impueNumChange(goods) {
            // group_storage
            if(isNaN(goods.goods_num)){
                
                
                this.$nextTick(()=>{
                    this.$set(goods,"goods_num",1)
                })
            }
            if(this.chain==3){
                // if(goods.goods_storage>0&&goods.goods_num>goods.goods_storage){
                //     this.$nextTick(()=>{
                //         goods.goods_num = goods.goods_storage
                //     })
                //     return this.$store.commit('UpDateState',{invDia:true})
                // }else if(Number(goods.group_storage)>0&&goods.goods_num>Number(goods.group_storage)){
                //     this.$nextTick(()=>{
                //         goods.goods_num = Number(goods.group_storage)
                //     })
                //     return this.$store.commit('UpDateState',{invDia:true})
                // }
                // this.$nextTick(()=>{
                //         goods.goods_num = Number(goods.group_storage)
                //     })
                // this.$store.commit('UpDateState',{invDia:true})

            }else{
                // if(goods.goods_storage>0&&goods.goods_num>goods.goods_storage){
                //     this.$nextTick(()=>{
                //         goods.goods_num = goods.goods_storage
                //     })
                //     return this.$store.commit('UpDateState',{invDia:true})
                // }
                // this.$nextTick(()=>{
                //         goods.goods_num = goods.goods_storage
                //     })
                    // this.$store.commit('UpDateState',{invDia:true})
            }
        },
        postDemand(){
            this.$router.push("/my/demand")
        },
        setParams(storeText){
            this.storeText = storeText
            this.currentPage = 1
            this.pageSize = 15
            this.query(true)
        },
        handleChange(value) {
            // console.log(value);
        },
        //关闭标签
        closeTag(value){
            let deleteType = ''
            this.tags.splice(this.tags.indexOf(value),1)
            for(let [key,tagsList] of Object.entries(this.searchData)){
                
                if(tagsList.indexOf(value)>-1){
                    deleteType = key
                }
            }
            if(deleteType){
                this.searchData[deleteType].splice(this.searchData[deleteType].indexOf(value),1)
            }
            // this.query()
        },
        
        // 弹出框数据
       async showGoodsInfo(goods){ 

            // this.goods_id =goods_id
            // this.dialogVisible = true
            if(!goods.goods_id){
                return 
            }
            if(this.$store.state.shop!=this.shopId&&this.$store.state.store_id!=this.shopId&&this.$store.state.for_branch!=this.shopId){
                return this.$warMsg("暂无权限")
            }
            goods.preRou = "直营配件列表"
            localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
            this.$emit("showInfo")
        },
        // 升序降序
        sortChange(type,way,index){
            this.sort_type = type
            this.sort_way = way
            this.sortActive = type + way
            this.listAttr[index].ckbox=!this.listAttr[index].ckbox
            this.currentPage = 1
            this.pageSize = 15
            this.query()
        },
      
        //展开收起筛选
        toggleScreen(){
            
            this.showCond = !this.showCond
            this.showScreen = !this.showScreen
            if(this.showCond){
                this.showCond=true;
                this.message="收起筛选"
            }else{
                this.showCond=false;
                this.message="展开筛选"
            }
       
         },
         toggleCH(){
             this.couditionH = this.couditionH == 'auto'?'20px':'auto'
         },
        
        //  分页
        async handleSizeChange(val) {
            await this.query()
            scrollTo(0,0)
        },
        handleCurrentChange(val) {

            this.query()
        },

        //头部筛选切换
        activeFilter(type,val){
            if(!this.searchData[type].includes(val)){
                this.searchData[type].push(val)
            }else{
               this.searchData[type] =   this.searchData[type].filter(scr=>scr!=val)
            }
            this.currentPage = 1
            this.pageSize = 15
            this.query()
        },
        //头部筛选取消选择
        clearActiveFilter(type){
            if(type=='pitchScreen'){
                for(let key in this.searchData){
                    this.searchData[key] = []
                }
                return
            }
            this.searchData[type] = []
            this.currentPage = 1
            this.pageSize = 15
            this.query() 
        },

        //加入购物车
        async joinCart(carItem,index,childIndex){ 
            if(this.$store.state.store_id==this.shopId){
                return this.$warMsg("不能购买自己配件")
            }
            // 昕豫不受库存的限制
            // if(this.$store.state.zero_buy!=1){
            //     if(carItem.goods_storage<1){
            //        console.log("???",carItem.group_storage);
            //       if(carItem.group_storage&&Number(carItem.group_storage)>0){
            //           if(carItem.goods_num+Number(carItem.cartNum)>carItem.group_storage){
            //             return this.$store.commit('UpDateState',{invDia:true})
            //           }
            //       }
            //     }else if(carItem.goods_num+Number(carItem.cartNum)>carItem.goods_storage){
            //         return this.$store.commit('UpDateState',{invDia:true})
            //     }
            // }
            if(this.$store.state.shop==1974||this.$store.state.shop==1871){
                if(carItem.goods_storage==0){
                    carItem.goods_storage = 1
                }
            }
            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_num:carItem.goods_num,
                goods_storage:carItem.goods_storage,
                group_storage:carItem.group_storage||"",
                buyer_id:'',
                order_name:'',
                store_id:carItem.store_id,
                member_id:'',
                buyer_name:'',
                store_name:carItem.store_name,
                cart_type:'1',
                setlist_id:'',
                groupbuy_id:'',
                promotion_id:''
            }
            let res = await this.$api.joinCart([carMessage])
            //更新记录中 已添加X件 字段
            carItem.cartNum = Number(carItem.cartNum) + Number(carItem.goods_num)
            
        },
       
        // 批量查询加入购物车
        async joinCartAll(){
            if(this.$store.state.store_id==this.shopId){
                return this.$warMsg("不能购买自己配件")
            }
            let cart = []
           
            this.goodsList.forEach(goods=>{
                if(this.$store.state.shop==1974){
                if(goods.goods_storage==0){
                    goods.goods_storage = 1
                }
            }
                cart.push({
                goods_id:goods.goods_id,
                sccode:goods.sccode,
                oecode:goods.oecode,
                goods_name:goods.goods_name,
                goods_price:goods.goods_price,
                goods_num:goods.goods_num,
                goods_storage:goods.goods_storage,
                buyer_id:'',
                order_name:'',
                member_id:'',
                buyer_name:'',
                store_id:goods.store_id,
                store_name:goods.store_name,
                cart_type:'1',
                setlist_id:'',
                groupbuy_id:'',
                promotion_id:''
                })
            })



            let res = await this.$api.joinCart(cart)
            
        },

      /*
      调用接口返回搜索结果
      * */
     
        async query(screen){
          
            let res = await this.$api.getFindList({
                shop:this.shopId, // 搜索哪家店铺
                is_chain:this.chain, // 商品类型 
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                store_curpage:this.currentPage, 
                store_page:this.pageSize, // 每页多少个
                group_type:this.group_type, // 结果排序类型 oe 或者商家 用不着
                sort_type:this.sort_type, // 商品排序类型用哪个字段查询传哪个 例库存 
                sort_way:this.sort_way, // 排序方式 1倒序 0正序
                search:this.storeText, // 搜索内容
                type:screen?"screen":'', // 是否需要筛选分类
                storage_type:this.showZero?'ok':'', // 是否过滤0库存
                oenaturebrand:this.searchData.oenaturebrand.join(","),   // 商品品牌 通过返回的screen获取
                oesort:this.searchData.oesort.join(","), // 商品分类 通过返回的screen获取
                shop_type:this.$store.state.shop_type
            })
            
            if(res.data.length>0){
                for(let item of res.data){
                    for(let list_item of item.goods_list){
                        if(!list_item.self_storage){
                            list_item.self_storage = 0
                        }

                        if(!list_item.cartNum){
                            list_item.cartNum = 0
                        }

                        if(!list_item.self_storage){
                            list_item.self_storage = 0
                        }

                        list_item.goods_num = 1
                        list_item.checked = false
                    }
                }
            }
            
            if(res.data[0]){
                this.goodsList = res.data[0].goods_list || []
                this.total = res.data[0].total 
            }
            this.noInquireList = []
            this.goodsList.forEach(goods=>{ 
                this.$handleStorePrice(goods)
                if(this.chain==3){
                    this.$hnwTools.formatGoodsKey(goods)
                }
            })

            if(screen){
                this.screenList = res.screen
            }else if(res.screen1){
                for(let key in res.screen1){
                    this.screenList[key] = res.screen1[key]
                }
            }else{
                
            }
            
        },

        async searches(){
            
            let oecodes =''
            this.$store.state.batchs.forEach(item=>{
                oecodes += item.search+','
            })
            
            oecodes = oecodes.substr(0,oecodes.length-1)
            let res = await this.$api.getSearches({
                searchs:oecodes,
                store_id:this.$store.state.store_id,
                shopId:this.shopId,
                chain:this.chain
            })
            
           
            let arr = res.data[0].goods_list || []
            
            arr.forEach(goods=>{

                let obj = this.$store.state.batchs.find(searOe=>{
                    let noRpOecode = goods.oecode.replace(/\s/g,"")
                    let noRpSccode = goods.sccode.replace(/\s/g,"")
                    let noRpEva = goods.evaluation_good_star.replace(/\s/g,"")
                    let noRpCount = goods.evaluation_count.replace(/\s/g,"")
                    if(searOe.search==goods.oecode||goods.sccode==searOe.search||searOe.search==goods.evaluation_good_star||searOe.search==goods.evaluation_count){
                        return goods
                    }else if(searOe.search==noRpOecode||noRpSccode==searOe.search||searOe.search==noRpEva||searOe.search==noRpCount){
                        return goods
                    }else{
                        return ""
                    }
                    
                })
                if(obj){
                    // this.$set(goods,"goods_num",obj.goods_storage)
                    if(goods.goods_storage==0){
                        // group_storage
                        // 总部有货的情况
                        if(Number(obj.goods_storage)>Number(goods.group_storage)){
                            this.$set(goods,"goods_num",Number(goods.group_storage))
                            this.$set(goods,"batch_pur_num_bey",true)
                            this.$set(goods,"batch_pur_num",obj.goods_storage)
                        }else{
                            this.$set(goods,"goods_num",obj.goods_storage)
                        }
                    }else{
                        if(Number(obj.goods_storage)>Number(goods.goods_storage)){
                            this.$set(goods,"goods_num",Number(goods.goods_storage))
                            this.$set(goods,"batch_pur_num_bey",true)
                            this.$set(goods,"batch_pur_num",obj.goods_storage)
                        }else{
                            this.$set(goods,"goods_num",obj.goods_storage)
                        }
                    }
                }

                this.$handleStorePrice(goods)
                if(this.chain==3){
                    this.$hnwTools.formatGoodsKey(goods)
                }
                // goods.goods_price = this.$handlePrice(goods)

                // if(!Array.isArray(goods.exchange)){
                //     if(!goods.exchange){
                //         goods.exchange = []
                //     }else{
                //         goods.exchange = [goods.exchange]
                        
                //     }
                // }else{
                //     goods.exchange.forEach(exGoods=>{
                        
                //         exGoods.goods_price = this.$handlePrice(exGoods)
                //     })
                // }

                if(this.$store.state.zero_buy!=1){
                    if(goods.cartNum*1>goods.goods_storage*1){
                        goods.cartNum = goods.goods_storage
                    }
                }


            })
            
            let arr1 = []
            let arr2 = []
            arr.forEach(goods=>{
                if(goods.batch_pur_num_bey){
                    arr1.push(goods)
                }else{
                    arr2.push(goods)
                }
            }) 
            arr = [
                ...arr1,
                ...arr2
            ]
            this.goodsList=arr
            if(this.goodsList&&this.goodsList.length>0){
                if(this.goodsList[0].goods_list&&this.goodsList[0].goods_list.length>0){
                    this.goodsList[0].goods_list.forEach(goods=>{
                        
                        this.$set(goods,"cartNum",0)
                    })
                }
            }
            
            this.noInquireList = res.data[0].return
        },
        async readHisList(goods_id){
            let res = await this.$api.getGoodsRecord(goods_id,this.chain)
            this.gooodsHisList = res
            this.hisVis = true
        },
    },    
    props:{  
        chain:""
    },
    computed:{
        // storeText () {
        //     return this.$store.state.demo.id;　　//需要监听的数据
        // },
        pitchScreen(){
            
            let arr = []
            let str = ''
            for(let key in this.searchData){
                if(this.searchData[key]){
                    this.searchData[key].forEach(item=>{
                        str += item + "；"
                    })
                }
                str = str.substr(0,str.length-1)
                if(str){
                    arr.push(str)
                }
                str = ''
            }
            
            return arr
        }
    }
}
</script>

<style lang="scss" scoped>
    .index{
        border-top: 1px solid #f7f7f7;
        padding-bottom: 10px;
    }
    .find-list{
        margin-top: 6px;
        // 弹出框
        overflow: hidden;
       .hezi{
            ::v-deep .el-dialog__header{
                border: none;
            }
        }
    }
    .w{
        width: 1200px;
        margin: 0 auto;
        box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
        .band{
            height: 300px;
            // background-color: #f00;
            img{
              width: 100%;
              height: 100%;
            }
        }
        .sort{
            position: relative;
            height: 38px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-right: 8px;
            border-bottom: 1px solid #50A3F8;
            .sort-left{
                display: flex;
                // width: 86px;
                font-size: 15px;
                // font-weight:bold;
                color: #666;
                // height: 26px;
                // border-left: 1px solid #000;
                // // border-bottom: 2px solid #3E84D6;
                // margin-top: 24px;
                // margin-bottom: 20px;
                div{
                    height: 30px;
                    line-height: 24px;
                    padding: 10px  20px 0px 20px;
                    cursor: pointer;
                }
                .atv{
                    // background: #50A3F8;
                    // color: #fff;
                }
            }
            .short-pag{
                position: absolute;
                top: 11px;
                right: 65px;
                display: flex;
                align-items: center;
                padding-right: 20px;
                .all{
                    padding: 0 10px;
                    
                }
                .filterZero{
                   ::v-deep .el-checkbox__label{
                        line-height: 20px;
                    }
                }
                >.up-down{
                   display: flex;
                   flex-direction: column;
                   padding-left: 5px;
                   div{
                       cursor: pointer;
                   }
                   .notCli{
                       cursor: not-allowed;
                   }
                }
            }
            .sort-right {
                height: 20px;
                text-align: center;
               color: #f00;
                font-size: 14px;
                font-weight: 400;
                // color: #666;
                line-height: 21px;
                cursor:pointer;
            }
            .screen{
                display: flex;
                position: absolute;
                top: 20px;
                right: 0px;
                text-align: right;
               
            }
        }

        .condition-tabel{
            border: 1px solid #ccc;
            border-bottom: none;
            margin-top: 8px;
            .coudition-group{
                display: flex;
                .label{
                    min-width: 80px;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .coudition{
                    border-bottom: 1px solid #ccc;
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    min-height: 33px;
                    .active{
                        color:#3E84D6;
                    }
                    .coudition-left{
                        display: flex;
                        flex-wrap: wrap;
                        overflow: auto;
                        overflow: hidden;
                        padding: 2px 0;
                        position: relative;
                        flex: 1;
                        .coudition-daxiao{
                            margin: 5px;
                            min-width: 10%;
                            border-right: 1px solid #ccc;
                            text-align: center;
                            
                        }
                        .toggle{
                            position: absolute;
                            bottom: -2px;
                            left: 50%;
                            i{
                                cursor: pointer;
                            }
                        }
                    }
                    .pabo10{
                        padding-bottom: 10px;
                    }
                    .item{
                        text-align: center;
                        line-height: 12px;
                        padding: 0 2px;
                        min-width: 5%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        border-right: 1px solid #ccc;
                        cursor: pointer;
                        margin: 5px;
                    }
                    .item:last-child{
                        border-right: none;
                    }
                    .item:hover{
                        color:#3E84D6;
                    }
                }

                .coudition-right{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    min-width: 100px;
                    span{
                        cursor: pointer;
                    }
                }

            }
        }
        .group-list{
            display: flex;
            padding: 10px 0;
            
            .group-item{
                cursor: pointer;
                line-height: 28px;
                border: 1px solid #ccc;
                height: 28px;
                padding: 0 12px;
            }
            .active{
                background: #3E84D6;
                border: 1px solid #3E84D6;
                color: #fff;
            }
        }
        .screen-list{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .screen-item{
                position: relative;
                padding: 0 10px;
                &::before{
                    content: "";
                    position: absolute;
                    width: 1px;
                    height: 60%;
                    left: 0;
                    top: 30%;
                    background: #888;
                }
                
            }
            .screen-item:first-child{
                 &::before{
                    width: 0;
                 }
            }
            
            i{
                cursor: pointer;
            }
            i:hover{
                transform: scale(1.2);
                color: #3E84D6;
            }
        }
        // 商品目录
        .product{
            height: 40px;
            background: #C4C9CC;
            // margin-top: 8px;
            
            ul{
                display: flex;
                // padding-left: 102px;
                box-sizing: border-box;
            li{
                display: flex;
                height: 40px;
                justify-content:center;
                align-items: center;
                position: relative;
                color: #666;
                .ico{
                    // display: flex;
                    // flex-direction: column; 
                    // justify-content: center; 
                    // align-items: center;
                    // >i{
                    //     cursor: pointer;
                    // }
                    // i:hover{
                    // transform: scale(1.5);
                    // color: #3E84D6;
                    // }
                    // .el-icon-caret-top{
                    //     position: relative;
                    //     top: 3px;
                    // }
                    // .el-icon-caret-bottom{
                    //     position: relative;
                    //     top: -3px;
                    // }

                    width: 10px;
                    height: 16px;
                    img{
                      width: 12px;
                    height: 12px;  
                    vertical-align: middle;
                    }
                }
                .active{
                    color: #3E84D6;
                }

                &:nth-child(1){
                    // margin: 0 0 0 102px;
                    min-width: 279px;
                    // max-width: 82px;
                    max-width: 230px;
                }
                 &:nth-child(2){
                    // min-width: 150px;
                    // max-width: 150px;
                    min-width: 218px;
                    max-width: 220px;
                }
                 &:nth-child(3){
                    min-width: 167px;
                    max-width: 170px;
                }
                 &:nth-child(4){
                    min-width: 176px;
                    max-width: 130px;
              
                }

                
                 &:nth-child(5){
                    min-width: 130px;
                    max-width: 130px;
                    // flex: 1;
                }
                 &:nth-child(6){
                    min-width: 130px;
                    max-width: 130px;
                    // flex: 1;
                }
                 &:nth-child(7){
                    min-width: 87px;
                    max-width: 130px;
                }
                 &:nth-child(8){
                    min-width: 130px;
                    max-width: 130px;
                }
                 &:nth-child(9){
                    min-width: 130px;
                    max-width: 130px;
                }
                .filterZero{
                    position: absolute;
                    top: 24px;
                   
                }
            }
        }
     }
       
          // 商品信息
          .available{
            
            >div{
                 height: 48px;
                line-height: 48px;
                padding-left: 20px;
                border-bottom: 1px solid #dadada;
                background: #FFFFFF;
                box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
                font-size: 16px;
               .red{
                   color: #f00;
               } 
               .blue{
                   color: #50A3F8;
               }
            }
        }
        .cargo{
            display: flex;
            box-sizing: border-box;
            position: relative;
            // box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.13);
            // margin-bottom: 20px;
            box-shadow: none;
            >ul{
                width: 100%;
                li:nth-child(2n)
                {
                background:#fff;
                }
            }
            .case-list{
                &.isAll{
                 height: auto;
                }
            }
            
            .case{
                padding-bottom: 10px;
                height: 30px;
                line-height: 44px;
                border-bottom:  1px solid #9e9e9e;
                position: relative;
                .company {
                    display:inline-block;
                    padding:0 20px 0 17px;
                    font-size: 14px;
                    color: #333;
                    font-weight: 700;
                }
                .phone{
                    font-size: 14px;
                    color: #666;
                    padding-right: 20px;
                    img{
                        width: 14px;
                        height: 14px;
                    }
                }
                .filterZero{
                    margin-left: 10px;
                }
            }
                

            li{
                &:nth-child(2n+1){
                    background: #f6f6f7;
                }
                .details{
                display: flex;
                height: 67px;
                // line-height: 55px;
                // border-bottom: 1px solid #ccc;
                position: relative;
                .el-checkbox {
                    width: 84px;
                    padding-left: 18px;
                }
                .isOe{
                    // min-width: 194px;
                    // max-width: 82px;
                    width: 275px;
                    border-right: 1px solid #E3E4EA;
                    
                    .isOe-top{
                        display: flex;
                        margin-top: 14px;
                        margin-bottom: 10px;
                        // margin: 0 0 0 28px;
                        margin-left: 10px;
                        .name{
                            width: 48px;
                            height: 20px;
                            background-color:#3E84D6;
                            font-size: 12px;
                            text-align: center;
                            line-height: 20px;
                            color: #fff;
                            
                        }
                        .number{
                            cursor:pointer ;
                            // min-width: 180px;
                            max-width: 100px;
                            // max-width: 180px;
                            margin-left: 5px;
                            margin-top: 2px;
                            overflow: hidden; //超出的文本隐藏
                            text-overflow: ellipsis; //溢出用省略号显示
                            white-space: nowrap; //溢出不换行
                            padding-top: 0;
                            color: #333;
                            // display: flex;
                            
                        }
                            .tag-icon{
                                display: flex;
                                align-items: center;
                                transform: scale(.7);
                                justify-content: center;
                                width: 20px;
                                height: 20px;
                                background: $vin-blue;
                                color: #fff;
                                border-radius: 50%;
                                font-size: 12px;
                                position: relative;
                                top: -8px;
                                left: 2px;
                            }
                    }
                    .vehicleModel{
                    margin: 0 0 0 10px;
                    max-width: 400px;
                    box-sizing: border-box;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    cursor:pointer;;
                    font-size: 14px;
                    color: #999;
                    .series-span{
                        margin-right: 10px;
                    }
                }
                }
                
                .productName{
                    // min-width: 150px;
                    // max-width: 150px;
                    width: 221px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    overflow: hidden;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .encoding{
                    overflow: hidden;
                    width: 169px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .brand{
                    overflow: hidden;
                    width: 160px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .place{
                    overflow: hidden;
                    width: 130px;
                    line-height: 69px;
                    border-right: 1px solid #E3E4EA;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .nature{
                    overflow: hidden;
                    width: 130px;
                    border-right: 1px solid #E3E4EA;
                    line-height: 69px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .inventory{
                    overflow: hidden;
                    width: 130px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    border-right: 1px solid #E3E4EA;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    .red{
                        font-weight: 700;
                        margin-top: 5px;
                        font-size: 12px;
                        transform: scale(0.9);
                        transform-origin: left center;
                    }
                }
                .TheOrderPrice{
                    overflow: hidden;
                    width: 144px;
                    border-right: 1px solid #E3E4EA;
                    line-height: 69px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .area{
                    overflow: hidden;
                    width: 130px;
                    border-right: 1px solid #E3E4EA;
                    line-height: 69px;
                    text-align: center;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
                .numAd{
                    width: 116px;
                   border-right: 1px solid #E3E4EA;
                   line-height: 69px;
                   position: relative;
                   .el-input-number{
                        width: 100px;
                        margin: 0 64px 0 7px;
                   }
                   .goods-Fcl-tit{
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 2px;
                    font-size: 12px;
                    line-height: 12px;
                    width: 120px;
                    text-align: center;
                    color: #f00;
                   }
                }
                .handle-box{
                    position: relative;
                    box-sizing: border-box;
                    padding: 5px 0;
                    width: 108px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    // justify-content: space-between;
                    >div{
                        height: 20px;
                        line-height: 20px;
                    }
                    .addition{
                        font-size: 14px;
                        color: #999;
                    }
                    .replace-btn{
                        color: #3E84D6;
                        cursor: pointer;
                    }
                }
                .el-input-number{
                    width: 100px;
                    margin: 0 64px 0 20px;
                }
            .el-button--default {
                width: 80px;
                height: 22px;
                border: 1px solid #3E84D6;
                padding: 0;
                color: #59A8F8;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                border-radius: 0px;
                margin-top:19px;
            }
                
                
                .stock{
                    position: absolute;
                    right: 160px;
                    top: 26px;
                    font-size: 14px;
                    color: #999;
                }
                // .addition{
                //     position: absolute;
                //     right: 5px;
                //     top: 44px;
                //     height: 15px;
                //     line-height: 15px;
                //     font-size: 14px;
                //     color: #999;
                // }
            }
            .buyHis{
                    font-style: italic;
                    text-align: right;
                    line-height: 30px;
                    height: 30px;
                    // color: #FF7519;
                    color: #f33;
                    border-top: 1px solid #E3E4EA;
                    font-size: 12px;
                    >span{
                            margin-right: 10px;
                            align-items: center;
                        }
                        .readMore{
                            cursor: pointer;
                        }
                }
            }
            .pagings{
                display: flex;
                justify-content:flex-end;
                align-items: center;
                margin: 20px 0;
            } 
                


        }
        .paging{
            display: flex;
            justify-content:flex-end;
            background-color: #fff;
            padding: 20px 0;
        } 
        .shoppingAll{
                display: flex;
                justify-content:flex-end;
                align-items: center;
                // margin: 20px 0;
                padding-top: 20px;
                padding-right: 20px;
                padding-bottom: 10px;
                background-color: #fff;
                >div{
                    // width: 100px;
                    // height: 40px;
                    background-color: #3E84D6;
                    color: #fff;
                    padding: 0 5px;
                    line-height: 40px;
                    text-align: center;
                    // border: 1px solid #000;
                     border-radius: 5px;
                }
            }
         .el-dialog__body{
        // padding: 10px 20px;
        padding: 0 !important;
        .his-list{
                .list-head{
                    display: flex;
                    height: 40px;
                    align-items: center;
                    background: #EEEEEE;
                    margin: 10px 0;
                    padding: 0 8px;
                    >div{
                        flex: 1;
                        text-align: center;
                    }
                    .col-100{
                        max-width: 100px;
                    }
                }
                .list-body{
                    .row{
                        display: flex;
                        padding: 0 8px;
                        >div{
                            flex: 1;
                            padding: 2px 0;
                            border-bottom: 1px solid #eee;
                            flex: 1;
                            text-align: center;
                            line-height: 28px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .col-100{
                            max-width: 100px;
                        }
                    }
                }
            }
    }
}
</style>
