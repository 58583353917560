<template>
<div class="integral-index">
    <!-- <div class="integral-title">
        <div class="w">
            <div class="inte-types">
                <div class="inte-type" :class="{'acti-inte':active==modu}" @click="toModu(modu)" v-for="modu in inteModus" :key="modu">
                    {{modu}}
                </div>
            </div>
            <div class="inteNum">
                <div class="label">
                    我的积分:
                </div>
                <div class="red num">
                    {{$store.state.integral_num}}
                </div>
            </div>
        </div>
    </div> -->
    <div class="nav-box hnw-area-center" id="nav-box">
       
        <div class="nav-list"   :class="{'fiexd':scrollDistance>120}" :style="navObj">
            <div v-for="modu in inteModus" :key="modu" @click="toModu(modu)"  :class="{'active':modu==active}" class="nav-item" >  {{ modu }}</div>
            <!-- <div class="nav-item">
                我的积分:
                <div class="inte-num">
                    {{$store.state.integral_num}}
                </div>
                
            </div> -->
        </div>
    </div>
    
    <div class="inte-content">
        <div class="w">
            
           <inteGoodsList v-if="active=='积分商城'"></inteGoodsList>
           <inteDetail v-else-if="active=='积分明细'"></inteDetail>
           <inteRecord v-else-if="active=='兑换记录'"></inteRecord>
           <Raffle v-else-if="active=='积分抽奖'"></Raffle>
           <inteRaOrder v-else-if="active=='抽奖订单'"></inteRaOrder>
        </div>
    </div>
    
    <div class="dia-box">
        <el-dialog
            :show-close="false"
            :visible.sync="dialogVisible"
            width="602px"
        >
            <div class="inte-dia-content">
                <div class="sing-img-box">
                     <div class="sing-btn" @click="singAdd">

                     </div>
                    <div class="handle-box" :class="{'han-10':isSign}" @click="dialogVisible=false">
                        <!-- <i class="el-icon-circle-close"></i> -->
                    </div>
                    <img class="sing-img" src="../../assets/integral/signover.png" alt="" v-if="isSign">   
                    <img class="sing-img" src="../../assets/integral/sing.png" alt="" v-else>   
                </div>
            </div>
        </el-dialog>
    </div>
</div>
</template>

<script>
import inteGoodsList from "./inteCompent/inteGoodsList.vue"
import inteDetail from "./inteCompent/inteDetail.vue"
import inteRecord from "./inteCompent/inteRecord.vue"
import Raffle from "./inteCompent/Raffle.vue"
import inteRaOrder from "./inteCompent/inteRaOrder.vue"
export default {
    data(){

        return{
           inteModus:["积分商城","积分明细","兑换记录","积分抽奖","抽奖订单","积分签到"],
           active:"积分商城",
        //    今日是否已经签到
           isSign:false,
           dialogVisible:false,
           navLeft:0,
           navObj:{},
           scrollDistance: 0 
        }
    },
    methods:{ 
        async toModu(modu){
            if(this.$store.getters.isVisitor){
               return 
            }
            if(modu=="积分签到"){
                let nowTime = new Date()
                let mouth = nowTime.getMonth()+1
                let nowTimeStr  = (mouth<10?"0"+mouth:mouth) + "-" + (nowTime.getDate()<10?"0"+nowTime.getDate():nowTime.getDate())
                // console.log("nowTimeStr",nowTimeStr)
                let params = {
                    
                }
                if(this.$route.params.id){
                    params.shop = this.$route.params.id || "1780"
                }
                let res = await this.$api.getInteSigninMsg(params)
                // console.log("res",res);
                if(res.data&&res.data.length>0){
                    let Index = res.data.findIndex(date=>date==nowTimeStr)
                    if(Index!=-1){
                        console.log("今日已签到");
                        this.isSign = true
                    }else{
                        console.log("今日未签到");
                    }
                }
                this.dialogVisible = true
            }else{
                this.active = modu
            }
        },
        async singAdd(){
            if(this.isSign){
                this.$suMsg("今天已签到")
                return 
            }
            let res = await this.$api.inteSignAdd({
                shop:this.$route.params.id || "1780"
            })
            if(res){
                this.$suMsg("签到成功")
                this.$store.dispatch("getInteNum")
                this.isSign = true
            }else{
                this.$erMsg("签到失败")
            }
        },
        handleScroll() {
			// 获取当前的滚动距离
			this.scrollDistance =  document.documentElement.scrollTop;
			if(this.scrollDistance>120){

				this.navObj.left = this.navLeft + "px"
			}else{
				try {
					const fixedElement = document.getElementById('nav-box');
					const rect = fixedElement.getBoundingClientRect();
					const leftDistanceToDocument = rect.left + window.pageXOffset;
                   
					this.navLeft = leftDistanceToDocument - 140
					this.navObj = {}
				} catch (error) {
					
				}
			}
		},
		handleResize() {
			try {
				const fixedElement = document.getElementById('nav-box');
				const rect = fixedElement.getBoundingClientRect();
				const leftDistanceToDocument = rect.left + window.pageXOffset;
				this.navLeft = leftDistanceToDocument - 140
			} catch (error) {
				
			}

		},
    },
    async created(){
        this.$api.getAnalysisLog("integral")
        // 首页防直营
        if(this.$store.state.for_branch&&this.$store.state.for_branch!=1780){
            this.$store.dispatch("getThan")
            this.$router.push(`/store/businesses/${this.$store.state.for_branch}`)
        }else if(this.$store.state.shop&&this.$store.state.shop!=1780){ 
            this.$store.dispatch("getThan")
            this.$router.push(`/store/businesses/${this.$store.state.shop}`)
        }
       console.log();
       
        this.$store.dispatch("getInteNum")
        this.$api.getRamInteOrder({
            buyer_id:this.$store.state.store_id,
			buyer_name:this.$store.state.store_name,
			member_id:this.$store.state.member_id,
			member_name:this.$store.state.member_name
        })
        // let res = await this.$api.inteSignAdd()
    },
    mounted() {
		// 添加滚动事件监听器
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleResize);
		// 如果你想要得到元素左边相对于整个文档（而不仅仅是当前视口）的距离，
		// 你需要加上当前的滚动偏移量
		
		
	},
	onBeforeUnmount(){
		window.removeEventListener('scroll', handleScroll);
		window.removeEventListener('resize', this.handleResize);
	},
    components:{
        inteGoodsList,
        inteDetail,
        inteRecord,
        Raffle,
        inteRaOrder
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.integral-index{
    background: #f9f9f9;
    .nav-box{
        position: relative;
    }
    .nav-list{
        width: 110px;
        align-self: flex-start;
        left: -140px;
        position: absolute;
        transition: top 0.3s ease;
        top: 20px;
        .nav-item{
            background-color: #fff;
            width: 110px;
            height: 48px;
            text-align: center;
            // line-height: 48px;
            border-bottom: 1px solid  #F2F2F2;
            cursor: pointer;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            i{
                margin-bottom: 2px;
            }
            .inte-num{
                margin-top: 5px;
            }
        }
        .active{
            background: #f00;
            color: #fff;
        }
        .nav-item:hover{
            background: #f00;
            color: #fff;

        }
    }
    .fiexd{
    
        position: fixed;
        top: 20px;
    }
    .integral-title{
        box-shadow:0px 5px 6px 0px rgba(41, 49, 56, 0.19);
        .w{
            display: flex;
            justify-content: space-between;
            
            .inte-types{
                display: flex;
                >div{
                    padding: 15px;
                    cursor: pointer;
                }
                .acti-inte{
                    color: #3E84D6;
                }
            }
            .inteNum{
                display: flex;
                align-items: center;
                font-weight: 700;
                text-align: center;
                margin-right:10px;
                .label{
                    color: #999;
                    padding-right: 5px;
                }
                .num{
                    border-radius: 3px;
                    background: red;
                    color: #fff;
                    min-width: 36px;
                    box-sizing: border-box;
                    padding: 5px 10px;
                }
            }
        }
    }
    .inte-content{
        // background: #f5f5f5;
        min-height: 400px;
        padding-top: 10px;
        .w{
            
        }
    }
    .dia-box{
        .el-dialog{
            background:transparent;
        }
        .el-dialog__header{
            padding: 0;
            border: none;
        }
        .el-dialog__body{
            padding: 0;
            background: transparent;
        }
        .inte-dia-content{
            background: transparent;
            position: relative;
            
            .sing-img-box{
                position: relative;
                background: transparent;
                // bottom: -460px;
                .sing-btn{
                    position: absolute;
                    cursor: pointer;
                    width: 320px;
                    height: 68px;
                   
                    bottom: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                }
                .sing-img{
                    background: transparent;
                }
                .handle-box{
                    position: absolute;
                    right: 24px;
                    top: 68px;
                    z-index: 999;
                    width: 40px;
                    height: 40px;
                    cursor: pointer;
                    
                    i{
                        // color: #fff;
                        font-size: 40px;
                        cursor: pointer;
                    }
                }
                .han-10{
                    top: 10px;
                }
            }
        }
    }
}
</style>