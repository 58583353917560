<template>
<div class="inte-goodsList">
      <div class="group-sort">
        <inteGroups :groups="clasList" @modeChange="modeChange" :noBor="true"></inteGroups>
        <div class="sort-nav">
            <div class="label">
                排序方式：
            </div>
            <div class="sort-list">
                <div class="clear-sort" @click="clearSort" :class="{'active':sort_k==''}">
                    综合排序
                </div>
                <div class="sort-item">
                    销量
                    <SortHandler @sortCutWay="sortCutWay" type="sales" :activeType="sort_k"></SortHandler>
                </div>
                <div class="sort-item">
                    积分
                    <SortHandler @sortCutWay="sortCutWay" type="price"  :activeType="sort_k"></SortHandler>
                </div>
            </div>
        </div>
      </div>
     
     <div class="goods-list">
        <div class="card-box" v-for="(item,index) in goodsList" :key="index">
            <div  class="goods-card hnw-hov" >
                <div class="img-box"  @click="toGoodsInfo(item)" >
                    <img :src="item.images[0]" alt="">
   
                </div>
                <div class="card-fot">
                    <!-- 价格和仓位 -->
                    <div class="goods-name">
                         {{item.name}}
                    </div>
                    <div class="pri_warehouse">
                        
                        <div class="ware">
                            限量 {{item.stock}} 件
                        </div>
                        <el-input-number  v-model="item.goods_num"  :max="Number(item.stock)" :min="1"  size="mini"></el-input-number>
                    </div>
                    <div class="inte-price">
                        <div class="num">
                            <span v-if="$isCompany()">

                                {{ item.price }}
                            </span>
                            <span v-else>
                                会员可见
                            </span>
                        </div>
                        <div class="text">
                            积分
                        </div>
                    </div>
                    <div class="handle">
                        <div class="btn" @click.stop="buyNow(item)">
                            兑换
                        </div>
                       
                    </div>
                </div>
            </div>
        </div>
        <!-- <el-row  >
            <el-col :span="4.8" v-for="(item,index) in goodsList" :key="index" class="goods-col">
                

            </el-col>
        </el-row> -->
     </div>
    <div class="paging">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="page"
            :page-sizes="[10,20,30]"
            :page-size.sync="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="Math.ceil(total)">
        </el-pagination>
    </div>  
</div>
</template>

<script>
import inteGroups from "./inteGroups.vue"
import SortHandler from "../../../components/SortHandler.vue"
import HnwOssImg from "../../../components/hnwOssImg.vue";
export default {
    data(){
        return{
            groups:[
                {
                    title:"全部",
                    value:""
                },
                {
                    title:"礼品兑换",
                    value:"goods"
                },
                {
                    title:"配件兑换",
                    value:"parts"
                },
            ],
            clasList:[
                {
                    title:"综合",
                    value:""
                }
            ],
            
            page:1,
            limit:10,
            mode:"",
            total:20,
            goodsList:[],
            sort_k:"",
            sort_v:"",
            classify:""
        }
    },
    methods:{
        async handleSizeChange(val) {
            await this.getList()
            
            scrollTo(0,0)
        },
        clearSort(){
           this.sort_k = ""
           this.sort_v = ""
           this.getList()
        },
        sortCutWay(sort){
           this.sort_k = sort.sort_type
           this.sort_v = sort.sort_way
           this.getList()
        },
        handleCurrentChange(val) {
            this.getList()
        },
        modeChange(mode){
            this.classify = mode
            
            this.getList()
        },
        async getList(){
            let params ={
            }
            if(this.$route.params.id){
                params.shop = this.$route.params.id || "1780"
            }
            let res = await this.$api.getIntegrallist({
                limit:this.limit,
                page:this.page,
                mode:this.mode,
                classify:this.classify,
                sort_k:this.sort_k,
                sort_v:this.sort_v,
                ...params
            })
            

            this.total = res.count
            if(res.data){
                res.data.forEach(goods => {
                    if(goods.images){
                        // console.log(goods.images)
                        try {
                            goods.images = JSON.parse(goods.images)
                            goods.goods_num = 1
                        } catch (error) {
                            
                        }
                    }
                    
                })
                this.goodsList = res.data 
            } 
            
        },
        buyNow(goods){
            if(!this.$store.getters.isVisitor){
                this.$router.push("/integral/suborder/"+goods.id +"/"+goods.goods_num)
            }
        },
        toGoodsInfo(goods){
            if(this.$isCompany()){
                let rou = '/integral/info'
                if(this.$route.params.id){
                    goods.preRou = "积分礼品"
                    localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
                    this.$emit("showInteInfo")
                }else{
                    goods.preRou = "积分礼品"
                    
                    localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
                    
                    this.$router.push(rou)
                }
            }
            
        }
    },
    async created(){
        this.getList()
        let res = await this.$api.getInGoodsClass({
            shop:this.$route.params.id||"1780"
        })
       
        if(res&&res.length>0){
            res.forEach(item=>{
                if(item.classify){
                    this.clasList.push({
                        title:item.classify,
                        value:item.classify
                    })
                }
            })
        }
    },
    components:{
        inteGroups,
        SortHandler,
        HnwOssImg
    },
    props:{
        
    }
}
</script>

<style lang="scss">
.inte-goodsList{
    .group-sort{
       
        .sort-nav{
            display: flex;
            // justify-content: flex-end;
            align-items: center;
            // padding: 8px 12px;
            background: #fff;
            height: 38px;
            line-height: 38px;
            background: #fff;
            padding-left: 20px;
            margin-bottom: 5px;
            .label{
                font-size: 14px;
            }
            .sort-list{
                display: flex;
                align-items: center;
                >div{
                    padding: 0 12px;
                }
                .clear-sort{
                    cursor: pointer;
                }
                .active{
                    color: #3E84D6;
                }
                .sort-item{
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
    .goods-list{
        // background: $backGray;
        background: #fff;
        display: flex;
        flex-wrap: wrap;
        padding: 15px 10px;
        .card-box{
            width: 20%;
            box-sizing: border-box;
            padding: 0 5px;
            .goods-card{
                padding: 10px 0;
                background: #fff;
                cursor: pointer;
                // height: 310px;
                box-sizing: border-box;
                display: flex;
                margin-bottom: 10px;
                flex-direction: column;
                border-radius: 4px;
                border: 1px solid #DCDCDC;
                .img-box{
                    // cursor: pointer;
                    height: 210px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        // width: 229px;
                        // height: 130px;
                        width: 210px;
                        height: 210px;
                    }
                }
                .card-fot{
                    box-sizing: border-box;
                    padding: 10px;
                    padding-bottom: 0;
                    .goods-name{
                        
                        line-height: 22px;
                        min-height: 22px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        font-size: 14px;
                        color: #333333;
                    }
                    .pri_warehouse{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding-top: 8px;
                        .ware{
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            font-size: 12px;
                            color: #999999;
                        }
                    }
                    .inte-price{
                        display: flex;
                        align-items: center;
                        padding: 8px 0;
                        .num{
                            font-family: MiSans;
                            font-weight: 800;
                            font-size: 24px;
                            color: #10317C;
                        }
                        .text{
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            font-size: 12px;
                            color: #999999;
                            margin-left: 10px;
                        }
                    }
                    .handle{
                        display: flex;
                        justify-content: center;
                        .btn{
                            width: 100%;
                            background: #10317C;
                            border-radius: 4px;
                            cursor: pointer;
                            text-align: center;
                            font-family: Source Han Sans CN;
                            font-weight: 400;
                            font-size: 16px;
                            color: #FFFFFF;
                            height: 40px;
                            line-height: 40px;
                        }
                    }
                }
            }
        }
        
        
    }
    
    .paging{
        display: flex;
        justify-content:right;
        padding-top: 10px;
        .el-pagination{

        }
    }
}
</style>