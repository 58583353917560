// 007接口

import axios from "axios"
let Authorization = ""
let expires = ""

// vin查询

const fetch = async (url,data,method='post',target='')=>{
    let hj =  axios.defaults.baseURL
    let res = ""
    let headUrl = ""
    // headUrl = "https://xmall.51qcj.com:1443" //测试
    // headUrl = "https://openapi.51qcj.com" //正式
    // 缓存接口
    // https://api.huanuowei.cn/web/mobile/index.php?act=api&op=syncEpc
    headUrl = "https://openapi.51qcj.com"
    // if(hj=='https://api.huanuowei.cn/web/'){
    // }else{
    //     headUrl = "https://xmall.51qcj.com:1443"
    // }
    // 如果没有Authorization
    if(!Authorization){
        let res = await axios({
            method:"post",
            url: headUrl + "/api/v1.0/secret/token",
            isScd:true,
            
            data:{
                license:"ffd7375e80a9e1d98610b372a7f3a4a6"
            }
        })
        Authorization = res.data.type +" " + res.data.access
        expires = res.data.profile.expires
    }else{

        let nowTime = new Date().getTime()/1000
        if(nowTime>expires){
            console.log("已经过期");
            let res = await axios({
                method:"post",
                url: headUrl + "/api/v1.0/secret/token",
                isScd:true,
                data:{
                    license:"ffd7375e80a9e1d98610b372a7f3a4a6"
                }
            })     
            Authorization = res.data.type +" " + res.data.access
            expires = res.data.profile.expires
        }
    }

    if(method=='post'){
        if(target=='hnw'){
            res = await axios({
                method:"post",
                url: hj + url, 
                headers:{
                    Authorization
                },
                data,
                
                // params:{
                //     manufacturer_id:data.manufacturer_id,
                //     vin:data.vin
                // }
            })
        }else{

            res = await axios({
                method:"post",
                url: headUrl + url,
                isScd:true,
                showMsg:data.showMsg||null,
                headers:{
                    Authorization
                },
                data,
                params:{
                    manufacturer_id:data.manufacturer_id,
                    vin:data.vin
                }
            })
        }
        
    }else{
        res = await axios({
            method:"get",
            url: headUrl + url,
            isScd:true,
            showMsg:data.showMsg||null,
            headers:{
                Authorization
            },
            params:data
        })
    }
// 对数据进行缓存
//     gw：接口地址
// param：请求参数
// resp：返回数据
// vin：非必需，则不传。车架号
// sku：非必需，则不传，相关配件，原厂oe号
    // 排除接口
    if(target!='hnw'){
        // try {
           
            
        //     if(!url.includes('public/getmanufacturer')){
        //         axios({
        //             method:"post",
        //             url: "https://api.huanuowei.cn/mobile/index.php?act=api&op=syncEpc",
        //             headers:{
        //                 Authorization
        //             },
        //             data:{
        //                 gw:res.responseURL.replace("&showMsg=true",""),
        //                 resp:res.respJson,
        //                 vin:data.vin?data.vin:null,
        //                 sku:data.part_number?data.part_number:null,
        //                 param:JSON.stringify(data)
        //             }
        //         })
        
        //     }
        // } catch (error) {
        //     console.log(error);
            
        // }
        try {
            return res.data
        } catch (error) {
            return ""
        }
        // if(res&&res.data){
        // }else{
        //     return res
        // }
    }else{
        return res
    }
}

// vin查询
const vinSearch = async (vin)=>{
    return await fetch("/api/v2.0/epcweb/public/getvin",{
        vin,
        showMsg:true
    },"get")
}
// 解析车辆详情
const getVinInfo = async (data)=>{
    return await fetch("/api/v2.0/epcweb/public/getvinprcode",{
        ...data
    },"get")
}

// 通过名称搜索该
// const vinSearchByName = async (data)=>{
//     return await fetch("/api/engine/parts/search_key_v2",{
//         ...data
//     })
// }
// 解析vin图片
const ocrvin = async (data)=>{
    return await fetch("/api/v2.0/epcweb/other/ocrvin",{
        ...data
    })
}
// 查询配件详情
const vinPartSearch = async (data)=>{
    return await fetch("/api/v2.0/epcweb/group/getpartdetail",{
        ...data
    })
}

// ,'fetch'
// 全车件零件标签查询
// const VinTagsQuery = async (data)=>{
//     return await fetch("/api/cars/part/tags",{
        
//         ...data
//     })
// }

// 获取主分组
const getMainGroupList = async (data)=>{
    // console.log(data)
    return await fetch("/api/v2.0/epcweb/group/getmaingroup",{
        ...data
    })
}

// 获取子分组
const getBranchGroupList = async (data)=>{
    return await fetch("/api/v2.0/epcweb/group/getsubgroup",{
        ...data
    })
}
// 获取图组
const getImagegroup = async (data)=>{
    return await fetch("/api/v2.0/epcweb/group/getimagegroup",{
        ...data
    })
}

// 获取配件基础信息
// const getGoodsBaseInfo = async (data)=>{
//     return await fetch("/api/parts/baseinfo",{
//         ...data
       
//     })
// }


// 获取4s价格 
const getPartPrice = async (data)=>{
    return await fetch("index.php?act=data&op=epcweb&model=get4sprice",
        data
    ,'post','hnw') 
}
//  华诺威替换件 
const getHnwPartReplace = async (data)=>{
    return await fetch("index.php?act=data&op=epcweb&model=getreplace",
        data
    ,"post","hnw")
}
// 华诺威缓存适配车型
const getHnwPartVeh = async (data)=>{
    return await fetch("index.php?act=data&op=epcweb&model=getfitseries",
        data
    ,"post","hnw")
}

// // 获取参考价格
// const getPartPrice = async (data)=>{
//     return await fetch("/api/v2.0/epcweb/detail/get4sprice",{
//         ...data
//     },"get")
// }
// // 获取替换件
const getPartReplace = async (data)=>{
    return await fetch("/api/v2.0/epcweb/detail/getreplace",{
        ...data
    },"get")
}
// 获取适配车型

const getPartVeh = async (data)=>{
    return await fetch("/api/v2.0/epcweb/detail/getfitseries",{
        ...data
    },"get")
}
// 获取子配件清单
const getPartKitr = async (data)=>{
    return await fetch("/api/v2.0/epcweb/detail/getpartkit",{
        ...data
    },"get")
}
// 获取配件实物图
const getPartImage = async (data)=>{
    return await fetch("/api/v2.0/epcweb/detail/getrealimage",{
        ...data
    },"get")
}

// 获取所有品牌
const getAllBrand = async ()=>{
    return await fetch("/api/v2.0/epcweb/public/getmanufacturer",{

    },"get")
}
// 获取车型详细节点/标题
const getBrandTitle = async (data)=>{
    return await fetch("/api/v2.0/epcweb/car/getcondition",{
        ...data
    })
}

// 获取二级品牌 
const getNextBrand = async (data)=>{
    return await fetch("/api/v2.0/epcweb/car/getbrand",{
        ...data,
        showMsg:true
    })
}
// 获取车系
const getCarseries = async (data)=>{
    return await fetch("/api/v2.0/epcweb/car/getcarseries",{
        ...data,
        showMsg:true
    })
}
// 获取详细车型
const getModels = async (data)=>{
    return await fetch("/api/v2.0/epcweb/car/getmodels",{
        ...data
    })
}

// 获取车型详细节点列表
const getSeriescondition = async (data)=>{
    return await fetch("/api/v2.0/epcweb/car/getseriescondition",{
        ...data
    })
}
// 通过oe或者名称搜索配件
const getPartByOe = async (data)=>{
    return await fetch("/api/v2.0/epcweb/search/getpartbycondition",{
        ...data,
        showMsg:true
    },"get")
}
// 条件译码
const getPartByVinQuery = async (data)=>{
    return await fetch("/api/v2.0/epcweb/decode/getpartdecode",{
        ...data
    })
}
// 获取下拉列表
const getGroupNameList = async (data)=>{
    return await fetch("/api/v2.0/epcweb/decode/getpartgroupnamelist",{
        ...data
    })
}
//  获取配件配置
// const getPartConfig = async (data)=>{
//     return await fetch("/api/epc/car/parts/sa_info",{
//         ...data
//     })
// }
//  定制接口 通过vin号查询华诺威包含商品
const getCustomVinSearch = async (data)=>{
    return await fetch("/api/v2.0/epcweb/query/getpartnumber",{
        ...data
    })
}
// 配件跳转
const getPartJump = async (data)=>{
    return await fetch("/api/v2.0/epcweb/detail/getpartjump",{
        ...data
    })
}
// 配件定位
const getPartLocation = async (data)=>{
    return await fetch("/api/v2.0/epcweb/search/getpartlocation",{
        ...data
    })
}
// 获取配件名词解释
const getPartPlainByid = async (data)=>{
    return await fetch("/api/v2.0/epcweb/detail/getpartnameexplain",{
        ...data
    },"get")
}
// 获取名词解释
const getPlain = async (data)=>{
    return await fetch("/api/v2.0/epcweb/search/getnameexplain",{
        ...data
    })
}
//  获取配件配置
// const getPartConfig = async (data)=>{
//     return await fetch("/api/epc/car/parts/sa_info",{
//         ...data
//     })
// }
const apiScd = {
    vinSearch,
    getVinInfo,
    ocrvin,
    vinPartSearch,
    getMainGroupList,
    getBranchGroupList,
    getImagegroup,
    getPartPrice,
    getPartReplace,
    getPartVeh,
    getHnwPartReplace,
    getHnwPartVeh,
    getPartKitr,
    getPartImage,
    getAllBrand,
    getBrandTitle,
    getNextBrand,
    getCarseries,
    getModels,
    getSeriescondition,
    getPartByOe,
    getPartByVinQuery,
    getGroupNameList,
    getCustomVinSearch,
    getPartJump,
    getPartPlainByid,
    getPlain,
    getPartLocation
}

export default apiScd