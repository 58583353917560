<template>
    <div class="hnw-copy" :style="{'width':w+'px'}">
        <img class="copy-btn" @click.stop="copy" src="../assets//vin/copy.png" alt="" :style="{'left':left+'px','top':top+'px'}">
    </div>
    
    </template>
    
    <script>
    export default {
        data(){
            return{
                
            }
        },
        methods:{
            copy(){
                if(this.data){

                    var textareaEl = document.createElement('textarea');
                    textareaEl.value = this.data;
                    document.body.appendChild(textareaEl);
                    textareaEl.select();
                    var res = document.execCommand('copy');
                    
                    document.body.removeChild(textareaEl);
                    this.$suMsg("复制成功")
                    return res;
                }
               
            }
        },
        created(){
        },
        props:{
            data:"",
            left:"",
            top:"",
            w:""
        }
    }
    </script>
    
    <style lang="scss">
    .hnw-copy{
        position: relative;
        margin-left: 5px;
        cursor: pointer;
        .copy-btn{
            position: absolute;
            width: 16px;
            z-index: 999;
            cursor: pointer;
        }
    }
    </style>