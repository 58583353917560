<template>
<div class="brand-Talk">
    <div class="hnw-area-center" id="talk-content">
        <div class="nav-list" :class="{'fiexd':scrollDistance>170}" :style="navObj">
            <div v-for="(brand,index) in brandList" :key="index"  @click="getClassGoodsList(brand)" class="nav-item" :class="{'active':brandInfo.id==brand.id}"> 
                <img :src="brand.icon" alt="" srcset="">
            </div> 
        </div>
        <div class="banner">
            <img :src="brandInfo.url" alt="" srcset="">
        </div>
        <div class="class-goods-list">
            <div class="brand-modu" v-for="clasItem in classList" :key="clasItem.id">
                <div class="brand-title">
                    <img :src="clasItem.url" alt="" srcset="">
                </div>
                <div class="goods-list">
                    <div class="goods-item" v-for="goods in clasItem.goodsList" :key="goods.id" @click="toInfo(goods)">
                        <img :src="goods.url" alt="" srcset="">
                        <div class="goods-text">
                            {{ goods.name }}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {

    data(){
        return{
            brandList:[],
            brandInfo:{},
            // 分类列表
            classList:[],
            navLeft:0,
			navObj:{},
			scrollDistance:0
        }
        
    },
    methods:{
        async getBrand(){
            let res = await this.$api.getBrandTalk()
            
            this.brandList = res.db
        },
        async getClassGoodsList(brand){
            if(brand&&brand.id){

                this.brandInfo = brand
                let res = await this.$api.getBrandTalk(brand.id)
                res.db.forEach(async clasItem => {
                    let claRes = await this.$api.getBrandTalk(clasItem.id)
                    // . =   
                    this.$set(clasItem,"goodsList",claRes.db)
                })
                this.classList = res.db
                console.log("classList",this.classList);
                
            }
        },

        toInfo(goods){
            console.log("goods",goods);
            // goods.data
            if(goods.data){
                this.$store.commit('UpDateState',{
                    searchText:goods.data
                })
                this.$router.push("/find/index")
            }
        },
        handleScroll() {

			// 获取当前的滚动距离
			this.scrollDistance =  document.documentElement.scrollTop;
			if(this.scrollDistance>170){
				this.navObj.left = this.navLeft + "px"
			}else{
				try {
					const fixedElement = document.getElementById('talk-content');
					const rect = fixedElement.getBoundingClientRect();
					const leftDistanceToDocument = rect.left + window.pageXOffset;
					this.navLeft = leftDistanceToDocument - 140
					this.navObj = { }
				} catch (error) {
                    
				}
			}
		},
		handleResize() {
			try {
				const fixedElement = document.getElementById('talk-content');
				const rect = fixedElement.getBoundingClientRect();
				const leftDistanceToDocument = rect.left + window.pageXOffset;
				this.navLeft = leftDistanceToDocument - 140
			} catch (error) {
				
			}
		},
    },
    async created(){
        await this.getBrand()
        await this.getClassGoodsList(this.brandList[0])
    },
    mounted() {
		// 添加滚动事件监听器
		window.addEventListener('scroll', this.handleScroll);
		window.addEventListener('resize', this.handleResize);
		// 如果你想要得到元素左边相对于整个文档（而不仅仅是当前视口）的距离，
		// 你需要加上当前的滚动偏移量
	},
	onBeforeUnmount(){
		window.removeEventListener('scroll', handleScroll);
		window.removeEventListener('resize', this.handleResize);
	},
    props:{
        
    }
}
</script>
<style lang="scss">
.brand-Talk{
    background: #F9F9F9;
    padding-top: 20px;
    #talk-content{
        position: relative;
        
        .nav-list{
            width: 110px;
            align-self: flex-start;
            left: -140px;
            position: absolute;
            transition: top 0.3s ease;
            top: 20px;
            .nav-item{
                background-color: #fff;
                width: 110px;
                text-align: center;
                border-bottom: 1px solid  #F2F2F2;
                cursor: pointer;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                margin-bottom: 10px;
                img{
                    width: 100px;
                }
                
            }
            .active{
                border: 1px solid #00A652;
            }
        }
        .fiexd{
        
            position: fixed;
            top: 20px;
        }
        .brand-modu{
            margin-top: 20px;
            background: #fff;
        }
        .banner{
            img{
                width: 100%;
            }
        }
        .class-goods-list{
            .brand-title{
                display: flex;
                justify-content: center;
                padding: 20px 0;
                img{
                    height: 58px;
                }
            }
            .goods-list{
                padding: 0 20px;
                padding-bottom: 10px;
                display: flex;
                flex-wrap: wrap;
                .goods-item{
                    margin: 0 11px;
                    cursor: pointer;
                    img{
                        width: 210px;
                    }
                    .goods-text{
                        text-align: center;
                        padding: 15px 0;
                    }
                }
            }
        }
    }
}
</style>