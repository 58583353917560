<template>
<div class="part-tem-info" v-loading="showLoding">
    <div class="base-info-box" id="base-box">
        <div class="part-info-title">
            <div>基础信息</div>
        </div>
        <div class="modu-title">
            基础信息
        </div>
        <div class="base-info">
            <div class="base-info-top">
                <div class="base-row">
                    <div class="label">
                        生产编码:

                    </div>
                    {{partInfo.sccode}}
                </div>
                <div class="base-row">
                    <div class="label">
                        原厂OE号:
                    </div>
                    {{partInfo.pid}}
                </div>

                <div class="base-row">
                    <div class="label">
                        
                    零件名称:
                    </div>
                    {{partInfo.label}}
                </div>
                <div class="base-row remark">
                    <div class="label">
                        备注:
                    </div>
                    {{partInfo.remark}}
                    <!-- <text v-html=""></text> -->
                </div>
                <div class="base-row">
                    <div class="label">
                        更新时间:
                    </div>
                    {{partInfo.updatetime}}
                </div>

            </div>
            <div class="base-info-bottom">
                <el-popover
                    placement="right"
                    width="400"
                    trigger="hover">
                    <img :src="partInfo.img||'https://jc.hnwmall.com/data/upload/shop/common/default_goods_image_.gif'" alt="" style="width:400px">
                    <img :src="partInfo.img||'https://jc.hnwmall.com/data/upload/shop/common/default_goods_image_.gif'" alt="" slot="reference">
                </el-popover>
            </div>
        </div>
    </div>
    <div class="more-msg-box">
        <div class="part-info-title" >
          
            <div :class="{'active-nav':moduIndex==2}" @click="moduCheck(2,'store')"  >供应商</div>
            <div :class="{'active-nav':moduIndex==4}" @click="moduCheck(4,'link')">替换件</div>
            <div :class="{'active-nav':moduIndex==3}" @click="moduCheck(3,'pirce')">渠道价格</div>
            <div :class="{'active-nav':moduIndex==5}" @click="moduCheck(5,'veh')">适用车型</div>
        </div>
        <div class="msg-list" :style="{'max-height':maxH+'px'}" >
            
            <div class="store-msg" >
                <div class="modu-title"  ref="tit">
                    供应商
                    <div class="tit-anch" id="store"> 
                    </div>
                </div>
                <div class="mode-body store-list">
                    <div class="list-head">
                        <div>供应商</div>
                        <div>名称</div>
                        <div>OE号</div>
                        <div>生产编码</div>
                        <div>品牌</div>
                        <div>价格</div>
                        <div>库存</div>
                        <div class="handle">操作</div>
                    </div>
                    <div class="list-body">
                        <div class="list-row" v-for="(goods,index) in storeList" :key="index">
                            <!-- <div>{{goods.store_name}}</div> -->
                            <!-- 简称 -->
                            <div>{{goods.store_name||"优百佳店铺"}}</div>
                            <div>{{goods.goods_name}}</div>
                            <div>{{goods.oecode}}</div>
                            <div>{{goods.sccode}}</div>
                            <div>{{goods.oenaturebrand}}</div>
                            <div>{{goods.goods_price}}</div>
                            <div>{{goods.goods_storage}}</div>
                            <div class="handle">
                                <span class="btn" @click="joinCart(goods)">
                                    加入购物车
                                
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
            <div class="link-msg" >
                <div class="modu-title"  ref="tit">
                    替换件
                    <div class="tit-anch" id="link"> 
                    </div>
                
                </div>
                
                <div class="mode-body link-list">
                    <div class="list-head">
                        <div>品牌</div>
                        <div>零件号</div>
                        <div>生产编码</div>

                        <div class="label">零件名称</div>
                        <div>件数</div>
                        <div class="label">备注</div>
                        <div>销售价</div>
                        <div class="handle"></div>
                    </div>
                
                    <div class="list-body">
                        <div class="list-row" v-for="(replace,index) in replaceList" :key="index">
                            <div>{{replace.pid[0].brandcn}}</div>
                            <div>{{replace.pid[0].pid}}</div>
                            <div>{{partInfo.sccode}}</div>
                            <div class="label">{{replace.pid[0].lable}}</div>
                            <div>{{replace.pid[0].num}}</div>
                            <div class="label">{{replace.pid[0].remark}}</div>
                            <div>{{replace.pid[0].prices}}</div>
                            <div class="handle" >
                                <i class="el-icon-shopping-cart-2"  @click="toBuy(replace)" v-if="replace.gysNum&&$store.getters.notLvD"></i>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="price-msg" >
                <div class="modu-title" ref="tit">
                    渠道价格
                    <div class="tit-anch" id="pirce"> 
                    </div>
                </div>
                <div class="mode-body price-list">
                    <div class="list-head">
                        <div>零件类型</div>
                        <div>厂商</div>
                        <div>说明</div>
                        <div>进价(未含税)</div>
                        <div>进价(含税)</div>
                        <div>销售价(未含税)</div>
                        <div>销售价(含税)</div>
                        <div>服务商</div>
                    </div>
                    <div class="list-body">
                        <div class="list-row" v-for="(priceItem,index) in priceList" :key="index">
                            <div>{{priceItem.parttype}}</div>
                            <div>{{priceItem.mill}}</div>
                            <div>{{priceItem.remark}}</div>
                            <div>{{priceItem.eot_price}}</div>
                            <div>{{priceItem.cost_price}}</div>
                            <div>{{priceItem.sale_price1}}</div>
                            <div>{{priceItem.sale_price}}</div>
                            <div>{{priceItem.supplier}}</div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="veh-msg">
                <div class="modu-title"  ref="tit">
                    适用车型
                    <div class="tit-anch"  id="veh"> 
                    </div>
                </div>
                <div class="brand">
                    <div class="label">
                        所属品牌
                    </div>
                    <div class="brand-list">
                        <div @click="getPartVeh('')" :class="{'fil-brand':filBrand==''}">全部</div>
                        <div v-for="(brand,index) in brandList" :key="index" @click="getPartVeh(brand[0])" :class="{'fil-brand':filBrand==brand[0]}">
                            {{brand[1]}}
                        </div>
                    </div>
                </div>
                <div class="mode-body veh-list">
                    <div class="list-head">
                        <div>品牌</div>
                        <div>车型</div>
                        <div>年份</div>
                        <div>市场</div>
                        <!-- 如果时零件号查询 -->
                    
                        <div class="handle" v-if="queryType=='part'">操作</div>            
                    </div>

                    <div class="list-body">
                        <div class="list-row" v-for="(veh,index) in vehList" :key="index">
                            <div>{{veh.brandname}}</div>
                            <div>{{veh.cars_model}}</div>
                            <div>{{veh.year}}</div>
                            <div>{{veh.market}}</div>
                        
                            <div class="handle" v-if="queryType=='part'">
                                
                                <span class="btn" @click="readVehInfo(veh)">查看</span>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

        </div>
    </div>

    <div class="store-vis">
        <el-dialog
            :visible.sync="storeVis"
            width="800px"
            :show-close="false"
        >
        
            <div class="tit">
                供应商列表
                <i class="el-icon-close" @click="storeVis=false"></i>
            </div>
            <div class="list-body">
                <div class="list-head">
                    <div>供应商名称</div>
                    <div>名称</div>
                    <div>OE号</div>
                    <div>品牌</div>
                    <div>价格</div>
                    <div>库存</div>
                    <div>操作</div>
                </div>
                <div class="list-row" v-for="(goods,index) in buyStoreList" :key="index">
                    <div>{{goods.store_name}}</div>
                    <div>{{goods.goods_name}}</div>
                    <div>{{goods.oecode}}</div>
                    <div>{{goods.oenaturebrand}}</div>
                    <div>￥{{goods.goods_price}}</div>
                    <div>{{goods.goods_storage}}</div>
                    <div>
                        <span class="btn" @click="joinCart(goods)">
                            <!-- 加入购物车  -->
                            去购买
                        </span>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>

</div>
</template>

<script>
export default {
    data(){
        return{
            moduIndex:2,
            queryType:"",
            showLoding:true,
            partInfo:{},
            storeList:[],
            priceList:[],
            replaceList:[],
            brandList:[],
            filBrand:'',
            vehList:[],
            sortWay:"从新到旧排序",
            sortWayList:[
                 {
                    label:"从新到旧排序",
                    value:"1"
                },
                {
                    label:"从旧到新排序",
                    value:"2"
                }
               
            ],
            buyStoreList:[],
            storeVis:false,
            maxH:""
        }
    },
    methods:{
        moduCheck(index,pNode){
            this.moduIndex = index
            document.getElementById(pNode).scrollIntoView()
        },
        toVinFill(){

        },

        async joinCart(carItem){
            // carItem.preRou = "找件"
            // localStorage.setItem("hnw-web-item-data",JSON.stringify(carItem))
            // let link = location.href.split("#")[0]
            // window.open(link + '#/find/info')
            this.storeVis = false

            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_storage:carItem.goods_storage,
                goods_num:1,
                store_id:carItem.store_id,
                store_name:carItem.store_name,
                cart_type:'1'
            }
            let res = await this.$api.joinCart([carMessage])
            if(res){
                this.$suMsg("添加购物车成功")
            }else{
                this.$erMsg("添加失败")
            }
           
        },
        async bannerJump(){

            let nowlist =  await this.$api.getSetList({mark:1})
            let acti = nowlist.find(col=>col.setlist_id==140)
            if(acti){
                acti.preRou = "首页"
                let rou = ""
                localStorage.setItem("hnw-web-item-data",JSON.stringify(acti))
                let link = location.href.split("#")[0]
                window.open(link + `#/purchase/goodsinfo`)
                // this.$router.push(rou)
            }else{
                this.$warMsg("暂无信息")
            }
        },
        readVehInfo(veh){
            // let link = location.href.split("#")[0]
           
            // window.open(link + `#/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`)


            // this.$emit("changeData",{
            //     brandCode:veh.uri_param.brandCode,

            //     mcid:veh.uri_param.mcid  
            // })
            
            veh.uri_param.mcid = veh.uri_param.mcid.replace(/\//g,"xiegang")
            this.$router.push(`/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`)
            this.$store.commit("navPush",{
                label:`${veh.brandname}${veh.cars_model}${veh.year}${veh.market}`,
                route:`/catalog/vehinfo/${veh.uri_param.p}/${veh.uri_param.brandCode}/${veh.uri_param.mcid}`
            })
        },
        
        // 获取商品基础信息
        async getGoodsBaseInfo(){
            
            let res = await this.$api007.getGoodsBaseInfo({
                pid:this.$route.params.oecode,
                brandCode:this.brandCode
            })
            this.partInfo = res.data
            console.log("基础信息是",this.partInfo)
            if(this.partInfo.pid){
                this.partInfo.pid = this.partInfo.pid.replace(/<br\/>/g,"")
            }
            if(this.partInfo.label){
                this.partInfo.label = this.partInfo.label.replace(/<br\/>/g,"")
            }
            if(this.partInfo.remark){
                this.partInfo.remark = this.partInfo.remark.replace(/<br\/>/g,"")
            }

            // 获取生产编码
            // if(this.$store.state.sc_rent==1){

            //     }
            let partLlist = await this.$api.getFindList({
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                store_curpage:1,
                store_page:10,
                shop:this.$store.state.shop,
                shop:this.$store.state.for_branch,
                search:this.partInfo.pid,
                shop_type:this.$store.state.shop_type
            })
            
            if(partLlist.total>0){
                this.partInfo.sccode =partLlist.data[0].goods_list[0].sccode
            }
        },
        // 获取供应商
        async getStoreList(){
            
            
            let res = await this.$api.getGysNum({
                oeList:[this.$route.params.oecode],
                for_branch:this.$store.state.for_branch,
                shop:this.$store.state.shop
            })
            // let res = await this.$api.getGysNum({
            //     oeList:["5QD121251AN"]
            // })
            if(res[0]){
                this.storeList = res[0].data
            }
        },
        async getPrice(){
            let res = await this.$api007.getPartPrice({
                pid:this.$route.params.oecode,
                brandCode:this.brandCode
            })
            
            this.priceList = res.data
            if(Array.isArray(this.priceList)){
                this.priceList.forEach(item=>{
    
                    item.sale_price1 = this.$hnwTools.div(item.sale_price,1.13).toFixed(2)
                })
            }
        },
        async getPartReplace(){
             let res = await this.$api007.getPartReplace({
                pid:this.$route.params.oecode,
                brandCode:this.brandCode
            })
            
            this.replaceList = res.data
           
            
            let pid_list = []
            // 获取对应商品能否购买
        //    this.$store.state.sc_rent==1&&
            if(Array.isArray(this.replaceList)){
                pid_list = this.replaceList.map(item=>item.pid[0].pid)
                // 相同平台归类
                const classifyDataBySame = (initData)=>{
                    let result = []
                    // 缓存 
                    let cache = {}
                    // 下标二维数组，把有相同属性的元素的下标位置，记录到同一个下标数组 
                    let indexs = [ ]

                    initData.forEach((item,index)=>{
                        let brancn = item.pid[0].brandcn
                        let brandIndex = cache[brancn]
                        if(brandIndex||brandIndex==0){
                            indexs[brandIndex].push(index)
                        }else{
                            indexs.push([index])
                            cache[brancn] = indexs.length - 1
                        }
                    })
                    indexs.forEach(item=>{
                        item.forEach(index=>{
                            result.push(initData[index])
                        })
                    })
                    
                    return result
                }
                classifyDataBySame(this.replaceList)
                this.replaceList = classifyDataBySame(this.replaceList)   
            }
            
            if(pid_list.length>0){
                let gysList = await this.$api.getGysNum({
                    oeList:pid_list,
                    for_branch:this.$store.state.for_branch,
                    shop:this.$store.state.shop
                })
                
                gysList.forEach(item=>{
                    if(item.data.length>0){
                        this.replaceList.forEach(part=>{
                            if(part.pid[0].pid==item.oecode){
                                part.gysNum = item.data.length
                                part.storeList = item.data
                            }
                        }) 
                        // let part = this.replaceList.find(part1=>part1.pid[0].pid==item.oecode)
                    }
                    
                })

            }

        },
        async getPartVeh(filter_brand){
            // brandCode:this.brandCode,
            let res = await this.$api007.getPartVeh({
                part:this.$route.params.oecode,
                filter_brand
            })
            
            if(!filter_brand){
                this.brandList = res.all_brands
            }
            this.filBrand = filter_brand
            this.vehList = res.data
        },
        async toBuy(epcPart){
            // goods.preRou = "找件"
            
            // this.$router.push("/find/info")
            
            if(epcPart.gysNum>1){
                
                this.storeVis = true
                this.buyStoreList = epcPart.storeList
                this.buyStoreList.forEach(goods=>{
                    goods.goods_price = this.$handlePrice(goods)
                })
            }else {

                if(epcPart.storeList[0]){
                    epcPart = epcPart.storeList[0]
                }
                epcPart.goods_price = this.$handlePrice(epcPart)
                
                // 直接加入购物车
                this.joinCart(epcPart)

               
            }
            
        },
        
        sortChange(){
            
            if(this.sortWay==1){
                // , , ,
                // 从新到旧
            }else{

            }
        }
    },
    async created(){
      
        if(this.$route.params.queryType){
            this.queryType = this.$route.params.queryType
        }
        this.brandCode = this.$route.params.brandCode
        // if(this.$store.state.sc_rent==1){
        //     }
        
        // 获取价格
        await this.getPrice()
        // 获取替换件
        await this.getPartReplace()
        // 获取适配车型
        this.showLoding = false
        this.$nextTick(()=>{
            this.getPartVeh('')
            this.getStoreList()
            // 获取基础信息
            this.getGoodsBaseInfo()
        })
        
    },
    watch:{
        
    },
   mounted(){
        let baseBox = document.querySelector("#base-box")
        console.log("baseBox.offsetHeight",baseBox.offsetHeight)
        
        this.maxH = baseBox.offsetHeight - 40
        
    },
    
}
</script>

<style lang="scss" scoped>

.part-tem-info{
    background: #f7f7f7;
    border-top: 2px solid #eee;
    box-sizing: border-box;
    height: 100%;
    overflow: auto;
    position: relative;
    display: flex;
    padding: 10px;
    .part-info-title{
        background: $vin-blue;
        height: 40px;
        line-height: 40px;
        display: flex;
        padding-left: 10px;
        box-sizing: border-box;
        >div{
            font-size: 14px;
            color: #fff;
            margin-right: 10px;
            cursor: pointer;
        }
        .active-nav{
            border-bottom: 1px solid #fff;
        }
    }
    .modu-title{
        // height: 40px;
        height: 40px;
        font-size: 15px;
        display: flex;
        align-items: center;
        position: relative;
        padding-left: 5px;
        color: $vin-blue;
        &::before{
            content: "";
            height: 18px;
            width: 2px;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            background: $vin-blue;
        }
        .tit-anch{
            position: absolute;
            top: -44px;
            height: 1px;

        }
    }
    .base-info-box{

        width: 300px;
        background: #FFFFFF;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        .base-info{
            padding-left: 15px;
            .base-info-top{
                
                .base-row{
                    display: flex;
                    color: #666;
                    padding: 5px 0;
                    font-size: 16px;
                    .label{
                        margin-right: 5px;
                        white-space: normal;
                        flex-shrink: 0;
                    }
                }
            }
            .base-info-bottom{
                img{
                    width: 140px;
                    // height: 140px;
                    border: 1px solid #eee;
                    margin-top: 20px;
                }
            }

        }
    }
    .more-msg-box{
        flex: 1;
        margin-left: 10px;
        // 标题样式
        padding: 0 .606061rem /* 20/33 */;
        box-sizing: border-box;
        .msg-list{
            overflow: auto;
            >div{
                background: #fff;
                margin-top: 10px;
                overflow: hidden;
            }
            >div:first-child{
                margin: 0;
            }
            .mode-body{

                .list-head{
                    display: flex;
                    height: 30px;
                    align-items: center;
                    background: #FAFAFA;
                    text-align: center;
                    >div{
                        flex: 1;
                    }
                    >div:first-child{
                        text-align: left;
                        padding-left: 20px;
                    }
                }
                .list-body{
                    .list-row{
                        display: flex;
                        align-items: center;
                        min-height: 34px;
                        // border-bottom: 1px solid #ccc;
                        border-bottom: 1px solid #eee;
                        text-align: center;
                        font-size: 18px;
                        >div{
                            flex: 1;
                            line-height: 19px;
                        }
                        >div:first-child{
                            text-align: left;
                            padding-left: 20px;
                        }
                    }
                    .list-row:last-child{
                        border:none;
                    }
                }
            }
            .link-list{
                .label{
                    padding: 0 5px;
                    text-align: left;
                }
            }
            .btn{
                cursor: pointer;
                color: $vin-blue;
            }
            .part-msg{
                display: flex;
                .base{
                    flex: 1;
                    border-right: 10px solid #f7f7f7;
                    min-height: 138px;
            
                    .handle{
                        display: flex;
                        justify-content: flex-end;
                        padding-right: 1.636364rem /* 54/33 */;
                        .btn{
                            width: 120px;
                            height: 30px;
                            background: #3E84D6;
                            color: #fff;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            i{
                                margin-right: 5px;
                                font-size: 18px;
                            }
                        }
                    }
                }
                .big-img{
                    // height: 138px;
                    display: flex;
                    align-items: center;
                    img{
                        // height: 100%;
                        height: 138px;
                        cursor: pointer;
                    }
                }
            }
            .store-msg{
                .store-list{
                    .list-head{
                        .handle{

                        }
                    }
                    .list-body{
                        .handle{
                        .btn{

                        } 
                        }
                    }
                }
                .more-store{
                    display: flex;
                    justify-content: center;
                    padding: 10px 0;
                    .more-btn{
                        width: 124px;
                        height: 30px;
                        border: 1px solid #3E84D6;
                        color: #3E84D6;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 15px;
                        cursor: pointer;
                        span{

                        }
                    }
                }
            }
            .price-msg{
                .price-list{

                }
            }
            .link-msg{
                .handle{
                    max-width: 50px;
                    min-width: 50px;
                    color: #3E84D6;
                    cursor: pointer;
                    text-align: left;
                }
                .btn{
                    color: #fff;
                    margin-left: 10px;
                    background: #3E84D6;
                    width: 84px;
                    height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                }
                .sort-handle{
                    display: flex;
                    align-items: center;
                    background: #f7f7f7;
                    margin-top: 0;
                    >div{

                    }
                    .sele{
                        margin-left: 20px;
                    }
                }
            }
            .veh-msg{
                .brand{
                    display: flex;
                    align-items: center;
                    padding-bottom: 5px;
                    .label{
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        padding-left: 10px;
                        margin-right: 10px;
                    }
                    .brand-list{
                        display: flex;
                        >div{
                            cursor: pointer;
                            margin: 0 2px;
                            min-width: 46px;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    
                        .fil-brand{
                            background: #254385;
                            color: #fff;
                        }
                    }
                    .handle{
                        span{

                        }
                    }
                }
            }
        }
    }
    .store-vis{
        ::v-deep .el-dialog__header{
            padding: 0;    
        }
        ::v-deep  .el-dialog__body{
            padding: 0;
        }
        .el-dialog{
            .tit{
                padding: 10px ;
                background: #3E84D6;
                color: #fff;
                display: flex;

                justify-content: space-between;
                .el-icon-close{
                    cursor: pointer;
                    color: #fff;
                }
            }
            .list-body{
                padding: 10px;
                .list-head{
                    display: flex;
                    background: #DFDFDF;
                    height: 28px;
                    line-height: 28px;
                    >div{
                        text-align: center;
                        flex: 1;
                    }
                    .name{
                        min-width: 120px;
                    }
                    .oe{
                        min-width: 140px;
                    }
                    >div:first-child{
                        text-align: left;
                        padding-left: 10px;
                    }
                    
                }
                .list-row{
                    display: flex;
                    align-items: center;
                    border-bottom: 1px solid#ccc;
                    >div{
                        text-align: center;
                        flex: 1;
                        padding: 8px 0 ;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        .btn{
                            cursor: pointer;
                            color: #3E84D6;
                        }
                    }
                    >div:first-child{
                        text-align: left;
                        padding-left: 10px;
                        justify-content: flex-start;
                    }
                    .tag-icon{
                        display: flex;
                        align-items: center;
                        transform: scale(.9);
                        justify-content: center;
                        width: 17px;
                        height: 17px;
                        border: 1px solid #ff781c;
                        border-radius: 50%;
                        color: #ff781c;
                        margin-right: 5px;
                        font-size: 12px;
                    }
                    .buy-nums{
                        .el-input-number{
                            width: 100%;
                        }
                    }
                     .name{
                        min-width: 120px;
                    }
                    .oe{
                        min-width: 140px;
                    }
                }
            }
           
        }
    }
}
</style>