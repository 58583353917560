<template>
<div class="brand-centre">
    
    <div class="hnw-area-center">
        <!-- <div class="brand-header" >
            <div class="brand-img"> 
                <img :src="require(`../../assets/index/brand/${brandTemp}.png`)" alt="">
            </div>
            
        </div> -->

            <!-- 品牌弹框 -->
    <!-- <div class="brand-logo-list"  v-if="showBrand">
        <div class="san"></div>
        <div class="img-box"  v-for="(brand,i) in putList" :key="i"  @click="getList(1,brand)">
            <img :src="require(`../../assets/brand/${brand}.png`)" alt="">
            <div class="img-txt"> {{brand}} </div>
        </div>
    </div> -->

    <!-- 点击收起品牌 -->
        <!-- <div class="put">
             <i class="el-icon-arrow-up jiantou" v-if="!showAll" @click="showPut" title="msgPut"></i>
             <i class="el-icon-arrow-down jiantou" v-if="showAll" @click="showPut" title="msgPut"></i>
        </div> -->

    <!-- 品牌配件信息 -->
    <div class="brand-msg" >
        <div class="brand-name">
            <div class="brand-select"  @click.stop="displayBrand">
                选择品牌 
                <i class="el-icon-arrow-down" v-if="!showBrand"></i>
                <i class="el-icon-arrow-up" v-if="showBrand"></i>
                </div>
            <div class="brand-checked" @click.stop="displayBrand">{{oenaturebrand}}</div>
        </div>
         <div class="brand-name">
            <div class="brand-select" @click.stop="displayProduct">
                产品分类 
                <i class="el-icon-arrow-down" v-if="!showProduct"></i>
                <i class="el-icon-arrow-up" v-if="showProduct"></i>
                </div>
            <div class="brand-checked" @click.stop="displayProduct">{{oesort || "全部"}}</div>
        </div>
        <div class="line"></div>
        <div @click="getList(3,'')" class="brand-names">
            <div class="brand-parts">配件数量</div>
            <div class="parts-text">{{goodsMsgTotal.total}}</div>
        </div>
        <div  @click="getList(3,'ok')" class="brand-names">
            <div class="brand-parts">有货</div>
            <div class="parts-text">{{goodsMsgTotal.in_total}}</div>
            
        </div>
        <div  @click="getList(3,'no')" class="brand-names">
            <div class="brand-parts">无货</div>
            <div class="parts-text">{{goodsMsgTotal.not_total}}</div>
            
        </div>
        <div class="close-btn" @click="clearBrand" v-if="oenaturebrand || oesort">
           
            清除
        </div>
    </div>

    <!-- <div class="brand-cls" v-if="clsList.length>0 || showProduct"> -->
    <div class="brand-cls" v-if=" showProduct">
        <div class="jiao"></div>
        <div class="brand-search"> 

            <el-input @click.stop.native="stopstop" v-model="result" clearable placeholder="请输入内容"></el-input>
            <div class="brand-btn" @click.stop="searchResult">搜索</div>
        </div>
        <div class="brand-info">    
            <div class="cls-item" v-for="(cls,index) in clsList" :key="index" @click="getList(2,cls)" :class="{'active':oesort==cls}">
                {{index+1}} {{cls}}
            </div>
            
        </div>
    </div>
        

        <!-- <div class="brand-msg" >
            <div >
                选择品牌：{{brand}}
            </div>
            <div @click="getList(3,'')">
                配件数量：{{goodsMsgTotal.count}}
            </div>
            <div  @click="getList(3,'ok')">
                有货：{{goodsMsgTotal.count_ok}}
            </div>
            <div  @click="getList(3,'no')">
                无货：{{goodsMsgTotal.count_no}}
            </div>
        </div>

        <div class="brand-cls" v-if="clsList.length>0">
            <div class="cls-item" v-for="(cls,index) in clsList" :key="index" @click="getList(2,cls)" :class="{'active':oesort==cls}">
                {{cls}}
            </div>
        </div> -->


    <div class="goods-list">
        <div class="list-head">
            <div class="head-item" v-for="(attr,index) in attrList" :key="index" :class="attr.key">
               <div>
                    {{attr.title}}
                    <!-- <span v-if="attr.key=='goods_price'">
                        (整箱单价)
                    </span> -->
                </div>
               <div class="sort">
                   <!-- <i class="el-icon-caret-top" :class="{'active':activeSort==attr.key+1}" @click="sortChange(attr.key,1)"></i> -->
                   <!-- <i class="el-icon-caret-bottom" :class="{'active':activeSort==attr.key+2}" @click="sortChange(attr.key,2)"></i> -->
                    <img src="../../assets/commom/up.png" v-if="activeSort==attr.key+1" alt="" @click="sortChange(attr.key,2,index)" >
                    <img src="../../assets/commom/down.png" v-else alt="" @click="sortChange(attr.key,1,index)" >
               </div>
               <!-- <el-checkbox class="filterZero" v-if="attr.key=='goods_storage'" v-model="showZero" @change="changeZero">过滤0库存</el-checkbox> -->
            </div>
            <div class="buy-nums head-item">
                规格
            </div>
            <div class="buy-nums head-item">
                订货数量
            </div>
            <div class="handle head-item">
                操作                
            </div>
        </div>
        
        <div class="list-body">
            <div class="list-goods" v-for="(goods,index) in goodsList" :key="index">
                <div class="row">
                    <template v-for="(attr,index1) in attrList">
                        <div class="list-rol"  :key="index1" :class="attr.key" v-if="attr.title=='OE号'" @click="getGoodsInfo(goods)" :title="goods[attr.key]">
                           
                            <div class="brand">
                                {{goods.oenaturebrand}}
                            </div>
                            {{goods[attr.key]}}
                            <!-- <div class="tag-icon" v-if="goods.exchange.length>0">
                                互
                            </div> -->
                            <div class="vehicle" :title="goods.vehicletype">
                                车型:{{goods.vehicletype}}
                            </div>
                        </div>

                        <!-- <div class="list-rol"  :key="index1" :class="attr.key" v-else-if="attr.key=='goods_price'"> -->
                        <div class="list-rol display-center"   :class="attr.key" v-else-if="attr.key=='goods_price'">

                            <span v-if="!$store.state.token" class="blue">
                                会员可见
                            </span>

                            <span v-else>
                                ￥{{goods.goods_price}}
                               
                                <span v-if="goods.brand_ratio_price">
                                    ({{goods.brand_ratio_price}})
                                </span>
                            </span>
                        </div>
                        <div class="list-rol display-center" v-else-if="attr.key=='oenaturebrand'" >
                            <span>
                                {{goods[attr.key]}}
                                <!-- <span class="id-bs">
                                    {{ $store.state.member_id }}
                                    <div class="bordiv"></div>
                                    <div class="bordiv1"></div>
                                </span> -->
                            </span>
                        </div>
                        <!-- <div class="list-rol"  :key="index1" :class="attr.key" v-else > -->
                        <div class="list-rol display-center"   :class="attr.key" v-else >
                            
                            <span v-if="attr.key=='goods_storage'" :class="{'disabled-color':goods.goods_storage==0}">
                                <template v-if="!$store.state.member_id.includes('1780-')">
                                    <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                                    <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                </template>
                                <div style="font-size: 12px;color: red;" v-if="$store.state.member_id.indexOf('1780-')!=-1"> 
                                    <div style="margin: 5px 0;">上海:
                                    <span v-if="$store.state.look_inv==1">{{goods.sh_storage}}</span>
                                    <span v-else>{{goods.sh_storage>0?"有货":"无货"}}</span> 
                                    </div>
                                    <div>丹阳:
                                    <span v-if="$store.state.look_inv==1">{{goods.dy_storage}}</span>
                                    <span v-else>{{goods.dy_storage>0?"有货":"无货"}}</span> 
                                    </div>
                                    <div @click="openAll(goods)" style="cursor: pointer;margin: 10px 0;"><span style="padding: 2px;color: #59a8f8;border: 1px solid #59a8f8;">货位详情</span></div>
                                </div>
                                <div v-else class="goods-storage">{{goods.cangTip}}</div>
                            </span>
                            <span v-else :title="goods[attr.key]">
                                {{goods[attr.key]}}
                            </span>
                        </div>
                    </template>
                    <div class="buy-nums list-rol display-center">
                        {{goods.spec}}
                    </div>
                    <div class="buy-nums list-rol display-center">
                       
                        <el-input-number   v-if="$store.state.zero_buy==1" v-model="goods.goods_num" :min="1" size="mini"></el-input-number>
                        <el-input-number   v-else  @change="impueNumChange(goods)" v-model="goods.goods_num" :min="1" size="mini"></el-input-number>
                        <span class="goods-Fcl-tit" v-if="goods.brand_ratio_price">
                            整箱更优惠
                        </span>
                    </div>
                    <div class="handle list-rol display-center">
                        <div class="btn" :class="{'ycg-btn':goods.goods_storage==0}" @click="joinCart(goods)">
                            {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                            <!-- 加入购物车 -->
                        </div>
                        <div class="cart-num" v-if="goods.cartNum">已添加 {{goods.cartNum||0}} {{goods.oecompany}}</div>
                        <el-popover
                            placement="left"
                            width="260"
                            popper-class="pop-replace-list"
                            trigger="click"
                            v-model="goods.replacePopVis"
                        >
                            <div class="pop-close"  @click="goods.replacePopVis=false">
                                <i class="el-icon-close"></i>
                            </div>
                            <el-table :border="false" class="replace-table"  height="300" :data="buyStoreList">
                            <el-table-column width="60" type="index" label="序号"></el-table-column>
                            <el-table-column width="120" property="oecode" label="互换件号"></el-table-column>
                            <el-table-column width="80" property="address" label="操作">
                                <template slot-scope="scope">
                                <span class="show-btn"  v-if="scope.row.goods_id" @click="toSearch(scope.row)">
                                    在售
                                </span>
                                
                                </template>
                            </el-table-column>
                            </el-table>
                            <div class="replace-btn"  @click="showChangeParts(goods)" type="text" slot="reference" text v-if="!goods.isExchange&&goods.exchange.length>0&&$store.getters.notLimitBrands">查看互换件</div>
                            
                        </el-popover>
                         <!-- <div class="replace-btn" @click="showChangeParts(goods)" v-if="goods.exchange.length>0">查看互换件</div> -->
                    </div>
                </div>
                
                <div class=" row-two" v-if="goods.goodsorderInfo">
                    
                    <div class="buyHis"  @click="readHisList(goods.goods_id)">
                        <template  >
                            上次购买信息：
                            <span>
                                
                                {{$hnwTools.formatDateTime(goods.orderInfo.add_time,'ymd')}}
                            </span>
                            <span>
                                {{goods.orderInfo.member_name}}
                            </span>
                            <span>
                                {{goods.orderInfo.goods_num}}
                            </span>
                           
                            <span>
                                {{$hnwTools.getOrderStateText(goods.orderInfo.order_state)}}
                            </span>
                            <span class="readMore">
                                更多
                            </span>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="store-vis">
        <el-dialog
            :visible.sync="storeVis"
            width="400px"
            :show-close="false"
        >
        
            <div class="tit">
                互换件列表
                <i class="el-icon-close" @click="storeVis=false"></i>
            </div>
            <div class="list-body">
                <div class="list-head">
                    
                    <div class="hhj-index">序号</div>
                    <div class="oe">互换件号</div>
                    
                    <div class="handle">操作</div>
                </div>
                <div class="list-row" v-for="(goods,index) in buyStoreList" :key="index">
                    <!-- <div>{{goods.store_name}}</div> -->
                    
                    <div class="hhj-index">
                        {{index+1}}
                    </div>
                    <div class="oe">
                        {{goods.oecode}}
                    </div>
                    
                    <template v-if="goods.goods_id">
                    
                        <div class="handle">

                            <span class="btn" @click="toSearch(goods.oecode)">
                                在售列表
                                
                            </span>
                        </div>
                    </template>
                    <template v-else>
                        
                        <div class="handle">

                            <span class="btn" >
                                
                                <span>--</span>
                            </span>
                        </div>
                    </template>
                </div>
            </div>
        </el-dialog>
    </div>

        <div class="paging">
            <!-- <span class="database">共 {{total}} 件配件</span> -->
            <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="currentPage"
                :page-sizes="[10, 20, 30, 40]"
                :page-size.sync="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="Math.ceil(total)">
            </el-pagination>
        </div>
        <el-dialog title="历史购买记录" :visible.sync="hisVis" center  width="800px">
            <div class="his-list">
                <div class="list-head">
                    <div>购买时间</div>
                    <div>购买人</div>
                    <div class="col-100">价格</div>
                    <div class="col-100">购买个数</div>
                    <div class="col-100">订单状态</div>
                </div>
                <div class="list-body">
                    <div class="row" v-for="(his,index) in gooodsHisList" :key="index">
                        <div>{{$hnwTools.formatDateTime(his.add_time,'ymd')}}</div>
                        <div>{{his.member_name}}</div>
                        <div  class="col-100">{{his.goods_price}}</div>
                        <div  class="col-100">{{his.goods_num}}</div>
                        
                        <div class="col-100">{{$hnwTools.getOrderStateText(his.order_state)}}</div>
                    </div>
                </div>
            </div>
        </el-dialog>
        <div class="hezi" >
            <el-dialog
                :visible.sync="dialogVisible"
                width="760px"
                top="8vh"
                >
                <DiaGoodsInfo :id="goods_id"></DiaGoodsInfo>
            </el-dialog>
        </div>

        <!-- 货位详情 -->
    <el-dialog
        title="货位详情"
        :visible.sync="openAllotion"
        width="50%"
        :before-close="handleClose">
        
        <div class="alltion">
            <div>OE号</div>
            <div>仓库</div>
            <div>货位</div>
            <div>库存</div>
        </div>
        <div class="alltion">
            <div style="width: 100%;">丹阳</div>
        </div>
        <div class="alltion" v-for="(item) in goodsAllotion.dy.list" :key="item.shelfId">
            <div class="item">{{item.code}}</div>
            <div class="item">{{item.warehouseName}}</div>
            <div class="item">{{item.shelfName}}</div>
            <div class="item">{{item.shelfStockNum}}</div>
        </div>
        <div class="alltion">
            <div style="width: 100%;">上海</div>
        </div>
        <div class="alltion" v-for="(item) in goodsAllotion.sh.list" :key="item.shelfId">
            <div class="item">{{item.code}}</div>
            <div class="item">{{item.warehouseName}}</div>
            <div class="item">{{item.shelfName}}</div>
            <div class="item">{{item.shelfStockNum}}</div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button size="mini" @click="openAllotion = false">取 消</el-button>
            <el-button size="mini" type="primary" @click="openAllotion = false">确 定</el-button>
        </span>
    </el-dialog>
    </div>
</div>
</template>

<script>
export default {
    data(){
        return{
            brandList:["博世","采埃孚","法雷奥","索恩格","德尔福","伦福德",
            "萨克斯","天合","卢卡斯","康迪泰克","车享配","汉高",
            "汉格斯特","马瑞利","博格华纳","海拉","华诺威","斯泰必鲁斯","SABS",
            "爱尔铃","NTK","天博","上海法雷奥","索菲玛","ATI",
            "华域三电","安庆帝伯格茨","三爱海陵","富奥","昌辉","信力",
            "上海来利","崇芳","永信","捷豹","厦门理研","凯吉","征和","欧博萨","上海兴盛",
            "上汽大众","皮尔伯格","科尔本","石川岛","FTE","捷成唯科","上海三达","森萨塔",
            ],
            // 品牌
            brand:"",
            brandTemp:"",
            goods_id:"",
            dialogVisible:false,
            attrList: [ ],
            activeSort:"",
            currentPage:1,
            pageSize:15,
            sort_type:"",
            sort_way:1,
            total:"",
            hisVis:false,
            gooodsHisList:[],
            oesort:[],
            goodsList:[],
            goodsMsgTotal:{},
            clsList:[],
            storeInfo:{},
            buyStoreList:[],
            storeVis:false,
            showZero:false,
            showBrand:false,
            putList:[],
            showProduct:false,
            oenaturebrand:'',
            result:'',
            openAllotion:false,//货位详情
            goodsAllotion:{
                dy:{
                    title:"丹阳",
                    list:[]
                },
                sh:{
                    title:"上海",
                    list:[]
                }
            },
        }
    },
    // this.$route.params.brand
    methods:{
        async openAll(data){  
            // 显示货位
            if(this.$store.state.member_id.indexOf('1780-')!=-1){ 
                let allotion = await this.$http(`${this.$axios.defaults.baseURL.replace("web",'public')}index.php?act=search&op=stockInfo`,{skuId:data.skuId})
                this.goodsAllotion = this.$hnwTools.getAllotion(allotion) 
            }  
            
            this.openAllotion= true
        },
        handleClose(done) {
            done();
        },
       displayBrand(){
            this.showBrand= !this.showBrand
            this.showProduct=false
        },
        displayProduct(){
            this.showProduct= !this.showProduct
            this.showBrand=false
        },
        // 点版心区域 关闭品牌和产品选择的弹框
        closePop(){
            this.showBrand=false
            this.showProduct=false
        },
        clearBrand(){
            
            this.putList=this.brandList
            // this.showAll = true
            // this.oenaturebrand = ""
            this.oesort=""
            this.result=""
            this.getList()
        },
        changeZero(){
            
            this.getList(3,this.showZero?'ok':'')
        },
        async getList(type,value){
            if(type==1){
                // 去掉数字
                this.oenaturebrand = value.replace(/\d/,'')
                this.oesort = ""
            }else if(type==2){
                this.storage_type = ""
                this.oesort = value
                 this.showProduct=false
            }else if(type==3){
                this.currentPage = 1
                if(value=='ok'){
                    this.storage_type = 'gt'
                }else if(value=='no'){
                    this.storage_type = 'eq'
                }else{
                    this.storage_type = ""
                }
                // this.storage_type = value
            }
            //  
            let sort_type  
            if(this.sort_type=='goods_storage'){
                sort_type = this.$store.state.is_sh=="0"?'goods_stcids':"goods_storage"
            }else{
                sort_type = this.sort_type
            }
            let  res = await this.$api.getBrandGoodsListNew({
                sort_key:sort_type||'goods_name',
                sort_value:this.sort_way==1?'ASC':'DESC',
                page:this.currentPage,
                limit:this.pageSize,
                shop_id:this.$store.state.shop, 
                store_id:this.$store.state.store_id,
                member_id:this.$store.state.member_id,
                stock:this.storage_type,
                oesort:this.oesort,
                search:this.oenaturebrand
            })
    //         let res = await this.$api.getBrandGoodsList({
    //             store_id:this.$store.state.store_id,
    //             member_id:this.$store.state.member_id,
    //             curpage:this.currentPage,
    //             page:this.pageSize,
    //             search:this.$store.state.searchText,
    //             sort_type:this.sort_type,
    //             sort_way:this.sort_way,
    //             oenaturebrand:this.oenaturebrand,
    //             oesort:this.oesort,
    //             storage_type:this.storage_type,
    //             shop_type:this.$store.state.shop_type
    //         })
            
            if(res.class){
                this.clsList = res.class.filter(oe=>oe)
            }else{
                this.clsList = []
            }

            this.goodsList = res.data 
            if(res.stock){
                if(this.storage_type=='gt'){
                    this.total = res.stock.in_total
                }else if(this.storage_type=='eq'){
                    this.total = res.stock.not_total
                }else{
                    this.total = res.stock.total
                }
                this.goodsMsgTotal = res.stock
            }
            
            // this.storeInfo = res.store
            
            this.goodsList.forEach(goods=>{
                this.$set(goods,"choose",false)
                this.$set(goods,"goods_num",1)
                // 对电商价格处理 
                if(!this.$store.state.token||this.$store.state.sc_id==14){
                    goods.goods_price = goods.goodprice2
                }else{
                    goods.goods_price =  goods.goodprice1>0 ?goods.goodprice1 : goods.goods_price
                }

                // goods.goods_price = this.$handlePrice(goods.goods_price)
                goods.goods_price = this.$handlePrice(goods)

                if(this.$store.state.ratioBrandList){
                    let obj = this.$store.state.ratioBrandList.find(brand=>{
                        if(brand.name==goods.oenaturebrand){
                            return brand
                        }
                    })
                    if(obj){
                        if(goods.spec){
                            let box = goods.spec.split("/")
                            // console.log("intact",box);
                            if(box[1]){
                                this.$set(goods,"boxNum",box[0])
                                goods.brand_ratio_price = this.$hnwTools.times(goods.goods_price,obj.ratio)
                            }
                        }
                    }
                }

                if(!goods.cartNum){
                    goods.cartNum = 0
                }else{
                   
                    if(this.$store.state.zero_buy!=1){
                        if(goods.cartNum*1>goods.goods_storage*1){
                            goods.cartNum = goods.goods_storage
                        }
                    }
                }
                if(goods.exchange){
                    goods.exchange = goods.exchange.filter(item=>item!=goods.oecode)
                    this.$set(goods,"replacePopVis",false)
                }else{
                    goods.exchange = []
                }
                this.$set(goods,"goodsorderInfo",'')
                if(!Array.isArray(goods.orderInfo)){
                    goods.goodsorderInfo = '1'
                }
            })
            
            // 选择品牌
            // let req = await this.$api.getBrandMsgTotal({
            //     oenaturebrand:this.oenaturebrand,
            //     shop_type:this.$store.state.shop_type
            // })
            

             // Cang
 
            let indexRes = []
            // this.goodsList = this.goodsList.filter(item => item.house_info!=null)
            this.goodsList.forEach((goods) => {
                this.$storageCangInfo(goods)
            }); 
        },
         // 产品分类的搜索
        searchResult(){
            if(this.result==''){
                this.getList()
            } else{
                this.clsList= this.clsList.filter(item=> {
                     return item.includes(this.result)
                 })
            }
        },
        async readHisList(goods_id){
            let res = await this.$api.getGoodsRecord(goods_id)
            this.gooodsHisList = res
            this.hisVis = true
        },
        toSearch(goods){
     
            this.$store.commit("UpDateState", {
            searchText: goods.oecode
            });
            this.storeVis = false
            goods.replacePopVis = false
            this.$router.push("/find/index")
        },
        async showChangeParts(epcPart) {
            if(epcPart.exchange&&epcPart.exchange.length>0){
                let oem =  epcPart.exchange.join(",")
                this.buyStoreList = []
                let res = await this.$api.getBatchSearch({
                oem
                })
                console.log("epcPart",epcPart)
                console.log("res",res)
            
                if(res&&res.length>0){
                let addStr = ''
                res.forEach(goods=>{
                    if(!addStr.includes(goods.oecode)){
                    this.buyStoreList.push({
                        goods_id:goods.goods_id,
                        oecode:goods.oecode
                    })
                    addStr += goods.oecode+","
                    }
                })
                }
                epcPart.exchange.forEach(oecode=>{
                let has = this.buyStoreList.some(goods=>goods.oecode==oecode)
                if(!has){
                    this.buyStoreList.push({
                    oecode
                    })
                }
                
                })
                // this.storeVis = true
                epcPart.replacePopVis = true
            }
        
        
        },
        sortChange(type,way,index){
            this.sort_type = type
            this.sort_way = way
            this.activeSort = type + way
            this.attrList[index].ckbox=!this.attrList[index].ckbox
            this.getList()
        },
        impueNumChange(goods) {
            if(isNaN(goods.goods_num)){
                this.$nextTick(()=>{
					this.$set(goods,"goods_num",1)
				})
            }

            if(goods.goods_storage>0&&goods.goods_num>goods.goods_storage){
                this.$nextTick(()=>{
                    goods.goods_num = goods.goods_storage
                })
                return this.$store.commit('UpDateState',{invDia:true})
            }
        },
        async joinCart(goods){
            if(this.$store.state.zero_buy!=1){
                if(goods.goods_storage<1){
                    // return this.$store.commit('UpDateState',{invDia:true})
                }else if(goods.goods_num+Number(goods.cartNum)>goods.goods_storage){
                    return this.$store.commit('UpDateState',{invDia:true})
                }
            }
            let cart = []
            let cartGoods = {}
            cartGoods.goods_num = goods.goods_num
            cartGoods.oecode = goods.oecode
            cartGoods.sccode = goods.sccode
            cartGoods.cart_type = 1
            let keys1 = ["goods_id","goods_name","goods_price","goods_storage","store_id","setlist_id"]
            keys1.forEach(key=>{
                cartGoods[key] = goods[key]
                // cartGoods.store_name = this.storeInfo.store_name
            })
             
            cart.push(cartGoods)
            // 跳转到加入成功页
            let res = await this.$api.joinCart(cart)
        
            if(res){
                
                goods.cartNum = Number(goods.cartNum) + Number(goods.goods_num)
            }else{
                
            }
        },
        async replaceJoinCart(carItem){
            this.storeVis = false
            if(this.$store.state.zero_buy!=1){
                if(carItem.goods_storage<1){
                    return this.$store.commit('UpDateState',{invDia:true})
                }else if(carItem.goods_num+carItem.cartNum>carItem.goods_storage){
                    return this.$store.commit('UpDateState',{invDia:true})
                }
            }
            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_storage:carItem.goods_storage,
                goods_num:carItem.goods_num,
                store_id:carItem.store_id,
                store_name:carItem.store_name,
                cart_type:'1'
            }
            
            let res = await this.$api.joinCart([carMessage])
            if(res){
                this.$suMsg("添加购物车成功")
            }else{
                this.$erMsg("添加失败")
            }
        },
        //  分页
        async handleSizeChange(val) {
            await this.getList()
            
            scrollTo(0,0)
        },   
        handleCurrentChange(val) {
            this.getList()
        },
        getGoodsInfo(goods){
            goods.preRou = "首页品牌中心"
            localStorage.setItem("hnw-web-item-data",JSON.stringify(goods))
            this.$router.push("/index/goodsInfo")
        },
    },
    watch:{
        "$store.state.searchText":{
               deep:true,
               handler(n){
                   console.log("执行")
                   this.getList()

               }
       },
       result(n){
          if(n==""){
              this.getList()
          }else{
              this.clsList= this.clsList.filter(item=> {
                     return item.includes(this.result)
                 })
          }
      },
    },
    created(){
        this.brand = this.$route.params.brand
        this.brandTemp = this.$route.params.brand+'12'
        
        let attr = ["OE号","商品名称","生产编码","配件品牌","库存","订货价"]
        this.$store.commit('UpDateState',{
            searchText:""
        })
        this.$nextTick(()=>{
            this.getList(1,this.brand)
        })
        attr.forEach(title=>{
            let atrObj = this.$hnwTools.goodsAttrs.find(item=>item.title==title)
            if(title=='OE号'){
                atrObj.method = "getGoodsInfo"
                // console.log("atrObj",atrObj)
            }
            this.attrList.push(atrObj)
        })
         this.putList= JSON.parse(JSON.stringify(this.brandList))
        
    },
    destroyed(){
        localStorage.removeItem("epc-brand-text")
    },
    props:{
        
    }
}
</script>

<style lang="scss" scoped>
.brand-centre{
    background: #f7f7f7;
    overflow: hidden;
    .brand-header{
        height: 360px;
        display: flex;
        justify-content: space-between;
        margin: 20px 0;
        .brand-img{
            flex: 1;
            img{
                height: 360px;
                width: 100%;
                // width: 816px;
            }
            
        }
        .brand-content{
            background: #fff;
            width: 360px;
            padding: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .cont-head{
                .cont-logo{
                    height: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-bottom: 1px solid #EEEEEE;
                    img{

                    }
                }
                .brand-text{
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #333333;
                    text-align: center;
                    padding: 10px 0;
                }
            }
            .cont-cls-list{
                padding-bottom: 16px;
                box-sizing: border-box;
                border-bottom: 1px solid #EEEEEE;
                .tit{
                    font-size: 16px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
                .list{
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    .item{
                        background: #f7f7f7;
                        cursor: pointer;
                        padding: 5px 10px;
                        margin: 5px 5px 0;
                    }
                    .active{
                        background: #3E84D6;
                        color: #fff;
                    }
                }
            }
            .total{
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1;
                .total-nums{
                    font-size: 16px;
                }
                .total-atten{
                    .nums{
                        font-size: 14px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #FF2C2C;
                        padding-bottom: 5px;
                        text-align: center;
                    }
                    .atten-tit{
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        img{
                            margin-right: 2px;
                        }
                    }
                }
            }
        }
    }
    .brand-logo-list{
        position: absolute;
        background-color: #fff;
        top: 533px;
        left: 365px;
        width: 917px;
        padding: 10px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;
        z-index: 99;
        box-shadow: 0px 0px 6px 0px rgba(41, 49, 56, 0.19);
        border-radius: 4px;
        // overflow-y: auto;
        // position: relative;
        .san{
           position: absolute;
           top: -8px;
           left: 56px;
           width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4px 8px 4px;
            border-color: transparent transparent #ffffff transparent;
        }
        .img-box{
            width: 107px;
            height: 52px;
            display: flex;
            cursor: pointer;
            margin-bottom: 8px;
            justify-content: center;
            align-items: center;
            background: #F7F7F7;
            flex-direction: column;
            justify-content: space-between;
            img{
                // margin-top: 8px;
                max-height: 30px;
                max-width: 90px;
                margin: auto;
            }
            .img-txt{
                width:107px; 
                height:15px;
                border-top: 1px solid #ccc;
                text-align: center;
                line-height: 5px;
                padding-top: 8px;
                color:#666;
            }
        }
       .img-box:hover{
           img{
               transform: scale(1.2);
           }
       }
    }

    .brand-msg{
        height: 82px;
        position: relative;
        background-color: #409EFF;
        // margin: 10px 0;
        box-sizing: border-box;
        // line-height: 40px;
        display: flex;
        color: #fff;
        margin-top: 15px;
        .brand-name{
            width: 143px;
            text-align: center;
            .brand-select{
                padding: 19px 0 10px;
                cursor: pointer;
            }
           
            .brand-checked{
                width: 80px;
                height: 22px;
                background-color: #fff;
                color: #50A3F8;
                text-align: center;
                line-height: 22px;
                margin-left: 28px;
                border-radius: 2px;
            }
           
        }
        .line{
            margin-top: 28px;
            width: 2px;
            height: 27px;
            background: #FFFFFF;
            opacity: 0.2;
            padding: 0;
            margin-right: 20px;
        }
        .brand-names{
            width: 95px;
            text-align: center;
            .brand-parts{
               padding: 20px 0 8px;
               font-size: 14px;
           }
           .parts-text{
               font-size: 18px;
                font-family: DFPShouJinW3-GB;
                font-style: oblique;
           }
        }
        >div{
            padding: 0 10px;
            cursor: pointer;
        }
        >div:first-child{
            cursor: default;
        }
        .close-btn{
            cursor: pointer;
            position: absolute;
            top: 27px;
            right: 34px;
            width: 54px;
            height: 30px;
            line-height: 29px;
            text-align: center;
            background: #FFFFFF;
            border-radius: 6px;
            font-size: 14px;
            font-weight: bold;
            color: #50A3F8;
        }
    }
    // 分类列表
    .brand-cls{
        position: absolute;
        top: 56px;
        left: 280px;
        z-index: 99;
        // height: 214px;
        width: 570px;
        // overflow-y: auto;
        border: 1px solid #ccc;
        padding: 20px;
        background-color: #fff;
        // display: flex;
        // flex-wrap: wrap;
        // align-items: center;
        .jiao{
            position: absolute;
            top: -8px;
            left: 116px;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 4px 8px 4px;
            border-color: transparent transparent #fff transparent;
        }
        .brand-search{
            display: flex;
            .el-input{
                .el-input__inner{
                    height: 32px;
                    line-height: 32px;
                    border: 1px solid #498FFF;
                    border-radius: 2px 0 0 2px;
                }
            }
            .brand-btn{
                width: 74px;
                height: 30px;
                font-size: 14px;
                color: #fff;
                text-align: center;
                line-height: 30px;
                background: #498FFF;
                border: 1px solid #498FFF;
                border-radius: 0 2px 2px 0;
            }
        }
        .brand-info{
            margin-top: 5px;
            display: flex;
            flex-wrap: wrap;
            .cls-item{
                margin: 0 10px;
                padding: 5px 0;
                cursor: pointer;
                // width: 98px;
            }
            .active{
                color: #3E84D6;
            }
        }
    }
    // 该品牌下的分类
    .goods-list{
       
        .list-head{
            display: flex;
            height: 40px;
            align-items: center;
            background: #C4C9CC;
            margin: 10px 0 0 0;
            .head-item{
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .filterZero{
                    position: absolute;
                    top: 15px;
                   
                }
                .sort{
                    // display: flex;
                    // flex-direction: column;
                    // justify-content: center;
                    // margin-left: 2px;
                     width: 12px;
                    height: 12px;
                    img{
                      width: 12px;
                    height: 12px;  
                    }
                }
                i{
                    cursor: pointer;
                    font-size: 12px;
                }
                i:hover{
                    transform: scale(1.5);
                    color: #3E84D6;
                }
                .el-icon-caret-top{
                    position: relative;
                    top: 2px;
                }
                .el-icon-caret-bottom{
                    position: relative;
                    top: -2px;
                }
                .active{
                    color: #3E84D6;
                }

            }
            .goods_price{
                min-width: 140px;
            }
            .oecode{
                // min-width: 180px;
                min-width: 220px;
                
            }
            .goods_name{
                min-width: 180px;
                max-width: 180px;
            }
            .buy-nums{
                max-width: 120px;
                min-width: 120px;

            }
            .handle{
                min-width: 100px;
                max-width: 100px;
            }
        }
        .list-body{
            box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.13);
            // border-top: 1px solid #9E9E9E;
            .list-goods{
                // padding: 15px 0;
                background: #fff;
                position: relative;
                // border-bottom: 1px solid #CCCCCC;
                .row{
                    display: flex;
                    height: 80px;
                    // padding-bottom: 10px;
                    .display-center{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    .list-rol{
                        flex: 1;
                        text-align: center;
                        // line-height: 72px;
                        // height: 72px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        border-right: 1px solid  #E3E4EA;
                        position: relative;
                        .id-bs{
                            position: absolute;
                            left: 50%;
                            bottom: 10%;
                            color: #ccc;
                            // border: 1px solid #efefef;
                            border-radius: 50%;
                            width: 70px;
                            text-align: center;
                            transform: translateX(-50%);
                            .bordiv{
                                position: absolute;
                                left: 0;
                                bottom: -2px;
                                width: 100%;
                                height: 100%;
                                transform: scale(1.3);
                                border-radius: 50%;
                                border: 2px solid #efefef;
                            }
                            .bordiv1{
                                position: absolute;
                                left: 0;
                                bottom: -2px;
                                width: 100%;
                                height: 100%;
                                transform: scale(1.1);
                                border-radius: 50%;
                                border: .5px solid #efefef;
                            }
                        }
                        .blue{
                            color: #3E84D6;
                        }
                        .vehicle{
                            width: 180px;
                            height: 38px;
                            position: absolute;
                            bottom: 0;
                            left: 24px;
                            overflow: hidden;
                            text-align: left;
                            color: #999999;
                            font-style: 12px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        .goods-storage{
                            line-height: 0;
                            margin-top: -18px;
                            font-size: 12px;
                            color: red;
                            font-weight:bold;
                        }
                    }
                    .oecode{
                        // min-width: 180px;
                        line-height: 50px;
                        min-width: 220px;
                        display: flex;
                        box-sizing: border-box;
                        padding-left: 15px;
                        align-items: center;
                        cursor: pointer;
                        .brand{
                            min-width: 48px;
                            max-width: 48px;
                            height: 20px;
                            margin: 0 10px;
                            background: #3E84D6;
                            text-align: center;
                            line-height: 20px;
                            color: #fff;
                        }
                        .tag-icon{
                            display: flex;
                            align-items: center;
                            transform: scale(.7);
                            justify-content: center;
                            width: 20px;
                            height: 20px;
                            background: $vin-blue;
                            color: #fff;
                            // border: 1px solid $vin-blue;
                            border-radius: 50%;
                            font-size: 12px;
                            position: relative;
                            top: -5px;
                            left: 2px;
                        }
                    }
                    .goods_price{
                        min-width: 140px;
                    }
                    .goods_name{
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        min-width: 180px;
                        max-width: 180px;
                    }
                    .buy-nums{
                        min-width: 120px;
                        max-width: 120px;
                        position: relative;
                        .el-input-number{
                            width: 110px;
                        }
                        .goods-Fcl-tit{
                            position: absolute;
                            left: 50%;
                            transform: translateX(-50%);
                            bottom: 2px;
                            font-size: 12px;
                            line-height: 12px;
                            width: 120px;
                            text-align: center;
                            color: #999;
                        }
                    }
                    .handle{
                        line-height: 16px;
                        min-width: 100px;
                        max-width: 100px;
                        justify-content: center;
                        padding-top: 5px;
                        padding-left: 10px;
                        box-sizing: border-box;
                        >div{
                            margin-top: 2px;
                        }
                        .btn{
                            width: 80px;
                            height: 22px;
                            display: flex;
                            justify-content: center;
                            border:1px solid #3E84D6;
                            color: #3E84D6;
                            align-items: center;
                            border-radius: 5;
                            cursor: pointer;
                        }
                        .cart-num{
                            width: 80px;
                            height: 16px;
                            
                            font-size: 12px;
                            color: #999999;
                            
                        }
                        .replace-btn{
                            width: 80px;
                            height: 16px;
                            color: #59A8F8;
                            text-align: center;
                            cursor: pointer;
                        }
                    }
                    
                }
                .row-two{
                    border-top: 1px solid #E3E4EA;;
                    height: 31px;
                    padding-bottom: 0px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    >div{
                        color: #999999;
                    }
                   
                    .buyHis{
                        cursor: pointer;
                        text-align: right;
                        flex: 1;
                        padding: 0 5px;
                      
                        color: red;
                        // color: #999;
                        
                        >span{
                            margin-right: 20px;
                            align-items: center;
                        }
                        .readMore{
                            cursor: pointer;
                        }
                    }
                    .han-box{
                        display: flex;
                        
                        text-align: center;
                        .col-num{
                            min-width: 120px;
                            
                        }
                        .handle{
                            flex: 1;
                            flex-direction: column;
                            min-width: 100px;
                            max-width: 100px;
                            line-height: 22px;
                            position: relative;
                            >div{
                                left: 10px;
                            }
                            .cart-num{
                                position: absolute;
                                top: -10px;
                            }
                            .replace-btn{
                                color: #3E84D6;
                                cursor: pointer;
                                position: absolute;
                                bottom: -10px;

                            }
                        }
                    }
                }
            }
            .list-goods:nth-child(2n){
                background: #f7f7f7;
            }
            
        }
    }

    .el-dialog__body{
        padding: 0 !important;
        .his-list{
            .list-head{
                display: flex;
                height: 40px;
                align-items: center;
                background: #EEEEEE;
                margin: 10px 0 ;
                padding: 0 8px;
                >div{
                    flex: 1;
                    text-align: center;
                }
                .col-100{
                    max-width: 100px;
                }
            }

            .list-body{
                
                .row{
                    display: flex;
                    padding: 0 8px;
                    >div{
                        flex: 1;
                        padding: 2px 0;
                        border-bottom: 1px solid #eee;
                        flex: 1;
                        text-align: center;
                        line-height: 28px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                    .col-100{
                        max-width: 100px;
                    }
                }
            }
        }
    }
     // 互换件
    .store-vis {
        ::v-deep .el-dialog__header{
        padding: 0;
        }
        ::v-deep .el-dialog__body{
            padding: 0;
        }
        .el-dialog {
        .hhj-index{
            width: 40px;
        }
        .handle{
            width: 80px;
            text-align: center;
            .btn {
            cursor: pointer;
            color: #3E84D6;
            }
        }
        .oe{
            flex: 1;
            text-align: center;
        }
        .tit {
            padding: 10px;
            background: #3E84D6;
            color: #fff;
            display: flex;
            justify-content: space-between;
            .el-icon-close {
            cursor: pointer;
            color: #fff;
            }
        }
        
        .list-body {
            padding: 10px;
            .list-head {
            display: flex;
            background: #dfdfdf;
            height: 28px;
            line-height: 28px;
            
            
            }
            .list-row {
            display: flex;
            align-items: center;
            border-bottom: 1px solid#ccc;
            > div {
                text-align: center;
            
                padding: 8px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                
            }
            }
        }
        }
    }
	.paging{
        // margin-top: 45px;
        display: flex;
        justify-content:center;
        // margin: 15px 0;
        padding: 15px 0;
        margin-bottom: 10px;
        background: #fff;
        .database{
            line-height: 30px;
        }
    }
    .hezi{
        .el-dialog__wrapper{
            .el-dialog{
                width: 760px;
                .el-dialog__header{
                    position: relative;
                    padding: 10px 20px 20px;
                        .el-icon-close:before{
                        position: absolute;
                        top: -10px;
                        right: 0px;
                    }
                }
                .el-dialog__body {
                    overflow-y:auto;
                    height: 500px;
                    padding: 0 10px;
                    box-sizing: border-box;
                  
                }
            }
        }
    }
}
.alltion{
  width: 100%;
  display: flex; 
  align-items: center;
  height: 40px;
  border-bottom: 1px solid #eee;
  >div{
    width: 25%;
    text-align: center; 
  }
}
</style>