<template>
<!-- 结构树查询 -->
<div class="scd-Vin-tree-content">
    <div class="epc-search" >
        
        <div class="vehicle-info" >
           
            <div class="main-search-box">
                <div class="main-sear-brand">
                    <div class="logo-img">
                        <img :src="brandImg" alt="">
                    </div>
                    <el-select  v-model="manufacturer_id" size="small" popper-class="vin-brand-select" >
                        <template v-for="(brand,index) in brandList">
                            <el-option :label="brand.manufacturer_name" :value="brand.manufacturer_id"  :key="index" >
                                <div class="brand-img" :key="index" @click="vinBrandChange(brand.manufacturer_id)">
                                    <img :src="brand.small_manufacturer_logo" alt=""  srcset="">
                                    {{brand.manufacturer_name}}
                                </div>
                                
                            </el-option>
                        </template>
                    </el-select>
                </div>
                <div class="vincode-select" > 
                    <!-- @paste.native="pasteImg" -->
                    <!-- clearable -->
                    <el-input class="vin-code-input"  
                        @paste.native="pasteImg" 
                        size="small"
                        v-model="vinCode"
                        clearable
                        placeholder="请输入VIN码,或粘贴车架号图片"
                    ></el-input>
                    <el-select v-model="vinCode" size="small" class="vin-his-box" popper-class="vin-his-select" >
                        <template v-for="(his,index) in vinHisList">
                            <el-option  :value="his.record_name"  :key="index" v-if="his.record_name">
                                <div class="vin-content" :key="index" @click="vinSearch(his.record_name)">
                                    <div class="vin-text">
                                        {{his.record_name}}
                                    </div>
                                    <div class="vin-time">
                                        {{$hnwTools.formatDateTime(his.add_time,"mdhm")}}
                                    </div>
                                </div>
                                <div class="vin-info">
                                    {{his.car_type}}
                                   
                                </div>
                            </el-option>
                        </template>
                    </el-select>
                    <!-- action="https://xmall.51qcj.com:1443/api/v2.0/epcweb/other/ocrvin" -->
                    <el-upload
                        class="upImgVin"
                        action="https://api.huanuowei.cn/web/index.php?act=data&op=liyang&model=image"
                        :multiple="false"
                        name="vinImage"
                        :data="Vindata"
                        :show-file-list="false"
                        list-type="picture"
                        :http-request="upVinImg"
                        :limit="1"
                        >
                        <div class="camera-box">
                            <img src="../../assets/catalog/camera1.png" alt="">
                        </div>
                    </el-upload>
                </div>
                
            </div>
            <div class="btn list-btn" @click="vinSearch('')" >
                搜索
            </div>
            <!-- @click="showMoreMsg=true" -->
            <div class="img-box" >
                <el-popover
                    placement="bottom"
                    width="200"
                    trigger="click"

                >
                    <img slot="reference" src="../../assets/catalog/car1.png" alt="">
                    <!-- v-show="showMoreMsg" -->
                    <div class="car-msg-info">
                        <el-tabs v-model="activeMsgTag" @click.stop >
                            <el-tab-pane label="车辆主要信息" name="main"></el-tab-pane>
                            <el-tab-pane label="车辆配置信息" name="other"></el-tab-pane>
                        </el-tabs>

                        <div class="msg-body">
                            <div class="msg-head">
                                <div v-if="activeMsgTag=='main'">主要信息</div>
                                <div v-else>配置号</div>
                                <div>说明</div>
                            </div>
                            <div class="camsg-input-box" v-if="activeMsgTag=='other'">
                                <el-input size="mini" placeholder="输入关键字查询" v-model="carMsgfilterText"></el-input>
                            </div>
                            <div class="car-msg-body">
                                <div class="car-msg-row" v-if="activeMsgTag=='main'">
                                    <div class="label">
                                        车型:
                                    </div>
                                    <div class="value">
                                        {{carInfo.vehicle_name}}
                                    </div>
                                </div>
                                <template v-if="activeMsgTag=='main'">
                                    <div class="car-msg-row" v-for="(item,index) in carAllMsg" :key="index">
                                        <template v-if="item.type==0">
                                            <div class="label">
                                                {{item.name}}
                                            </div>
                                            <div class="value">
                                                {{item.value}}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-else-if="activeMsgTag=='other'">
                                    <div class="car-msg-row" v-for="(item,index) in showCarOtherMsg" :key="index">
                                        <div class="label o-label">
                                            {{item.name}}
                                        </div>
                                        <div class="value">
                                            <el-tooltip  effect="dark" :open-delay="300" :content="item.value" placement="top">
                                                <span >
                                                    {{item.value}}
                                                </span>
                                            </el-tooltip>
                                            
                                        </div>
                                        
                                    </div>
                                </template>
                            </div>
                            
                        </div>
                    </div>
                </el-popover>
                
            </div>

            <div class="info">
                <!-- 车型导航 -->
                <div class="ml-title">
                    目录
                </div>
                <div class="kh">
                    [
                </div>
                <div class="level-show-list" v-if="$route.params.vin!='veh'">
                    <!-- <div class="leve-item" v-for="(item,index) in carAllMsg" :key="index">
                        <span v-if="item.type==0&&index<7&&item.value">
                            {{item.value}}
                            <i class="el-icon-arrow-right" v-if="index<6"></i>
                        </span>
                    </div> -->
                    
                    {{carInfo.vehicle_show_name}}
                </div>
                <div class="level-show-list" @click="vehDrawer=true,tempNum=0" :class="{'cur':$route.params.vin=='veh'}" v-else-if="$route.params.vin=='veh'&&!vehDrawer">

                    <div class="leve-item" v-for="(name,index) in $refs.vehicle.leveShow" :key="index">
                        {{name}}
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    {{carInfo.vehicle_full_name}}
                </div>
                <div class="kh">
                    ]
                </div>
                
            </div>
            
        </div>
        <!-- 右边控制按钮 -->
        <div class="handle">
            <!-- v-if="$store.state.sc_rent==1&&$store.getters.notLvD&&vinCode" -->
            <div class="btn" @click="toVinGoods"  >
                精准找件
            </div>
        </div>
    </div>
    <div class="page-control">
        <!-- 左侧页面控制 -->
        <div class="left-page">
            <div class="page-item" :class="{'active':tempNum==index}" v-for="(item,index) in pageModes" :key="index" @click="tabPage(index)">
                <div>
                    {{item}}
                </div>
            </div>
           
        </div>
        <div class="search-box" v-if="tempNum!=1">
           
            <!-- clearable -->
            <el-autocomplete
                v-model="searchText2"
                :fetch-suggestions="q2"
                :trigger-on-focus="false"
                value-key="showTit"
                size="mini"
                placeholder="输入配件编码查询"
                @select="conditionTopartList1"
                select-when-unmatched
                @input="inputStyle"
            ></el-autocomplete>
            <!-- clearable -->
            <el-autocomplete
                v-model="searchText1"
                :fetch-suggestions="q1"
                :trigger-on-focus="false"
                value-key="showTit"
                placeholder="输入配件名称查询"
                size="mini"
                @select="conditionTopartList1"
                select-when-unmatched
                @input="inputStyle"
            ></el-autocomplete>
            <!-- part_group_name -->
                <!--  -->
            <!-- clearable -->
           
            <el-autocomplete
                ref="autoPle3"
                v-model="searchText3"
                :fetch-suggestions="q3"
                v-show="searchText3"
                value-key="showTit"
                size="mini"
                placeholder="输入通用名称"
                @select="conditionTopartList1"
                select-when-unmatched
                @input="inputStyle"
            >
            <template slot-scope="{ item }">
                <div v-if="item.flag==1" style="color: #409EFF;">
                    <img src="../../assets/vin/lock.png" style="width: 15px;vertical-align: text-bottom;" alt="">
                    {{ item.showTit }}
                </div> 
                <div v-else style="color: #999;">
                    <img src="../../assets/vin/unlock.png" style="width: 15px;vertical-align: text-bottom;" alt="">
                    {{ item.showTit }}
                </div> 
            </template>
        
            </el-autocomplete>
                <!-- clearable -->
            <el-autocomplete
                v-show="!searchText3"
                v-model="searchTextTymc"
                ref="tymcPla"
                :fetch-suggestions="queryTymc"
               
                value-key="part_group_name"
                size="mini"
                placeholder="输入通用名称"
                @select="seleTymc"
                @input="inputStyle"
            ></el-autocomplete>

            <!-- :trigger-on-focus="false" -->
            
            <el-button type="primary" size="mini" @click="vinSearchByText">搜索</el-button>
        </div>
        <template v-if="tempNum==2&&$route.params.vin!='veh'">
            <el-radio-group v-model="listFilter">
                <el-radio :label="false">全部</el-radio>
                <el-radio :label="true">显示锁定</el-radio>
            </el-radio-group>
            <!-- <div class="tip-tit" v-if="!listFilter">
                *注意:红色字体零件不适配该车型
            </div> -->
            <!--  -->
        </template>
        <div class="right-num" v-if="tempNum==2">
            记录数:
            {{$refs.EpcImgList.epcPartList.length}}
        </div>
        
    </div>
    <div class="vehicle-box" v-show="tempNum==0">
        <Vehicle :manufacturer_id="manufacturer_id" @getMainGroup="getMainGroup" v-show="vehDrawer" ref="vehicle">

        </Vehicle>
        <div v-show="!vehDrawer" class="part-content" ref="partContent" id="partContent" v-loading="treeLoading">
            <!-- 计算完高度再渲染 -->
            <div class="classification">
                <!-- v-if="mainGroupList.length>0" -->
                <div class="re-back">
                    <div class="btn" @click="reback">
                        <i class="el-icon-arrow-left"></i>
                        {{'车型目录'}}
                    </div>
                </div>
                <div class="part-group" >
                    
                    <div class="groups">
                        <div class="group-title main-title">
                            主组
                        </div>
                        <div class="group-list" :style="{'height':groupH +'px'}">
                            <div class="group-item" v-for="(group,index) in mainGroupList" :key="index" :class="{'active':group.main_group_id==carInfo.main_group_id}"  @click="getBranch(group)">
                            <div class="tit">
                                    {{group.main_group_name}}
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="groups">
                        <div class="group-title">
                            子组
                        </div>
                        <div class="group-list branch-list" :style="{'height':groupH+'px'}">
                            <div class="group-item"  @click="getImageList('')" :class="{'active':''==carInfo.sub_group_id}">
                                <div class="tit">
                                    全部
                                </div>
                            </div>
                            <div class="group-item" v-for="(group,index) in branchGroupList" :key="index" :class="{'active':group.sub_group_id==carInfo.sub_group_id}"  @click="getImageList(group)">
                                <div class="tit">
                                    {{group.sub_group_name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="groups img-groups">
                        <div class="group-title">
                            图组
                        </div>
                        <div class="group-list partimg-list" :style="{'height':groupH+'px'}">
                            <div class="group-item"   v-for="(part,index) in showImgList" :key="index" :class="{'active':part.image_group_id==partInfo.image_group_id,'disabled':part.lock_flag==0}" @dblclick="epcSearch(part)" @click="selePart(part)">
                            <div class="tit">
                                {{part.image_dispcode}} {{part.image_group_name}}
                            </div>
                                
                            </div>
                        </div>
                    </div> 
                </div>
                <!-- 图组大图展示 -->
                <div class="img-big" id="bigImg">
                    <div class="group-title">
                        图片
                    </div>
                    <div class="img-box">
                        <img :style="{'max-height':groupH + 20+'px'}" :src="partInfo.image_url" alt="" >
                    </div>
                </div>
            </div>

            <div class="part-list-area" id="part-list-area">
                <div class="img-filter">
                    图组选择:
                    <el-radio-group v-model="listFilter" v-if="$route.params.vin!='veh'">
                        <el-radio :label="false">全部</el-radio>
                        <el-radio :label="true">显示锁定</el-radio>
                    </el-radio-group>
                </div>
                <!-- <div class="list-content" :style="{'height':imglistH+'px'}">  -->
                <div class="list-content"> 
                    <div class="img-list" id="img-list-body" > 
                    
                        <template v-for="(item,index) in showImgList">
                            <div class="img-item-box" :key="index"  >
                                <!-- @click="epcSearch(item)" -->
                                <div class="img-item "  :title="item.image_group_name" @dblclick="epcSearch(item)" @click="selePart(item)"  :class="{'disabled':item.lock_flag==0,'active':partInfo.image_group_id==item.image_group_id}">
                                    <div class="block_dia"></div>
                                    <div class="dis-vis" >
                                        <i class="el-icon-close"></i>
                                    </div>
                                    <div class="part-num">
                                        <div>
                                            {{index+1}}
                                        </div>
                                        <div>
                                            {{item.image_dispcode}}
                                        </div>
                                    </div>
                                    <div class="img-box">
                                        <img :src="item.small_image_url" alt="">
                                    </div>
                                    <!-- <div class="name">
                                        <div>
                                            {{item.image_group_name}}
                                        </div>
                                        <div v-if="item.image_memo">
                                            描述： {{item.image_memo}}
                                        </div>
                                        <div v-if="item.image_remark">
                                            备注： {{item.image_remark}}
                                        </div>
                                        
                                    </div> -->
                                    

                                </div>

                            </div>
                        </template>    
                    </div>

                </div>

            </div>

        </div>
    </div>
    <div  v-show="tempNum==1">
        <div class="sear-result">
            <div class="condition-search">
                <div class="search-selects">
                    <div class="sele-tit">
                        车厂
                    </div>
                    <el-select v-model="condition.manufacturer_id"  placeholder="请选择车型"  @change="manuChange"  size="mini">
                        <el-option :label="brand.manufacturer_name" :value="brand.manufacturer_id"  :key="index" v-for="(brand,index) in brandList">
                        </el-option>
                    </el-select>
                    <!-- clearable -->
                    <div class="sele-tit">
                        品牌
                    </div>
                    <el-select v-model="condition.brand_id"  placeholder="请选择品牌"    @change="searchBrandChange" size="mini">
                        <el-option :label="brand.brand_name" :value="brand.brand_id"  :key="index" v-for="(brand,index) in conditionBrandList">
                        </el-option>
                    </el-select>
                    <!-- clearable -->
                    <div class="sele-tit">
                        车系
                    </div>
                    <el-select v-model="condition.car_series_id"  placeholder="请选择车系"   size="mini">
                        <el-option :label="brand.car_series_name" :value="brand.car_series_id"  :key="index" v-for="(brand,index) in conditionVehList">
                        </el-option>
                    </el-select>
                </div>
                
                <el-autocomplete
                    class="sele-input"
                    size="mini"
                    v-model="searchText"
                    :fetch-suggestions="querySearch"
                    @select="oeSearch"
                    value-key="record_name"
                    placeholder="请输入配件编码或者配件名称或者通用名称"
                    :hide-loading="true"
                ></el-autocomplete>


                <el-button size="mini" type="primary" @click="oeSearch">搜索</el-button>
                <el-button size="mini" @click="clearCondition">清除</el-button>
                <!--  -->
                <!-- <span class="tip" v-if="resultList.length>0">
                    双击查看爆炸图
                </span> -->
                 <div class="total">
                    共计<span>{{resultList.length}}</span>组
                </div>
            </div>
           
            
            <div class="ult-table">
                <div class="ult-head">
                    <div class="index-num">
                        #
                    </div>
                    <div v-for="tit in partAttrs" :key="tit.key" :class="tit.key">
                        {{tit.title}}
                    </div>
                    
                </div>
                <div class="ult-body" :style="{'height':partlistH+'px'}" v-loading="resultLoading">
                 <!-- @click="rowClick(part,index1)" :id="index1"  -->
                    <div class="ult-row" v-for="(part,index1) in resultList" 
                        :class="{'active':partActiveIndex==index1,'err-row':!part.part_number||!part.part_name}"  
                        :key="index1" @click="rowClick(part,index1)"
                        @dblclick="conditionTopartList(part)"
                    >
                        <div class="index-num">
                            {{index1+1}}
                        </div>
                        <div class="desc-model-fa" v-for="tit in partAttrs" :class="tit.key" :key="tit.key" >
                            {{part[tit.key]}}
                            <div class="icon" v-if="tit.key=='part_number'&&part.replace_flag==1">
                                替
                            </div>
                            <div class="icon desc-model" v-if="tit.key=='descmodel'&&part.detailid&&part.descmodel!=null" @click="showPlain(part)">
                                <img src="../../assets/vin/descModel.png" alt="">
                            </div>
                        </div>    
                    </div>

                </div>
                <div class="draw-replace">
                </div>
            </div>
            <div class="vin-part-info" id="partInfo" v-if="showPartInfo" :style="{'height':infoProportion +'px'}">
                <div class="info-type-list">
                    
                    <div class="type-item" :class="{'active':infoModuType==item.type}" v-for="(item,index) in infoTypeList" :key="index" @click="handleClick(item.type)">
                        <div>
                            {{item.title}}
                        </div>
                    </div>
                    <div class="add-pur-btn" @click="togglePurVis">
                        加采购车
                    </div>
                </div>
                <div v-loading="partInfoLoading">
                    <template v-if="infoModuType=='pirce'">
                        <div class="mode-body price-list">
                            <div class="list-head">
                                
                                <div class="index-num">#</div>
                                <div>车厂</div>
                                <div>采购价(不含税)</div>
                                <div>采购价(含税)</div>
                                <div>销售价(不含税)</div>
                                <div>销售价(含税)</div>
                                <div>销售价(含税)</div>
                            
                            </div>
                            <div class="list-body" :style="{'height': infoProportion -58 +'px'}">
    
                                <div class="list-row" v-for="(priceItem,index) in priceList" :key="index">
                                    
                                    <div class="index-num">{{index+1}}</div>
                                    <div>{{priceItem.brand_name}}</div>
                                    
                                    <div>{{priceItem.purchase_price}}</div>
                                    <div>{{priceItem.purchase_price_tax}}</div>
                                    <div>{{priceItem.sale_price}}</div>
                                    <div>{{priceItem.sale_price_tax}}</div>
                                    <div>{{priceItem.sale_price_tax}}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="infoModuType=='link'">
                        <div class="mode-body link-list">
                            <div class="list-head">
                                <div class="index-num">#</div>
                                <div class="cc-brand">车厂品牌</div>
                                <div class="code">替换码</div>
                                <div class="price">采购价</div>
                                <div class="price">销售价</div>
                                <div>备注</div>
                            
                                <div class="handle">操作</div>
                            </div>
                        
                            <div class="list-body" :style="{'height':infoProportion -58 +'px'}">
                                <div class="list-row" v-for="(replace,index) in replaceList" :key="index">
                                    <div class="index-num">{{index+1}}</div>
                                    <div class="cc-brand">{{replace.brand_name}}</div>
                                    <div class="code">{{replace.replace_number}}</div>
                                    <div class="price">{{replace.purchase_price}}</div>
                                    <div class="price">{{replace.sale_price}}</div>
                                    <div>{{replace.remark}}</div>
                                    
                                    <div class="handle" >
                                        <i class="el-icon-shopping-cart-2"  @click="toBuy(replace)" v-if="replace.gysNum&&$store.getters.notLvD"></i>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </template>
                    <template v-else-if="infoModuType=='veh'">
                        <div class="mode-body veh-list">
                            <div class="list-head">
                                <div class="index-num">#</div>
                                <div>品牌名称</div>
                                <div>年款</div>
                                <div>车系名称</div>
                                
                                <div>车型目录名称</div>
                                <div>配件编码</div>
                                <!-- <div class="handle" v-if="queryType=='part'">操作</div>             -->
                            </div>
    
                            <div class="list-body" :style="{'height': infoProportion -58 +'px'}">
                                <div class="list-row" v-for="(veh,index) in vehList" :key="index">
                                    <div class="index-num">{{index+1}}</div>
                                    <div>{{veh.brand_name}}</div>
                                    <div>{{veh.production_year}}</div>
                                    <div>{{veh.car_series_name}}</div>
                                    <div>{{veh.series_name}}</div>
                                    <div>{{veh.part_number}}</div>
                                </div>
                            </div>
                            
                        </div>
                    </template>
                    <template v-else-if="infoModuType=='store'">
                        <div class="store-msg"  >
                            <div class="mode-body store-list">
                                <div class="list-head">
                                    <div class="index-num">#</div>
                                    <div>是否互换件</div>
                                    <div>名称</div>
                                    <div>OE号</div>
                                    <div>生产编码</div>
                                    <div>品牌</div>
                                    <div>价格</div>
                                    <div>库存</div>
                                    <div class="handle">操作</div>
                                </div>
                                <div class="list-body" :style="{'height': infoProportion -58 +'px'}">
                                    <div class="list-row" v-for="(goods,index) in storeList" :key="index">
                                        <!-- <div>{{goods.store_name}}</div> -->
                                        <!-- 简称 -->
                                        <div class="index-num">{{index+1}}</div>
                                        <div>
                                            {{ goods.isHhj?"是":"否" }}
                                            <!-- <span v-if="goods.store_id!=1780">
                                                {{$store.state.shopInfo.shop_name||goods.store_name}}
                                            </span>
                                            <span v-else>
                                                华诺威汽配
                                            </span> -->
                                        </div>
                                        <div>{{goods.goods_name}}</div>
                                        <div>{{goods.oecode}}</div>
                                        <div>{{goods.sccode}}</div>
                                        <div>{{goods.oenaturebrand}}</div>
                                        <!-- <div>{{goods.goods_price}}</div> -->
                                        <div v-if="$store.state.shop==1948">价格面议</div>
                                        <div v-else>{{goods.goods_price}}</div>
                                        <div>
                                            <span v-if="$store.state.look_inv==1">{{goods.goods_storage}}</span>
                                            <!-- <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span> -->
                                            <span v-else-if="$store.state.shop==1948">有货</span>
                                            <span v-else>{{goods.goods_storage>0?"有货":"无货"}}</span>
                                        </div>
                                        <!-- <div class="handle">
                                            <span class="btn" @click="joinCart(goods)">
                                                {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                                            </span>
                                        </div> -->
                                        <div class="handle" v-if="$store.state.shop==1948">
                                            <span  class="btn" @click="joinCart(goods)"> 
                                                加入购物车 
                                            </span>
                                        </div>
                                        <div class="handle" v-else>
                                           
                                            <span class="btn"  :class="{'ygd':goods.goods_storage==0}" @click="joinCart(goods)">
                                                {{goods.goods_storage>0?'加入购物车':'加入预购单'}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="infoModuType=='sonPart'">
                        <div class="mode-body price-list">
                            <div class="list-head">
                                <div class="index-num">#</div>
                                <div class="factory">车厂</div>
                                <div>配件编码</div>
                                <div>配件名称</div>
                                <div>备注</div>
                                <div>采购价格(不含税)</div>
                                <div>采购价格(含税)</div>
                                <div>销售价(未含税)</div>
                                <div>销售价(含税)</div>
                                
                            </div>
                            <div class="list-body" :style="{'height': infoProportion -58 +'px'}">
                                
                                <div class="list-row" v-for="(priceItem,index) in kitrList" :key="index">
                                    <div class="index-num">{{index+1}}</div>
                                    <div class="factory">{{priceItem.brand_name}}</div>
                                    <div>{{priceItem.part_number}}</div>
                                    <div>{{priceItem.part_name}}</div>
                                    <div>{{priceItem.remark}}</div>
                                    <div>{{priceItem.purchase_price}}</div>
                                    <div>{{priceItem.purchase_price_tax}}</div>
                                    <div>{{priceItem.sale_price}}</div>
                                    <div>{{priceItem.sale_price_tax}}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-else-if="infoModuType=='partImg'">
                        <div class="mode-body part-img-list" :style="{'height': infoProportion -58 +'px'}">
                            <div class="img-box" v-for="(img,index) in partImgList" :key="index">
                                <img :src="img" alt="" @click="redBigImg(img)" srcset="">
                            </div>
                        </div>
                    </template>
                </div>
                
                
            </div>
        </div>
        
    </div>
    <div v-show="tempNum==2">

        <EpcImgList :partdatas="partdatas" :partInfo="partInfo" :imgList="switchList" ref="EpcImgList" :carInfo="carInfo" :listFilter="listFilter" @readJump="readJump" >
            <template slot="img-handle">
                <div class="img-handle" >
                    <div class="handle-han">
                            <!-- <img src="../../assets/vin/fh.png" alt=""> -->
                        <div class="btn" @click="tabPage(isCondition?1:0)">
                            <i class="el-icon-arrow-left"></i>
                            {{isCondition?'条件查询':'目录查询'}}
                        </div>
                        <div class="sele-box">
                            <el-select v-model="partInfo.image_group_id" size="mini" @change="seleChange">
                                <el-option
                                class="optionEL"
                                    v-for="(part,index2) in switchList"
                                    :key="index2"
                                    :value="part.image_group_id"
                                    :label="part.image_dispcode+part.image_group_name"
                                    >
                                    {{part.image_dispcode}} {{part.image_group_name}}
                                </el-option>
                            </el-select>
                        </div>
                        <template v-if="switchList.length>0">
                            <div class="tab" @click="switchGroup(1)">
                                <!-- <i class="el-icon-caret-left"></i> 
                                上一个 -->
                                前
                            </div>
                            <div class="tab" @click="switchGroup(2)">
                                后
                                <!-- 下一个
                                <i class="el-icon-caret-right"></i> -->
                            </div>
                        </template>
                        
                    </div>
                </div> 
            </template>
        </EpcImgList>
    </div>
    <div v-show="tempNum==3">
        <div class="explain-box">
            <div class="ex-type-list">
                <div class="type-item" :class="{'active':exType==index+1}" v-for="(item,index) in explainTypeList" :key="index" @click="explainTypeTap(index)">
                    <div>
                        {{item}}
                    </div>
                </div>
            </div>
            <div class="explain-search-box">
                <div class="plain-label">
                    <span v-if="exType==1">
                        配置编码
                    </span>
                    <span v-else-if="exType==2">
                        发动机编码
                    </span>
                    <span v-else-if="exType==3">
                        变速箱代码
                    </span>
                </div>
                <el-input class="" :placeholder="exHolder" v-model="explainText"  size="mini" ></el-input>
                <el-button size="mini" type="primary" @click="explainTypeTap('sear')">查询</el-button>
            </div>
            <div class="mode-body explain-mode-body" v-loading="explainLoding">
                <div class="list-head">
                    <div class="index-num">序号</div>
                    <template v-if="exType==1">
                        <div class="pr_code">配置代码</div>
                        <div class="pr_desc">配置说明</div>
                    </template>
                    
                    <template v-if="exType==2">
                        <div class="eng_code">发动机编码</div>
                        <div class="eng_kv">千瓦</div>
                        <div class="eng_hp">马力</div>
                        <div class="eng_cy">缸数</div>
                        <div class="eng_dsm">排量</div>
                        <div class="eng_mod">适用车型</div>
                        <div class="eng_date">安装日期</div>
                        <div class="eng_memo">备注</div>
                    </template>
                    <template v-if="exType==3">
                       <div class="pr_code">变速箱编码</div>
                        <div class="trans_type">变速箱类型</div>
                        <div class="trans_mod">适用车型</div>
                        <div class="eng_date">安装日期</div>
                        <div class="trans_memo">备注</div>
                    </template>
    
                </div>
                <div class="list-body" :style="{'height': partlistH+'px'}" >
                    <div class="list-row" v-for="(plain,index) in explainList" :key="index">
                        <div class="index-num">{{plain.no}}</div>
                        <template v-if="exType==1">
                            <div class="pr_code">{{plain.pr_code}}</div>
                            <div class="pr_desc">{{plain.pr_desc}}</div>
                        </template>
                        <template v-if="exType==2">
                            <div class="eng_code">{{plain.eng_code}}</div>
                            <div class="eng_kv">{{plain.eng_kv}}</div>
                            <div class="eng_hp">{{plain.eng_hp}}</div>
                            <div class="eng_cy">{{plain.eng_cy}}</div>
                            <div class="eng_dsm">{{plain.eng_dsm}}</div>
                            <div class="eng_mod">{{plain.eng_mod}}</div>
                            <div class="eng_date">{{plain.eng_date}}</div>
                            <div class="eng_memo">{{plain.eng_memo}}</div>
                        </template>
                        <template v-if="exType==3">
                            <div class="pr_code">{{plain.trans_code}}</div>
                            <div class="trans_type">{{plain.trans_type}}</div>
                            <div class="trans_mod">{{plain.trans_mod}}</div>
                            <div class="eng_date">{{plain.trans_date}}</div>
                            <div class="trans_memo">{{plain.trans_memo}}</div>
                            
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <el-drawer
        size="80%"
        title="搜索结果"
        :visible.sync="searchDrwVis"
        direction="btt"
        custom-class="vin-search-drawer"
    >
        <div class="ult-table">
            <div class="ult-head">
               <!-- <div>
                    结构图
               </div> -->
               <div>
                    图组序号
               </div>
               <div>
                    图组名称
               </div>
               <div>
                    配件编码
               </div>
            </div>
         
            <div class="ult-body" >
                <!-- @click="rowClick(part,index1)" :id="index1"  -->
                <div class="ult-row" v-for="(part,index1) in vinResultList"  :key="index1" @click="conditionTopartList1(part)" >
                    <!-- <div>
                        <div class="img-box">
                            <img :src="part.small_image_url" alt="">
                        </div>
                    </div> -->
                    <div>
                        {{part.image_dispcode}}
                    </div>
                    <div>
                        {{part.image_group_name}}
                    </div>
                    <div>
                        {{part.part_number}}
                    </div>
                </div>

            </div>
            <div class="draw-replace">
            </div>
        </div>
    </el-drawer>
    <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog
        title="车辆选择"
        :visible.sync="carSelectVis"
        width="600px"
        
        custom-class="car-select-dia"
    >
    
        <el-table 
            :data="vinCarsData"  
            
        >
            <el-table-column prop="checked" label="" width="40">
                <template slot-scope="scope">
                    <el-checkbox v-model="scope.row.checked" @change="vinCarSelect(scope.row)"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column prop="brand_name" label="品牌" width="150"></el-table-column>
            <el-table-column prop="production_year" label="年款" width="200"></el-table-column>
            <el-table-column prop="series_name" label="名称"></el-table-column>
        </el-table>
        <span slot="footer" class="dialog-footer">
            <el-button @click="carSelectVis=false" size="mini">取 消</el-button>
            <el-button type="primary" @click="affCar" size="mini">确 定</el-button>
        </span>
    </el-dialog>
    <el-dialog
        title="配件详情"
        :visible.sync="partExplainVis "
        width="60%"
        :show-close="false"
        custom-class="plain-dia"
    >
        <div class="mode-body explain-list">
            <div class="list-head">
                <div class="code">编码</div>
                <div>名称</div>
            </div>
            <div class="list-body">

                <div class="list-row" v-for="(plain,index) in explainListData" :key="index">
                    <div class="code">{{plain.code}}</div>
                    <div>{{plain.name}}</div>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="mini" @click="partExplainVis = false">关闭</el-button>
        </span>
    </el-dialog>
    <EpcPurFrom ref="EpcPurFrom"></EpcPurFrom>
</div>
</template>

<script>

import EpcImgList from "./EpcImgList.vue"
import Vehicle from "./Vehicle.vue"
export default {
    name:"vinTree",
    data(){
        return{
            Vindata:{
                member_id:this.$store.state.member_id,
                shop_id:this.$store.state.shop,
                member_name:this.$store.state.member_name,
                store_name:this.$store.state.store_name
            },
            listFilter:true,
            activeMsgTag:"main",
            mainGroupList:[],
            branchGroupList:[],
            searchDrwVis:false,
            imgList:[],
            searchText:"",
            searchText1:"",
            searchText2:"",
            searchText3:"",
            searchText3_id:"",
            searchTextTymc:"",
            tempNum:0,
            carMsgfilterText:"",
        
            vinCode:"",
           
            manufacturer_id:"12",
            carAllMsg:[],
            carInfo:{
            },
            // "OE询价","替换与库存","名词解释","常用配件"
            pageModes:["目录查询","条件查询","配件展示","名词解释"],
            resultList:[],
            vinResultList:[],
            partAttrs:[
            
                {
                    title:"	品牌名称",
                    key:"brand_name",
                    width:"180px"
                },
                {
                    title:"车系名称",
                    key:"car_series_name",
                    width:"180px"
                },
                {
                    title:"适用年份",
                    key:"fit_year",
                    width:"180px"
                },

                {
                    title:"配件编码",
                    key:"part_number",
                    width:"120px"
                },
                {
                    title:"配件名称",
                    key:'part_name',
                    width:"140px"
                },
            
                
                {
                    title:"配件备注",
                    key:"part_remark",
                    width:"180px"
                },
                {
                    title:"型号",
                    key:"descmodel",
                    width:"180px"
                },
                {
                    title:"图组名称",
                    key:"image_group_name",
                    width:"180px"
                },
                {
                    title:"图组",
                    key:"image_dispcode",
                    width:"80px"
                },
                
                {
                    title:"图号",
                    key:"pots_number",
                    width:"80px"
                }
            ],
            //   store sonPart  partImg
            infoTypeList:[
                {
                    title:"供应商",
                    type:"store"
                },
                {
                    title:"4S参考价",
                    type:"pirce"
                },
                {
                    title:"通用替换件",
                    type:"link"
                },
                {
                    title:"适用车型",
                    type:"veh"
                },
                {
                    title:"子配件",
                    type:"sonPart"
                },
                {
                    title:"配件实物图",
                    type:"partImg"
                },
            ],
            vehDrawer:false,
            groupH:0,
            imglistH:0,
             // 列表高度
            partlistH:0,
            // 商品详情信息高度 
            infoProportion:"",
            // 初始列表高度
            startH:0,
            partInfo:{},
            partdatas:{},
            conditionBrandList:[],
            conditionVehList:[],
            condition:{
                manufacturer_id:"",
                brand_id:"",
                car_series_id:""
            },
           
            // 用于切换图组的列表
            switchList:[],
            showPartInfo:false,
            infoModuType:"store",
            actiPart:{},
            partActiveIndex:"-1",
            priceList:[],
            replaceList:[],
            vehList:[],
            storeList:[],
            kitrList:[],
            partImgList:[],
            // 精准搜索后的列表
            tempSearchList:[],
            isCondition:false,
            partInfoLoading:false,
            resultLoading:false,
            treeLoading:false,
            hisTitValue:"",
            vinHisList:[],
            vinCarsData:[],
            carSelectVis:false,
            dialogVisible:false,
            dialogImageUrl:"",
            checkIndex:-1,
            exType:"1",
            exPage:"1",
            explainTypeList:["配置说明","发动机","变速箱"],
            explainList:[],
            explainText:"",
            exHolder:"请输入需要解释的配置代码",
            explainLoding:false,
            canClick:true,
            explainListData:[],//名词解释
            partExplainVis:false,
        }
    },
    methods:{
        inputStyle(){
            setTimeout(() => {
                let ressug = document.getElementsByClassName("el-autocomplete-suggestion")
                ressug[0].style.width = 'unset' 
                ressug[1].style.width = 'unset' 
                ressug[2].style.width = 'unset' 
                ressug[3].style.width = 'unset' 
                ressug[4].style.width = 'unset' 
            },500)
        },
        async showPlain(part){ 
            let vin = ""
            if(this.$route.params.vin!='veh'){
                vin = this.$route.params.vin
            }
            let res =  await this.$apiScd.getPartPlainByid({
                manufacturer_id:this.manufacturer_id,
                vin,
                part_detail_id:part.detailid
            })
            if(!res||res.length==0){
                this.$warMsg("未查到相关信息")
            }else{
                this.explainListData = res
                this.partExplainVis = true
            }
        },
        async explainTypeTap(index){
            this.explainList = []
            this.explainLoding = true
            if(index!="sear"){
                this.exType = index + 1
                this.explainText = ""
                this.explainList = []
            }else{
                let exRes = await this.$apiScd.getPlain({
                    manufacturer_id:this.manufacturer_id,
                    vin:this.vinCode,
                    number:this.explainText,
                    type:this.exType,
                    page:this.exPage,
                })
                this.explainList = exRes
            }
            if(this.exType==1){
               this.exHolder = "请输入需要解释的配置代码"
            //    this.explainText = "L29"
            }else if(this.exType==2){
                this.exHolder = "请输入需要解释的发动机编码"
                // this.explainText = "ABC"
            }else{
                this.exHolder = "请输入需要解释的变速箱代码"
                // this.explainText = "1L"
            }
            
            this.explainLoding = false
        },
        upVinImg(e,e1){
            // console.log("触发上传",);
            this.fileReaderupdateImg(e.file)
        },
        reset(){
            this.carInfo = {}
            this.carAllMsg = []
            this.mainGroupList = []
            this.branchGroupList = []
            this.carMsgfilterText = ""
            this.imgList = []
            this.partInfo = {}
        },
        rowClick(epcPart,index){
            this.actiPart = epcPart
           this.$set(this.actiPart,"goods_oecode",this.actiPart.part_number)

            this.$set(this.actiPart,"goods_name",this.actiPart.part_name.replace(/<\/br>/g,""))
            this.$set(this.actiPart,"goods_number",1)
            this.$set(this.actiPart,"goods_brand","")
            this.$set(this.actiPart,"supplier","")
            this.$set(this.actiPart,"data","")

            this.partActiveIndex = index
            this.handleClick()
            if(this.partlistH==this.startH){
                // this.partlistH = this.partlistH - 270
                this.partlistH = this.partlistH *0.6
            }
        },
        vinCarSelect(row){
           
            this.vinCarsData.forEach(item=>item.checked=false)
            row.checked = true
        },
      
        affCar(){
            this.carInfo = this.vinCarsData.find(item=>item.checked)
           
            // this.carInfo = this.vinCarsData.filter(item=>item.checked)[0]
            if(this.carInfo){

                this.carSelectVis = false
                this.manufacturer_id = this.carInfo.manufacturer_id
                this.getCarInfoByVIn()
            }else{
                this.$warMsg("请选择")
            }
        },
        tabPage(num){
            console.log("num",num)
            if(num==1){
                
                // this.condition.manufacturer_id = this.manufacturer_id
                // this.manuChange()
                
            }else if(num==2){
               
            }else if(num==3){
                if(this.explainList.length==0){
                    this.explainTypeTap('sear')
                }
            }
            this.tempNum = num 
        },
        
        async vinSearch(vin){
            // console.log("查询vin");
            if(!vin){
                if(!this.canClick){
                    return console.log("重复点击")
                }
                this.vinCode = this.vinCode.replace(/\s/g,'').toUpperCase()
                if(this.vinCode.length!=17){
                    return this.$warMsg("车架号长度必须17位，请检查您的车架号")
                }
                if(!this.$store.state.vinTotal){
                    await this.$store.dispatch("getVinNum")
                }
            }else{
                this.vinCode = vin
            }
            
            this.vinCode = this.vinCode.replace(/O/g,'0')
            this.vinCode = this.vinCode.replace(/I/g,'1')
            this.vinCode = this.vinCode.replace(/Q/g,'9')

            this.canClick = false
            // 次数用完 不论是否查询过都不可查询 2021-11-27
            if(this.$store.state.vin_over_time<1&&this.$store.state.vinNum<1&&this.$store.state.store_id!=1989){
                this.$warMsg("您的次数已用完")
                return false
            }
            
            this.treeLoading = true
            // 查询是否扣费
            // 清除信息
            this.reset()
            let feeRes = await this.$api.getFeeSite(1)
            
            let ins_ok = 1
            // 永不扣费
            if(feeRes.ins_ok==0){
                ins_ok = 0

            }else if(feeRes.ins_repeat_ok==0){
                // 如果周期天数有
                if(this.$store.state.vin_over_time>0){
                    // console.log("当前使用包月套餐");
                    ins_ok = 0
                }else{

                    let searTime = await this.$api.getSearTime({
                        record_name:this.vinCode,
                        store_id:this.$store.state.store_id
                    
                    })
                    if(Array.isArray(searTime)){
                        // console.log("未找搜索记录，扣费")
                        
                    }else{

                        if(Number(feeRes.ins_date)==0){
                            ins_ok = 0
                            // console.log("永久不扣费")
                        }else{
                            // 当前时间是
                            let nowTime = new Date().getTime()/1000
                            // 扣费周期
                            
                            let feeDate = Number(feeRes.ins_date)*60*60*24 
                            
                            if(nowTime - searTime.add_time > feeDate){
                                ins_ok = 1
                                // console.log("超出免费周期，扣费");
                            }else{
                                ins_ok = 0
                            }
                        }
                        
                    }

                }
                // 重复扣费
            }
            
        
           
            
            const res = await this.$apiScd.vinSearch(this.vinCode)
            
            if(!res){
                this.$router.push("/epc/home/vin")
                this.canClick = true
            }else{

                 
                this.canClick = true
               
                if(res.isManufacturer_id){
                    if(res.manufacturer_id<0){
                        this.$warMsg("vin解析失败")
                        this.treeLoading = false
                        
                    }else{
                        this.$warMsg("vin解析失败，返回车厂信息")
                        this.manufacturer_id = res.manufacturer_id
                        this.vinCode = ""
                        this.vehDrawer = true
                        this.$nextTick(()=>{
                            this.$refs.vehicle.init()
                        })
                        this.treeLoading = false
                    }
                }else{
                    // 添加查询记录 和扣费 
                    await this.$api.addUseHis({
                        use_model:"车架号解析全车件车型配置",
                        use_content:this.vinCode,
                        is_ok:ins_ok,
                        store_id:this.$store.state.store_id,
                        store_name:this.$store.state.store_name,
                        member_id:this.$store.state.member_id,
                        member_name:this.$store.state.member_name,
                    })
                    if(res.length>1){
                        this.vinCarsData = res
                        this.vinCarsData.forEach(car=>{
                            this.$set(car,"checked",false)
                        })
                        this.carSelectVis = true
                    }else{
                       this.manufacturer_id = res[0].manufacturer_id
                       this.carInfo = res[0]
                       this.getCarInfoByVIn()
                    }
                }
                this.$store.dispatch("getVinNum")
            }
            
           this.tempNum = 0
        },
        redBigImg(url){
            this.dialogImageUrl = url;
            this.dialogVisible  = true
        },
        async getCarInfoByVIn(){
            
            // 再解析车辆详情
            let info = await this.$apiScd.getVinInfo({
                vin:this.vinCode,
                manufacturer_id:this.manufacturer_id
            })
            this.carAllMsg = info
            this.getMainGroup()

            await this.$api.addSearchRecord({
                record_name:this.vinCode,
                record_type:3,
                car_type:this.carInfo.vehicle_full_name,
                record_from:1,

            })
            this.getVinHisList();
            location.href = location.href.split("#")[0]+`#/epc/tree/${this.vinCode}/1`
        },
        // 如果是车型有参数
        async getMainGroup(params){
            let mainPrams = {
                vin:this.vinCode,
                manufacturer_id:this.manufacturer_id,
                brand_id:this.carInfo.brand_id,
                series_id:this.carInfo.series_id,
                condition:this.carInfo.condition,
                models_id:this.carInfo.models_id
            }
            // 查询主组
            if(params){
                // 如果是车型
                params.vin = ""
                mainPrams = {
                    ...mainPrams,
                    ...params,
                }
                this.carInfo = mainPrams
                
                location.href = location.href.split("#")[0]+`#/epc/tree/veh/${this.manufacturer_id}`
                this.listFilter = false
            }else{
                this.listFilter = true
            }
            
            
            
           
            let groupRes = await this.$apiScd.getMainGroupList(mainPrams)
            this.mainGroupList = groupRes
            // // 附件及其他调整位置
            // this.mainGroupList.push(this.mainGroupList.shift())
            // await this.getBranch(this.mainGroupList[0])
            this.treeLoading = false
            this.vehDrawer = false
        },
        
        async getBranch(item){
           
            this.carInfo.main_group_id = item.main_group_id
            let res = await this.$apiScd.getBranchGroupList({
                vin:this.vinCode,
                manufacturer_id:this.manufacturer_id,
                brand_id:this.carInfo.brand_id,
                series_id:this.carInfo.series_id,
                condition:this.carInfo.condition,
                models_id:this.carInfo.models_id,
                main_group_id:item.main_group_id
            })
            
          
            this.branchGroupList = res
            
            // this.getImageList(this.branchGroupList[0])
            this.getImageList("")
            let boxDom = document.getElementById("img-list-body")
            if(boxDom){
                boxDom.scrollTo(0,0)
            }
        },

        async getImageList(item){
            // console.log("item12",item) 
            if(item){
                this.carInfo.sub_group_id = item.sub_group_id
                this.carInfo.main_group_id = item.main_group_id||this.carInfo.main_group_id 
            }else{
                this.carInfo.sub_group_id = ""
            }
            // console.log("this.carInfo",this.carInfo)
            let res  = await this.$apiScd.getImagegroup({
                vin:this.vinCode,
                manufacturer_id:this.manufacturer_id,
                brand_id:this.carInfo.brand_id,
                series_id:this.carInfo.series_id,
                condition:this.carInfo.condition,
                models_id:this.carInfo.models_id,
                main_group_id:this.carInfo.main_group_id,
                sub_group_id:this.carInfo.sub_group_id,
                production_year:this.carInfo.production_year
            })
            this.imgList = res || [] 
            let indexFInd = this.showImgList.findIndex(itemX => itemX.image_dispcode==item.image_dispcode) 
            this.$nextTick(()=>{  
                // this.selePart(this.showImgList[0])
                this.selePart(this.showImgList[indexFInd])
                
                // this.groupH = document.documentElement.clientHeight - 240 - 207
                this.switchList = this.showImgList
            })
        },
        async togglePurVis(){
            
            this.$set(this.actiPart,"models",this.carInfo.vehicle_name)
            let resprice = await this.$apiScd.getPartPrice({
                manufacturer_id:this.actiPart.manufacturer_id,
                part_number:this.actiPart.part_number,
            })
            let priceList = resprice.content
            if(priceList){
                let priceInfo = priceList.find(item=>item.brand_name==this.actiPart.brand_name)
                if(priceInfo){
                    this.$set(this.actiPart,"goods_price",priceInfo.purchase_price_tax)
                    this.$set(this.actiPart,"purchase_price",priceInfo.purchase_price_tax)
                }else{
                    this.$set(this.actiPart,"goods_price",priceList[0].purchase_price_tax)
                    this.$set(this.actiPart,"purchase_price",priceList[0].purchase_price_tax)
                }
                
            }
            // console.log("this.actiPart",this.actiPart);
            this.$refs.EpcPurFrom.actiPart = JSON.parse(JSON.stringify(this.actiPart))
            this.$refs.EpcPurFrom.purCartVis = true
           
        },
        async manuChange(){
            let nextBrand = await this.$apiScd.getNextBrand({
                manufacturer_id:this.condition.manufacturer_id,
                vin:""
            })
            this.condition.brand_id = ""
            this.condition.car_series_id = ""
            this.conditionBrandList = nextBrand
        },
        async searchBrandChange(){
            if(this.condition.brand_id){
                let carses = await this.$apiScd.getCarseries({
                    manufacturer_id:this.manufacturer_id,
                    vin:"",
                    brand_id:this.condition.brand_id
                })
                this.conditionVehList = carses
            }else{
                this.conditionVehList = []
            }
            this.condition.car_series_id = ""
        },
        vinBrandChange(id){
            
            if(!this.vinCode){
                this.vehDrawer = true
                this.tempNum = 0
                this.$nextTick(()=>{
                    this.$refs.vehicle.init()
                })
            }
        },
        selePart(part){

            this.partInfo = {
                manufacturer_id:this.manufacturer_id,
                ...this.carInfo,
                vin:this.vinCode,
                ...part
            }
        },
        seleChange(image_group_id){
            // console.log("image_group_id",image_group_id);
            let part =  this.switchList.find(item=>item.image_group_id==image_group_id)
            this.selePart(part)
        },
        switchGroup(type){ 
            let index =  this.switchList.findIndex(item=>item.image_group_id==this.partInfo.image_group_id)
            if(type==1){
                if(index==0){
                    return this.$warMsg("已是第一个")
                }else{
                    this.selePart(this.switchList[index-1])
                }
            }else{
                if(index==this.switchList.length-1){
                    return this.$warMsg("已到最后")
                }else{
                    this.selePart(this.switchList[index+1])
                }
            }

        },
        readJump(jumpObj){
            this.switchList = jumpObj.jumpList
            let arr = jumpObj.jumpitem.split(",")
            let pots_number = ""
            if(arr[1]){
                pots_number = arr[1]
            }
            this.partInfo = {
                manufacturer_id:this.manufacturer_id,
                ...this.carInfo,
                vin:this.vinCode,
                ...this.switchList[jumpObj.tapIndex],
                pots_number
            }

            // this.selePart(list[0])
        },
        async epcSearch(part){
            // console.log("partpart",part)
            this.isCondition = false
            this.switchList = this.showImgList
            if(this.vinCode){
                // console.log("12")
                this.listFilter = true
            }else{
                this.listFilter = false
            }
            this.selePart(part)
            this.tabPage(2)
        },
        // 搜索后去爆炸图列表
        conditionTopartList(part){ 
            this.isCondition = true
            this.listFilter = false
            this.searchText1 = ""
            this.searchText2 = ""
            this.searchText3 = ""
            this.searchText3_id = ""
            this.switchList = [part]
            
            this.partInfo = part
            this.tabPage(2)

        },
        async conditionTopartList1(part){
            console.log("part12",part)
            // 页面跳转的时候，跳转到指定配件  
            this.partdatas = part
            this.isCondition = true
            this.listFilter = false
            this.searchText1 = ""
            this.searchText2 = ""
            this.searchText3 = ""
            this.searchText3_id = ""
            this.searchDrwVis =false 
            this.getImageList(part)
            
            try {
                this.$refs.tymcPla.blur()
                this.$refs.autoPle3.blur()
            } catch (error) {
                
            }
            // this.selePart({
            //     ...part,
            //     ...this.carInfo,
            //     main_group_id:part.main_group_id,
            //     sub_group_id:part.sub_group_id,
            //     image_group_id:part.image_group_id,
            //     manufacturer_id:this.manufacturer_id,
            //     vin:this.vinCode,
                
                
            // })
             this.selePart(part)
            this.tabPage(2)
        },
        seleTymc(part){ 
            console.log("part",part);
            
           this.$api.addSearchRecord({
                record_name:part.part_group_name,
                record_type:10,
                car_type:part.part_group_id,
                record_from:1
           })
            this.searchText3 = part.part_group_name
            this.searchText3_id = part.part_group_id
            // this.searchTextTymc = ""
            
            this.$nextTick(()=>{
                this.$refs.autoPle3.focus()
            })
        },
        reback(){ 
            if(this.$route.params.vin!='veh'){
                this.vehDrawer = true
                this.vinCode = ""
                location.href = location.href.split("#")[0]+`#/epc/tree/veh/${this.manufacturer_id}`
                this.$nextTick(()=>{
                    this.$refs.vehicle.init()
                })
                this.reset()
            }else{
                this.vehDrawer = true
            }
            // if(this.vinCode){
            //     console.log("vin号");
            // }
        },
        async vinSearchByText(){

           
            let query_type = ""
            let query = ""
            if(this.searchText1){
                query = this.searchText1
                query_type = 1
            }else if(this.searchText2){
                query = this.searchText2
                query_type = 2
            }else if(this.searchText3){
                query = this.searchText3
                query = this.searchText3_id
                query_type = 3
            }else{
                return this.$warMsg("请输入搜索内容")
            }
            this.searchDrwVis = true
            this.vinResultList = []
            let res = await this.$apiScd.getPartLocation({
                manufacturer_id:this.manufacturer_id,
                vin:this.vinCode,
                ...this.carInfo,
                query,
                query_type
            })
            this.vinResultList = res || []
        },

        q1(query, cb){
            this.querySearchAsync(query, cb,1)
        },
        q2(query, cb){
            this.querySearchAsync(query, cb,2)
        },
        async q3(query, cb){
           
            this.querySearchAsync(this.searchText3_id, cb,3)
        },
        async queryTymc(query, cb){
            if(query==''){
                let res = await this.$api.getSearchRecord({
                    store_id:this.$store.state.store_id,
                    record_type:10,
                    record_from:1
                })
                if(res&&res.length>0){
                    
                    res.forEach(item=>{
                        item.part_group_name = item.record_name
                        item.part_group_id = item.car_type
                    })
                }
                cb(res||[])
            }else{
                let groupName = await this.$apiScd.getGroupNameList({
                    manufacturer_id:this.manufacturer_id,
                    vin:this.vinCode,
                    series_id:this.carInfo.series_id,
                    query
                })
                
                cb(groupName||[])
            }
        },
        async querySearch(str,cb){
            console.log("11");
            
            let res =  await this.$api.getSearchRecord({
                store_id:this.$store.state.store_id,
                record_type:3,
                record_from:3
            })
            cb(res)
            // if(!this.vinCode){
            // }else{
               
            //     // cb([])
            // }
        },
        async querySearchAsync(query, cb,query_type){
           
            if(!query){
                return cb([])
            }
            
            let res = await this.$apiScd.getPartLocation({
                manufacturer_id:this.manufacturer_id,
                vin:this.vinCode,
                ...this.carInfo,
                query,
                query_type
            })
            
            
            if(res){
                res.forEach(item=>{
                    item.showTit = `${item.image_dispcode} ${item.image_group_name} ${item.descmodel||''}`
                })
                this.tempSearchList =  res
                cb(res||[])
                // this.resultList = res
                // if(res.length==0){
                //     return this.$warMsg("未找到配件")
                // }
                
            }else{
                cb(res||[])
            }
        },
        async oeSearch(){
            
            if(this.resultLoading){
                return 
            }
            this.resultLoading = true
            
            let res = await this.$apiScd.getPartByOe({
                ...this.condition,
                query:this.searchText
            })

            this.$api.addSearchRecord({
                record_name:this.searchText,
                record_type:3,
                car_type:"",
                record_from:3
            })
           
            this.$api.addUseHis({
                use_model:"oe号查询",
                use_content:this.searchText,
                store_id:this.$store.state.store_id,
                store_name:this.$store.state.store_name,
                member_id:this.$store.state.member_id,
                member_name:this.$store.state.member_name,
            })

            if(res){
                if(res.length==0){
                    this.resultList = []
                    return this.$warMsg("未找到配件")
                }
                this.resultList = res
            }else{
                this.resultList = []
            }
            if(this.tempNum!=1){
                this.tabPage(1)
            }
            this.resultLoading = false
        },
        
        clearCondition(){
            this.searchText = ""
            this.condition = {
                manufacturer_id:"",
                brand_id:"",
                car_series_id:""
            }
        },
        // 去到精准找件
        toVinGoods(){
            if(!this.vinCode){
                return this.$warMsg("没有对应vin号")
            }
            this.$router.push(`/epc/vinGoods/${this.vinCode}`)
            localStorage.setItem("hnw-epc-carInfo",JSON.stringify({
                ...this.carInfo,
                vin:this.vinCode
            }))
        },
        async getVinHisList(){
            let res =  await this.$api.getSearchRecord({
                store_id:this.$store.state.store_id,
                record_type:3,
                record_from:1,
                page:30
            })
            if(res&&res.length>0){
                this.vinHisList = res
            }else{
                this.vinHisList = []
            }
          
        },
        async vinQuerySearch(str,cb){
            if(!this.vinCode){
                let res =  await this.$api.getSearchRecord({
                    store_id:this.$store.state.store_id,
                    record_type:3,
                    record_from:1
                })
               
                cb(res)
            }else{
                cb([])
            }
        },
        async nameGroupSearch(str,cb){
          
            cb([])
        },
        pasteImg(event){
            let clipboardData = event.clipboardData
            if(!(clipboardData&&clipboardData.items)){
                return 
            }
            for(let i= 0,len = clipboardData.items.length;i<len;i++){
                let item = clipboardData.items[i];
                if(item.type.indexOf('image')!==-1){
                    let f = item.getAsFile()

                    this.fileReaderupdateImg(f)
                }
            }
        },
        vinSelectFc(item){
            this.vinCode = item
        },
        compress (img) {
            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')
            let width = img.width
            let height = img.height
            canvas.width = width
            canvas.height = height
            ctx.fillStyle = '#fff'
            ctx.fillRect(0, 0, canvas.width, canvas.height)
            ctx.drawImage(img, 0, 0, width, height)
            let ndata = canvas.toDataURL('image/jpeg', 0.5)
            return ndata
        },
        dataURItoBlob (base64Data) {
            var byteString
            if (base64Data.split(',')[0].indexOf('base64') >= 0) {
            byteString = atob(base64Data.split(',')[1])
            } else {
            byteString = unescape(base64Data.split(',')[1])
            }
            var mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]
            var ia = new Uint8Array(byteString.length)
            for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i)
            }
            return new Blob([ia], { type: mimeString })
        },
        fileReaderupdateImg(file){
            this.loading = true
            let reader = new FileReader()

            reader.readAsDataURL(file)
            reader.onload = (e)=>{
                let img = new Image()
                // console.log(e.target.result)
                img.src = e.target.result
                img.onload =async ()=>{
                    
                    // console.log("img",img)
                    let data = this.compress(img)
                    this.upImg(data)
                    
                    return 
                    let blob = this.dataURItoBlob(data)
                    // console.log("data",data)
                    // console.log("blob",blob)
                          
                   
                }
            }
        },
        async upImg(baseData){
            this.loading = true
            // let arr = baseData = baseData.split(",")
            // let orcRes = await this.$apiScd.ocrvin({
            //     vinImage:arr[1]
            // })
            let orcRes = await this.$http(`index.php?act=data&op=liyang&model=image`,{  
                data:baseData,
                member_id:this.$store.state.member_id,
                shop_id:this.$store.state.shop,
                member_name:this.$store.state.member_name,
                store_name:this.$store.state.store_name
            });
            if(orcRes.Result){
                this.$suMsg("解析成功")
                this.vinCode = orcRes.Result
            }
            this.loading = false
        }, 
        amplify(isAmp){
            if(isAmp){
                this.partImgSty.width = "20rem"
            }else{
                this.partImgSty.width = "28rem"
            }
        },
       
        acDotChange(pnum){

            this.activePnum = pnum
            this.hotspots.forEach(dot=>{
                if(dot.hsKey==pnum){
                    dot.active = true
                }else{
                    dot.active = false
                }

            })
            
        },
        showVce(){
            if(this.$route.params.vin=='veh'){
                this.vehDrawer = true   
            }
        },
         // 切换类型
        async handleClick(type) {
            this.partInfoLoading = true
            if(type){
                this.infoModuType = type
            } 
            if(this.infoModuType=="pirce"){
                // 获取价格
                await this.getPrice()
            }else if(this.infoModuType=="link"){
                // 获取替换件
                await this.getPartReplace()
            }else if(this.infoModuType=="veh"){
                // 获取适配车型
                await this.getPartVeh()
            }else if(this.infoModuType=="store"){
                await this.getStoreList()
            }else if(this.infoModuType=="sonPart"){
                await this.getKitrList()
            }else if(this.infoModuType=="partImg"){
                await this.getPartImage()
            }
            this.partInfoLoading = false
            this.showPartInfo = true
        },
        async getPrice(){
            if(this.actiPart.part_number){
                let res = await this.$apiScd.getPartPrice({
                    manufacturer_id:this.actiPart.manufacturer_id,
                    part_number:this.actiPart.part_number,
                })
                
                this.priceList = res.content
            }
            
        },

        async getPartReplace(){
            if(this.actiPart.part_number){
                let res = await this.$apiScd.getPartReplace({
                    manufacturer_id:this.actiPart.manufacturer_id,
                    part_number:this.actiPart.part_number
                })
                console.log("res",res);
                
                this.replaceList = res ||[]

                if(this.replaceList){
                    let pid_list = this.replaceList.map(item=>item.replace_number)
                    if(pid_list.length>0){
                        let gysList = await this.$api.getGysNum({
                            oeList:pid_list,
                            for_branch:this.$store.state.for_branch,
                            shop:this.$store.state.shop
                        })
                        
                        gysList.forEach(item=>{
                            if(item.data.length>0){
                                this.replaceList.forEach(part=>{
                                    if(part.replace_number==item.oecode){
                                        part.gysNum = item.data.length
                                        part.storeList = item.data
                                    }
                                }) 
                            }
                            
                        })
                    }
                }
            }
             
        },
        async getPartVeh(){
            let res = await this.$apiScd.getPartVeh({
                manufacturer_id:this.actiPart.manufacturer_id,
                part_number:this.actiPart.part_number
            })
            this.vehList = res
            
        },
        async getStoreList(){
          
            // let res = await this.$api.getGysNum({
            //     oeList:[this.actiPart.part_number],
            //     for_branch:this.$store.state.for_branch,
            //     shop:this.$store.state.shop
            // })
            
          
            // if(res[0]){

            //     this.storeList = res[0].data
            //     // 分仓
            //     let indexRes = []
            //     let houseInfo = []
            //     if(this.$store.state.shop==1780){
                    
            //         this.storeList.forEach((goods) => {
            //             this.$storageCangInfo(goods)
            //         }); 
            //     }
            //         this.storeList.forEach(goods=>{
            //             if(goods.store_id!=1780){
            //                 this.$handleStorePrice(goods)
            //                 this.$hnwTools.formatGoodsKey(goods)
            //                 goods.store_name="江苏华诺威山东直营仓"
            //             }
            //         })
            // }
            // this.actiPart.part_number
            // let oem =  epcPart.exchange.join(",")
            let res = await this.$api.getDataCenterRepalce({
                oecode:this.actiPart.part_number
            })
           
            if(res){
                res = await this.$api.getBatchSearch({
                  oem:res.join()
                })
            }else{
                res = await this.$api.getBatchSearch({
                  oem:this.actiPart.part_number
                })
            }
          
          
            if(res&&res.length>0){
                res.forEach(goods=>{
                    this.$handleStorePrice(goods)
                    if(goods.oecode!=this.actiPart.part_number){
                        this.$set(goods,"isHhj",true)
                    }
                })
              this.storeList = res
            }else{
                this.storeList = []
            }
           
        },
        async getKitrList(){
            let res = await this.$apiScd.getPartKitr({
                manufacturer_id:this.actiPart.manufacturer_id,
                part_number:this.actiPart.part_number
            })
            this.kitrList = res
        },
        async getPartImage(){
            let res = await this.$apiScd.getPartImage({
                manufacturer_id:this.actiPart.manufacturer_id,
                part_number:this.actiPart.part_number
            })
           
            this.partImgList = res
        },
        async joinCart(carItem){
            // this.storeVis = false
            // console.log("carItem",carItem)
            if(this.$store.state.shop==1948&&carItem.goods_storage==0){
                carItem.goods_storage=1
            }
            
            let carMessage = {
                goods_id:carItem.goods_id,
                sccode:carItem.sccode,
                oecode:carItem.oecode,
                goods_name:carItem.goods_name,
                goods_price:carItem.goods_price,
                goods_num:carItem.goods_num,
                goods_storage:carItem.goods_storage,
                store_id:carItem.store_id,
                store_name:carItem.store_name,
                cart_type:'1'
            }
            
            await this.$api.joinCart([carMessage])
            
            // carItem.preRou = "找件"
            // localStorage.setItem("hnw-web-item-data",JSON.stringify(carItem))
            // let link = location.href.split("#")[0]
            // window.open(link + '#/find/info') 
        },
    },
    computed:{
        showImgList(){
            let list = []
            list = this.imgList.filter(item=>{
                // 0未锁，1唯一锁定，2锁多个，3不判定
                if(this.listFilter){
                    return item.lock_flag == 1 || item.lock_flag == 2
                }else{
                    return item
                }
            }) 
            console.log("list",list)
            return list
        },
        showCarOtherMsg(){
            let list = this.carAllMsg.filter(item=>item.type==1)
            // console.log("list",list);
     
            return list.filter(item=>{
                if(item.value&&item.value.includes(this.carMsgfilterText)){
                    return true
                }else if(item.name&&item.name.includes(this.carMsgfilterText)){
                    return true
                }else{
                    return false
                }
            })
        },

        brandImg(){
            if(this.manufacturer_id&&this.brandList&&this.brandList.length){
                let brand = this.brandList.find(brand=>brand.manufacturer_id==this.manufacturer_id)
                // console.log("brand",brand);
                return brand.small_manufacturer_logo
            }else{
                return ""
            }
        }
    },
    watch:{
        searchText3(n){
            if(n==''){
                this.$nextTick(()=>{
                    
                    // this.$refs.tymcPla.focus()
                })
            }
        },
        vinCode(n){
            if(n){
               this.listFilter = true 
            }else{
                this.listFilter = false 
            }
        }
    },
    activated(){
        // console.log("组件被激活")
        // this.isloo
    },
    async created(){
        // this.partlistH =  document.documentElement.clientHeight - 340
        // this.partlistH =  document.documentElement.clientHeight - 330
        
        this.groupH = (document.documentElement.clientHeight - 260)/2
        this.imglistH = this.groupH - 24

        this.partlistH =  document.documentElement.clientHeight - 300
        this.startH = this.partlistH
        this.infoProportion = this.partlistH * 0.4
        this.getVinHisList()
        if(this.$route.params.vin!='veh'){
            this.vinCode =  this.$route.params.vin
            this.vinCode = this.vinCode.replace(/\s/g,'').toUpperCase()
            this.vinSearch('')
        }else{
            this.manufacturer_id = this.$route.params.manufacturer_id
            this.vehDrawer = true
            this.$nextTick(()=>{
                this.$refs.vehicle.init()
            })
        }
        
       
        
    },
   
    components:{
        EpcImgList,
        Vehicle
    },
    //  brandCode:{
            
    //     },
    //     manufacturer_id:{

    //     },
    props:{
       brandList:{
            type:Array,
            default(){
                return []
            }
       },
    }
}
</script>

<style lang="scss">
.scd-Vin-tree-content{
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background: #F0F2F6;;
    .btn{
        cursor: pointer;
    }
    .vehicle-box{
        flex: 1;
    }
    .mode-body{
        height: 100%;
        .index-num{
            min-width: 40px;
            max-width: 40px;
        }
        .list-head{
            display: flex;
            height: 20px;
            align-items: center;
            // background: #FAFAFA;
            background: #FAFAFA;
            text-align: center;
            color: $scdTaCol;
            >div{
                flex: 1;
                border-right: 1px solid $scdLine;
                box-sizing: border-box;
            }
            >div:first-child{
                // text-align: left;
                // padding-left: 50px;
            }
            .factory{
                width: 100px;
            }
        }
        .list-body{
            overflow: auto;
            // max-height: 207px;
            .list-row{
                display: flex;
                align-items: center;
                min-height: 34px;
                // border-bottom: 1px solid #ccc;
                border-bottom: 1px solid #eee;
                text-align: center;
                font-size: 16px;
                >div{
                    flex: 1;
                    line-height: 19px;
                    // text-align: center;
                }
                >div:first-child{
                    // text-align: left;
                    // padding-left: 20px;
                }
                .factory{
                    width: 100px;
                }
            }
            .list-row:last-child{
                border:none;
            }
            .handle{
                display: flex;
                justify-content: center;
                .btn{
                    width: 86px;
                    height: 26px;
                    line-height: 26px;
                    cursor: pointer;
                }
                .ygd{
                    color: red;
                }
            }
        }
    }
    .epc-search{
        display: flex;
        justify-content: space-between;
        align-items: center;
        // background: #3158C6;
        background: #2f87c1;
        min-height: 50px;
        max-height: 50px;
        padding: 0 10px;
        color: #fff;
        font-weight: 700;
        .btn{
            height: 28px;
            line-height: 28px;
            padding: 0 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            background: #fff;
            border-radius: 3px;
            color: #3158C6;
        }
        .vehicle-info{
            display: flex;
            align-items: center;
            position: relative;
            .list-btn{
                height: 32px;
                line-height: 32px;
            }
            .main-search-box{
                display: flex;
                .main-sear-brand{
                    display: flex;
                    margin-right: 10px;
                    width: 130px;
                    background: #fff;
                    border-radius: 4px;
                    .logo-img{
                       width: 30px;
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       text-align: center;
                       img{
                        width: 20px;
                        height: 20px;
                       }
                    }
                    .el-select{
                        width: 100px;
                        .el-input__inner{
                            padding: 0 5px;
                            font-size: 14px;
                            outline: none;
                            border: none;
                        }
                    }
                }
                .vincode-select{
                    position: relative;
                    width: 300px;
                    border-radius: 4px;
                    height: 32px;
                    background: #fff;
                    .el-input.is-focus{
                        .el-input__inner{
                            border: none;
                        }
                    }
                    .vin-code-input{
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 10;
                        width: 235px;
                        .el-input__inner{
                            font-weight: 700;
                            border-right: none;
                            font-size: 16px;
                            color: #3158c6;
                            padding-right: 10px;
                            &::placeholder {
                                font-size: 12px;
                            }
                        }
                    }
                    .vin-his-box{
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 8;
                        width: 265px;
                        .el-input__inner{
                            border-right: none;
                        }
                    }
                    .upImgVin{
                        width: 35px;
                        height: 100%;
                        position: absolute;
                        right: 0;
                        display: flex;
                        align-items: center;
                        .camera-box{
                            height: 20px;
                            img{
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                }
                
            }
            .btn{
                margin-left: 10px;
                font-weight: 800;
                min-width: 48px;
                box-sizing: border-box;
            }
            .img-box{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 10px;
                margin-left: 20px;
                cursor: pointer;
                img{
                    width: 20px;
                    height: 20px;
                }
            }
            
            .info{
                display: flex;
                align-items: center;
                font-size: 12px;
                .ml-title{
                    width: 26px;
                }
                .info-item{
                    position: relative;
                    text-align: center; 
                }
                .cur{
                    cursor: pointer;
                }
                .level-show-list{
                    
                    font-size: .484848rem /* 16/33 */;
                    display: flex;
                    .leve-item{
                        display: flex;
                        align-items: center;
                        flex-wrap: nowrap;
                        span{
                            font-weight: 700;
                        }
                        i{

                        }
                    }
                }
                .kh{
                    padding: 0 5px;
                }
            }
           
            
        }
        .handle{
            display: flex;
            align-items: center;
           
            .btn{
                width: 76px;
            }
        }
    }
    // 中间的页面控制区域
    .page-control{
        display: flex;
        align-items: center;
        padding: 5px 10px;
        position: relative;
        // padding-bottom: 0;
        .left-page{
            display: flex;
            padding-right: 10px;
            border-radius: 4px;
            .page-item{
                padding:  6px 10px;
                font-size: 16px;
                margin: 0 5px;
                background: #fff;
                cursor: pointer;
                border: 1px solid #b6c8e1;
                transform: skewX(15deg);
                >div{
                    transform: skewX(-15deg);
                }
            }
            .active{
                background: #36af47;
                color: #fff;
            }
        }
        .search-box{
            display: flex;
            // flex: 1;
            // background: #fff;
            align-items: center;
            padding: 0 10px;
            .el-button{
                margin-left: 10px;
            }
            .el-input{
                // width: 7rem /* 220/33 */;
                height: 30px;
                .el-input__inner{
                    height: 30px;
                    line-height: 30px;
                }
            }
            .el-button{
                height: 30px;
            }
           
        }
        .right-num{
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
        .tip-tit{
            margin-left: 1rem ;
            color: #f00;
        }
        .is-checked{
            .el-radio__inner{
                background:#3158C6;
                color:#3158C6;
                border-color: #3158C6;
            }
            .el-radio__label{
                color: #3158C6;
            }
        }
    }
    .part-content{
       
        flex: 1;
        // overflow: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 10px;
        // box-sizing: border-box;
        position: relative;
        .re-back{
            position: absolute;
            left: 10px;
            top: 0px;
            .btn{
                color: #fff;
                cursor: pointer;
                box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.12);
                border-radius: 6px;
                background: #4fc1e9;
                width: 86px;
                height: 28px;
                display: flex;
                justify-content: center;
                align-items: center;
                i{
                    margin-right: 5px;
                }
            }
        }
        .classification{
            display: flex;
            flex: 1;
            .part-group{
                display: flex;
                height: 100%;
                flex: 1;
                .groups{
                    // margin-right: 10px;
                    margin-right: 5px;
                    
                    .group-title{
                        font-size: 14px;
                        font-weight: 400;
                        color: #555;
                        text-align: center;
                        padding-bottom: 10px;
                       
                    }
                    .main-title{
                        box-sizing: border-box;
                        padding-left: 50px;
                    }
                    .group-list{
                        border: 1px solid #ccc;
                        overflow: auto;
                        box-sizing: border-box;
                        flex: 1;
                        padding: 0 2px;
                        padding-top: 1px;
                        background: #fff;
                        .group-item{
                           
                            padding: 4px;
                            box-sizing: border-box;
                            cursor: pointer;
                            
                            .tit{
                                font-size: 15px;
                                // font-size: 14px;
                                padding-top: 2px;
                                color: #000;
                                font-weight: 500;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                            }
                        }
                        .active{
                            // background: #CED6ED;
                            background: #add8e6;
                            border-radius: 4px;
                        }
                    }
                    .branch-list{
                        // width: 80px;
                        min-width: 100px;
                        background: #f1f2f6;
                    }
                    .partimg-list{
                        flex: 1;
                        width: 100%;
                        .group-item{

                        }
                        .disabled{
                            .tit{
                                color: #777;
                            }
                        }
                    }
                   
                }
                .img-groups{
                    flex: 1;
                }
            }
            .img-big{
                width: 25%;
                
                .group-title{
                    font-size: 14px;
                    font-weight: 400;
                    color: #555;
                    text-align: center;
                    padding-bottom: 10px;
                }
                .img-box{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    img{
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translateX(-50%);
                        // transform: translatX(-50%);
                    }
                }
                img{
                    
                    // width: 80%;
                }
            }
        }

        .part-list-area{
            flex:1;
         
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            .img-filter{
                padding: 5px 0;
                .el-radio-group{
                    margin-left: 5px;
                }
                .is-checked{
                    .el-radio__inner{
                        background:#3158C6;
                        color:#3158C6;
                        border-color: #3158C6;
                    }
                    .el-radio__label{
                        color: #3158C6;
                    }
                }
            }
            .list-content{
                background: #fff;
                padding: 5px;
                // flex: 1;
                overflow: auto;
                // height: 145px;
               
                .img-list{
                    display: flex;
                    flex-wrap: wrap;
                    // overflow: auto;
                    // height: 174px;
                    .img-item-box{
                        // padding-right: .606061rem;
                        padding-right: 10px;
                        box-sizing: border-box;
                        width: 10%;   
                    }
                    .img-item{
                        // border:1px solid #EEEEEE;
                        border:1px solid #819cb1;
                        overflow: hidden;
                     
                        margin-bottom: 10px; /* 20/33 */
                        cursor: pointer;
                        position: relative;
                        text-align: center;
                        .img-box{
                            height: 130px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            img{
                                width: 90px;
                            }
                        }
                        .part-num{
                            height: 30px;
                            line-height: 30px;
                            padding-left: 10px;
                            padding-right: 10px;
                            box-sizing: border-box;
                            overflow: hidden;
                            display: flex;
                            justify-content: space-between;
                            color: #4848ff;
                        }
                        .name{
                            position: absolute;
                            top: 30px;
                            width: 100%;
                            
                            text-align: left;
                            padding: 2px;
                            overflow: hidden;
                            background-color: rgba(31, 62, 130, .6);
                            color: #fff;
                            display: none;
                            >div{
                                padding: 2px 0;
                                font-size: 12px;
                            }
                        }
                        .dis-vis{
                            display: none;
                        }
                    }
                    .disabled{
                        position: relative;
                        .block_dia{
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            background: rgba(0, 0, 0, 0.12);
                            
                        }
                        .dis-vis{
                            position: absolute;
                            right: 0;
                            bottom: 0;
                            display: block;
                            font-size: 34px;
                            color: #fff;
                            font-weight: 700;
                        }
                       .part-num{
                        //    color: $vin-blue;
                       }
                    }
                    .active{
                        border: 2px solid #FFB302;
                        // border: 1px solid #409EFF;
                    }
                    .img-item:nth-child(7n){
                        margin-right: 0;
                    }
                    .img-item:hover{
                        box-shadow: 0 4px 8px rgba(0,0,0,.1);
                        .name{
                            display: block;
                        }
                    }
                }
                
            }
        }
    }
    .img-handle{
        padding-bottom: 10px;
        .handle-han{
            display: flex;
            align-items: center;
            .sele-box{
                padding: 0 5px;
                flex: 1;
                .el-select{
                    width: 100%;
                    .el-input{
                        .el-input__inner{
                            font-size: 14px;
                            font-weight: 700 ;
                        }
                    }
                }
            }
            .btn{
                box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.12);
                border-radius: 6px;
                background: #4fc1e9;
            }
            .tab{
                font-weight: 700;
                cursor: pointer;
                width: 40px;
                height: 28px;
                line-height: 28px;
                text-align: center;
                background: $scdBlue;
                color: #015f8b;
                
                border-radius: 6px;
                margin-left: 10px;
                i{
                    font-size: 20px;
                }
            }

        }
    }
}
.car-msg-info{
    width: 420px;
    position: absolute;
    left: 50%;
    top: 0px;
    transform:  translateX(-50%);
    background: #F7FBFF;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    z-index: 2;
    text-align: center;
    .el-tabs{
        padding: 0 10px;
        .el-tabs__header{
            margin: 0;
        }
    }
    .msg-title{
        background: #2767DD;
        color: #fff;
        text-align: center;
        position: relative;
        height: 40px;
        line-height: 40px;
        .close-btn{
            cursor: pointer;
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    .msg-body{
        padding: 5px 10px;
        max-height: 420px;
       
        .msg-head{
            height: 30px;
            line-height: 30px;
            display: flex;
            background: #D9E1EE;
            >div{
                color: #344D99;
                padding: 0 15px;
                text-align: left;
            }
            >div:first-child{
                width: 120px;
            }
        }
        .camsg-input-box{
            padding: 5px 0;
        }
        .car-msg-body{
            overflow: auto;
            max-height: 300px;
            .car-msg-row{
                font-size: 16px;
                display: flex;
                line-height: 26px;
                >div{
                    padding: 0 15px;
                }
                .label{
                    color: #999999;
                    white-space: nowrap;
                    text-align: left;
                    width: 120px;
                }
                .o-label{
                    width: 80px;
                }
                .value{
                    flex: 1;
                    color: #666666;
                    padding-left: 10px;
                    white-space: nowrap;
                    text-align: left;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
            }
        }
    }
    
}
.sear-result{
    // padding: 10px 20px;
    padding: 5px 20px;
    background: #fff;
    .condition-search{
        display: flex;
        align-items: center;
        padding: 2px 5px;
        border: 2px solid$scdLine;
        border-radius: 10px;
        margin-bottom: 5px;
        .search-selects{
            // width: 580px;
            display: flex;
            align-items: center;
            .sele-tit{
                padding-right: 6px;
            }
            .el-select{
                margin-right: 15px;
                .el-input{
                    width: 140px;
                }
            }
        }
        .sele-input{
            width: 330px;
            input{
                width: 330px;
            }
        }
        .el-input-group{
            // flex: 1;
            width: 300px;
        }
        .tip{
            padding-left: 5px;
            color: red;
        }
        .total{
            padding-left: 5px;

        }
    }
    .dia-big-box{
        height:300px;
    }
    .total{
        display: flex;
        align-items: center;
        padding: 10px 0;
        .title{
            color: #3E84D6;
            margin-right: 5px;
        }
    }
    .head-area{
        display: flex;
        justify-content: space-between;
        border: 1px solid #EDEDED;
        border-radius: 4px;
        padding: 0 10px;
        .brand-list{
            display: flex;
            
            >div{
                cursor: pointer;
                margin: 0 2px;
                min-width: 30px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 6px;
                padding: 5px 0;
            }
            
            .fil-brand{
                
                border-bottom: 2px solid $scdLine;
            }
        }
        .total{
            color: #999999;
            span{
                color: #333;
            }
        }
    }
    .ult-table{
        background: #fff;
        border: 2px solid$scdLine;
        margin-bottom: 5px;
        border-radius: 10px;
        .ult-head{
            display: flex;
            align-items: center;
            background: #FAFAFA;
            height: 28px;
            border-bottom: 2px solid $scdLine;
            border-radius: 10px 10px 0 0 ;
            >div{
                flex: 1;
                text-align: left;
                color:#56688a;
                box-sizing: border-box;
                padding-left: 10px;
                border-right: 1px solid $scdLine;
            }
            .index-num{
                max-width: 40px;
                min-width: 40px;
            }
        }
        .ult-body{
            overflow: auto;
            .ult-row{
                display: flex;
                align-items: center;
                // min-height: 20px;
                min-height: 18px;
                padding: 5px 0;
                border-bottom: 1px solid #dce1e8;
                cursor: pointer;
                .index-num{
                    max-width: 40px;
                    min-width: 40px;
                }
                .desc-model-fa{
                    // position: relative;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .desc-model{
                        // position: absolute;
                        // top: -6px;
                        // right: 0;
                        >img{
                            width: 14px;
                        }
                    }
                }
                .sign{
                    color: #3E84D6;
                }
                >div{
                    flex: 1;
                    
                    min-height: 2px;
                    padding-left: 10px;
                    line-height: 18px;
                    box-sizing: border-box;
                    word-break:break-all;
                    .btn{
                        // border:1px solid #3E84D6;
                        color: #FF7519;
                        cursor: pointer;
                        // width: 80px;
                        // height: 30px;
                        padding: 4px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-sizing: border-box;
                        border: 1px solid #FF7519;
                        border-radius: 50%;
                        
                    }
                    
                }

                .part_number{
                    display: flex;

                    .icon{
                        background: #5c81b2;
                        color: #fff;
                        padding: 1px 3px;
                        border-radius: 5px;
                        margin-left: 4px;
                    }
                    
                }
                .sort-num{
                    min-width: 50px;
                    max-width: 50px;
                }
                .handle{
                    max-width: 80px;
                    min-width: 80px;
                    display: flex;
                    justify-content: space-evenly;
                    .shu{
                        width: 6px;
                    }
                    img{
                        width: 18px;
                        height: 20px;
                        // height: 16px;
                        cursor: pointer;
                    }
                    img:last-child{
                        width: 20px;
                    }
                    .bzt{
                        width: 20px;
                    }
                }
                
            }
            .err-row{
                color: red;
            }
            .active{
                // border: 2px solid #1890ff !important;
                background: #36af47;
                color: #fff;
            }
        }
    }
    .vin-part-info{
        
        overflow: hidden;
        background: #fff;
        width: 100%;
        bottom: 0;
       
        border: 2px solid$scdLine;
        box-sizing: border-box;
        border-radius: 4px;
        position: relative;
      
        .el-tabs{
            .el-tabs__header{
                padding-left: 10px;
                margin: 0;
                .el-tabs__item{
                    height: 28px;
                    line-height: 28px;
                }
            }
        }
        .info-type-list{
            display: flex;
            padding-right: 10px;
            border-radius: 4px;
            position: relative;
            .type-item{
                padding: 0 5px;
                height: 28px;
                line-height: 28px;
                font-size: 16px;
                margin: 0 5px;
                background: #fff;
                cursor: pointer;
                border: 1px solid #b6c8e1;
                border-top: none;
                transform: skewX(15deg);
                >div{
                    transform: skewX(-15deg);
                }
            }
            .active{
                background: #36af47;
                color: #fff;
            }

            .add-pur-btn{
                position: absolute;
                right: 10px;
                top: 50%;
                transform: translateY(-50%);
                font-weight: 700;
                cursor: pointer;
                padding: 0 10px;
                height: 24px;
                line-height: 24px;
                text-align: center;
                background: #16b2ec;
                color: #fff;
                font-size: 16px;
                border-radius: 6px;
            }
        }

        
        .link-list{
            .cc-brand{
                min-width: 100px;
                max-width: 100px;
            }
            .code{
                min-width: 140px;
                max-width: 140px;
            }
            .price{
                min-width: 100px;
                max-width: 100px;
            }
            .handle{
                min-width: 80px;
                max-width: 80px;
            }
        }
        .part-img-list{
            display: flex;
            max-height: 240px;
            flex-wrap: wrap;
            overflow: auto;
            .img-box{
                // width: 200px;
                padding: 10px;
                img{
                    // width: 200px;
                    height: 180px;
                    cursor: pointer;
                }
            }
        }

    }
}
.vin-brand-select{
    .el-select-dropdown__item{
        .brand-img{
            display: flex;
            align-items: center;
            img{
                width: 20px;
                margin-right: 4px;
            }
        }
        
    }
}

.vin-his-select{
    .el-select-dropdown__item{
        padding: 5px;
        line-height: 1;
        height: 40px;
       .vin-content{
            display: flex;
            justify-content: space-between;
            .vin-text{
                color: #409EFF;
                font-weight: 700;
                font-size: 15px;
                color: #333;
            }
            .vin-time{
                font-weight: 400 !important;
            }
        }
        .vin-info{
            padding: 3px;
            padding-left: 3px;
            font-size: 14px;

            // width: 300px;
            box-sizing: border-box;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #999;
        }
    }
    .el-select-dropdown__item.selected{
        div{
            color: #409EFF;
        }
        .vin-text{
            color: #409EFF;
        }
    }
}
.vin-tree-drawer{
    .el-drawer__header{
        padding: 10px;
        text-align: center;
        font-weight: 700;
        color: #333;
        margin: 0;
    }
}
.car-select-dia{
    .el-dialog__body{
        
        .current-row{
            td{
                background: #4977f3;
                color: #fff;
            }
        }
    }
}
.vin-search-drawer{
    .el-drawer__header{
        padding: 10px;
        text-align: center;
        font-weight: 700;
        color: #333;
        margin: 0;
    }
    .ult-table{
        background: #fff;
        
        .ult-head{
            display: flex;
            align-items: center;
            background: #FAFAFA;
            height: 40px;
            >div{
                flex: 1;
                text-align: left;
                color: $vin-blue;
                box-sizing: border-box;
                padding-left: 10px;
                border-right: 1px solid #ccc;
            }
            
        }
        .ult-body{
            overflow: auto;
            .ult-row{
                display: flex;
                align-items: center;
                // min-height: 20px;
                min-height: 18px;
                padding: 5px 0;
                border-bottom: 1px solid #EEEEEE;
                cursor: pointer;
                .sign{
                    color: #3E84D6;
                }
                >div{
                    flex: 1;
                    
                    min-height: 2px;
                    padding-left: 10px;
                    line-height: 18px;
                    box-sizing: border-box;
                    word-break:break-all;
                  
                    
                }
            }
            .img-box{
                img{
                    width: 40px;
                }
            }
            .active{
                border: 2px solid #1890ff !important;
            }
        }
    }
}
.explain-box{
    padding: 0 10px;
    .ex-type-list{
        display: flex;
        padding-right: 10px;
        border-radius: 4px;
        .type-item{
            padding: 0 5px;
            height: 28px;
            line-height: 28px;
            font-size: 16px;
            background: #fff;
            cursor: pointer;
            border: 1px solid #b6c8e1;
            
            
        }
        .active{
            // background: #36af47;
            border: 1px solid #36af47;
            border-bottom: none;
            color: #36af47;
        }
    }
    .explain-search-box{
        display: flex;
        padding: 5px;
        align-items: center;
        .plain-label{
            width: 100px;
            font-weight: 700;
        }
        .el-input{
            width: 200px;
        }
    }
    .explain-mode-body{
        .index-num{
            padding-left: 5px;
        }
        .pr_code{
            min-width: 120px;
            max-width: 120px;
        }
        .eng_code{
            min-width: 150px;
            max-width: 150px;
        }
        .eng_kv{
            min-width: 80px;
            max-width: 80px;
        }
        .eng_hp{
            min-width: 80px;
            max-width: 80px;
        }
        .eng_cy{
            min-width: 80px;
            max-width: 80px;
        }
        .eng_dsm{
            min-width: 80px;
            max-width: 80px;
        }
        .trans_type{
            min-width: 80px;
            max-width: 80px;
        }
        .eng_date{
            min-width: 160px;
            max-width: 160px;
        }
        .list-head{
            border: 1px solid$scdLine;
            height: 28px;
            font-weight: 700;
            border-bottom: none;
        }
        .list-body{
            border: 2px solid$scdLine;
            background: #fff;
            div{
                text-align: left;
            }
            .list-row{
                background: #fff;
                // border: 1px solid $scdLine;;
            }
        }
    }
}
</style>